import { useIsFetching, useIsMutating,  useQueryClient } from "react-query";
import { ProgressBar } from "primereact/progressbar";
import { useEffect, useState } from "react";

export function TopLoader() {
  const queryClient = useQueryClient();
  const isFetching = useIsFetching();
  const isMutating = useIsMutating();

  const [isLoading, setIsLoading] = useState(Boolean);

  const loaderStyle = {
    height: '4px',
    width: '100%'
  };

  useEffect(() => {
    setIsLoading(isFetching > 0 || isMutating > 0)
  }, [isFetching, isMutating, queryClient]);

  return (
    <div style={loaderStyle}>{isLoading && <ProgressBar mode="indeterminate" />}</div>
  );
}
