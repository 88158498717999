import { InputText } from "primereact/inputtext";
import { ExtendedMetaDataItem } from "../../queries/pcr/models/meta-data";

export interface ExtendedMetaDataInputItemProps {
  item: ExtendedMetaDataItem;
  disabled?: boolean;
  onChange: (v: string) => void;
  onBlur?: () => void;
}

export function ExtendedMetaDataInputItem({
  item,
  disabled = false,
  onChange,
  onBlur,
}: ExtendedMetaDataInputItemProps) {
  return (
    <>
      <label className={disabled ? "text-gray-400" : "text-gray-700"}>
        {item.label}
      </label>
      <InputText
        value={item.value}
        key={item.label}
        disabled={disabled}
        onChange={(v) => onChange(v.target.value)}
        className="mb-1 p-inputtext-sm"
        onBlur={onBlur}
      />
    </>
  );
}
