import { ActionType } from "../../queries/action-type";
import { CreateTubesModel } from "../../queries/micro/models/create-tubes";
import { PrepareSampleTubesModel } from "../../queries/micro/models/prepare-sample-tubes";
import { RemoveTubeRequest } from "../../queries/micro/models/remove-tube.request";
import { TubeModel } from "../../queries/micro/models/tube";
import { TubeState } from "../../queries/micro/models/tube-state";
import { UpdateCultureTubesModel } from "../../queries/micro/models/update-culture-tubes";
import { UpdatePendingResultsTubesModel } from "../../queries/micro/models/update-pending-results-tubes";
import { API_ENDPOINTS } from "../../utils/endpoints";
import { BaseService } from "../base.service";

class MicroService extends BaseService {
  async getTubesByStates(states: TubeState[]): Promise<TubeModel[]> {
    let url = `${this.endpointPath}/tubes?state=${states.join("&state=")}`;

    return this.http.get<TubeModel[]>(url).then((res) => res.data);
  }

  async getNegativeTubes(): Promise<TubeModel[]> {
    let url = `${this.endpointPath}/tubes/pcr-negative`;

    return this.http.get<TubeModel[]>(url).then((res) => res.data);
  }

  async createMicroTubes(model: CreateTubesModel): Promise<TubeModel[]> {
    return this.http.post(
      `${this.endpointPath}/tube-actions/process-multi-tubes`,
      {
        tubeCodes: model.barcodes,
        actionType: ActionType.MicroTubesCreated,
        data: {},
      }
    );
  }

  async getNoAstBarcodes(barcodes: string[]): Promise<string[]> {
    let url = `${this.endpointPath}/tubes/check-no-ast`;

    return this.http.post<string[]>(url, barcodes).then((res) => res.data);
  }

  async archiveTubes(ids: number[]): Promise<TubeModel[]> {
    let url = `${this.endpointPath}/tubes?id=${ids.join("&id=")}`;
    return this.http.delete(url);
  }

  async removeTube(model: RemoveTubeRequest): Promise<void> {
    return this.http.post(`${this.endpointPath}/tube-actions/process-single`, {
      actionType: ActionType.MicroTubesRemoved,
      data: {
        tubeId: model.tubeId,
        comment: model.comment,
      },
    });
  }

  async updatePrecultureTubes(
    model: UpdateCultureTubesModel
  ): Promise<TubeModel[]> {
    const data = {
      tubeIds: model.tubeIds,
      actionType: model.completed
        ? ActionType.MicroPreCultureCompleted
        : ActionType.MicroPreCultureUpdated,
      data: {
        currentStepTimeIn: model.timeIn?.toISOString(),
        currentStepTimeOut: model.timeOut?.toISOString(),
        currentStepSecondTimeIn: model.timeSecondIn?.toISOString(),
        currentStepSecondTimeOut: model.timeSecondOut?.toISOString(),
        currentStepComment: model.comment,
        metadata: model.metadata,
      },
    };
    return this.http.post(
      `${this.endpointPath}/tube-actions/process-multi-tubes`,
      data
    );
  }

  async updatePlateCultureTubes(
    model: UpdateCultureTubesModel
  ): Promise<TubeModel[]> {
    return this.http.post(
      `${this.endpointPath}/tube-actions/process-multi-tubes`,
      {
        tubeIds: model.tubeIds,
        actionType: model.completed
          ? ActionType.MicroPlateCultureCompleted
          : ActionType.MicroPlateCultureUpdated,
        data: {
          currentStepTimeIn: model.timeIn ?? null,
          currentStepTimeOut: model.timeOut ?? null,
          currentStepComment: model.comment,
          noAstPerformed: model.noAstPerformed ?? null,
          metadata: model.metadata,
        },
      }
    );
  }

  async updatePendingResultsTubes(
    model: UpdatePendingResultsTubesModel
  ): Promise<TubeModel[]> {
    return this.http.post(
      `${this.endpointPath}/tube-actions/process-multi-tubes`,
      {
        tubeIds: model.tubeIds,
        actionType: ActionType.MicroResult,
        data: {
          microResultType: model.result,
        },
      }
    );
  }

  async prepareSampleTubes(
    model: PrepareSampleTubesModel
  ): Promise<TubeModel[]> {
    return this.http.post(`${this.endpointPath}/tube-actions/process-single`, {
      actionType: ActionType.MicroGenerateSamples,
      data: {
        quantity: model.quantity,
        tubeId: model.parentId,
      },
    });
  }
}

export const microService = new MicroService(API_ENDPOINTS.MICRO);
