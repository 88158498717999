export enum TubeState {
  Undefined = 0,
  MicroCreated = 10,
  PendingPreculture = 20,
  PendingPlateCulture = 30,
  PendingResults = 40,
  PendingHalo = 50,
  PendingFungal = 60,
  PendingSemiTraditional = 70,
  MicroCompleted = 99,
}
