export enum SuperBatchState {
  Undefined = 0,
  Created = 10,
  PendingUti = 20,
  UtiSetupCompleted = 21,
  UtiRunCompleted = 22,
  PendingAbr = 30,
  ReadyForAbr = 31,
  AbrSetupCompleted = 32,
  AbrRunCompleted = 33,
}
