import { InputText } from "primereact/inputtext";
import { ExtendedMetaDataItem } from "../../queries/pcr/models/meta-data";

export interface ExtendedMetaDataInputItemProps {
  item: ExtendedMetaDataItem;
  disabled?: boolean;
  onChange: (v: string) => void;
  onBlur?: () => void;
}

export function ExtendedMetaDataInputItem({
  item,
  disabled = false,
  onChange,
  onBlur,
}: ExtendedMetaDataInputItemProps) {
  return (
    <div className="flex flex-row align-items-center justify-content-end mb-1">
      <label className="mr-2">{item.label}</label>
      <InputText
        value={item.value}
        disabled={disabled}
        onChange={(v) => onChange(v.target.value)}
        onBlur={onBlur}
      />
    </div>
  );
}
