import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { useEffect, useState } from "react";
import { MinibatchModel } from "../../queries/pcr/models/mini-batch";

interface PcrRunDataPanelProps {
  batch: MinibatchModel;
  state?: string;
  disabled?: boolean;
  onComplete: (v: PcrRunData) => void;
  onExport: (v: PcrRunData) => void;
}

export interface PcrRunData {
  id: number;
  timeIn?: Date;
  machineId?: string;
}

export function PcrRunDataPanel({
  batch,
  state,
  disabled = false,
  onComplete,
  onExport,
}: PcrRunDataPanelProps) {
  const [timeIn, setTimeIn] = useState<Date | undefined>(batch?.startDate);
  const [machineId, setMachineId] = useState<string>(batch?.machineId ?? "");

  useEffect(() => {
    setTimeIn(batch?.startDate);
    setMachineId(batch?.machineId ?? "");
  }, [batch]);

  function handleComplete() {
    return onComplete({ id: batch.id, timeIn, machineId });
  }

  function handleExport() {
    return onExport({ id: batch.id, timeIn, machineId });
  }

  return (
    <Card className="surface-100">
      <div className="flex flex-column">
        <div className="flex justify-content-center">
          <h3>PCR Run</h3>
        </div>
        <div className="flex justify-content-center">
          <table className="w-full" style={{ borderSpacing: 0 }}>
            <thead>
              <tr className="h-3rem">
                <th className="border-1 border-500">MiniBatch ID</th>
                <th className="border-1 border-500">Status</th>
                <th className="border-1 border-500">Time in</th>
                <th className="border-1 border-500">QuantStudio 5 ID</th>
              </tr>
            </thead>
            <tbody>
              <tr className="h-4rem">
                <td className="border-1 border-500 text-center">
                  {batch?.plateBarcode}
                </td>
                <td className="border-1 border-500 text-center">{state}</td>
                <td className="border-1 border-500 text-center">
                  <Calendar
                    disabled={!batch.isExported}
                    value={timeIn}
                    onChange={(e) => setTimeIn(e.target.value as Date)}
                    showTime
                    showButtonBar
                    hideOnDateTimeSelect
                  />
                </td>
                <td className="border-1 border-500 text-center">
                  <InputText
                    disabled={!batch.isExported}
                    value={machineId}
                    onChange={(e) => setMachineId(e.target.value)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="flex flex-row justify-content-evenly mt-3">
          <Button
            className="p-button-outlined w-8rem justify-content-center"
            disabled={disabled}
            onClick={handleExport}
          >
            Export
          </Button>
          <Button
            className="p-button-outlined w-8rem justify-content-center"
            disabled={disabled || !timeIn || !machineId || !batch.isExported}
            onClick={handleComplete}
          >
            Complete
          </Button>
        </div>
      </div>
    </Card>
  );
}
