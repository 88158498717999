import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useToast } from "../../components/ui/ToastContext";
import { useCreateTubesMutation } from "../../queries/pcr/tubes.query";

export function PagePcrNewTube() {
  const [barcodes, setBarcodes] = useState<string[]>([]);
  const [newBarcode, setNewBarcode] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const navigate = useNavigate();
  const toast = useToast();

  const createTubesMutation = useCreateTubesMutation();

  const queryClient = useQueryClient();

  function addBarcode() {
    let subject = newBarcode.trim();
    if (subject !== "" && barcodes.indexOf(subject) === -1) {
      setBarcodes([...barcodes, subject]);
      setNewBarcode("");
    }
    return true;
  }

  function createTubes() {
    setButtonDisabled(true);
    createTubesMutation.mutate(
      { barcodes: barcodes },
      {
        onSuccess: () => {
          toast.current!.show({
            detail: barcodes.length + " tubes created",
            severity: "success",
          });
          queryClient.invalidateQueries({ queryKey: ["tubes"] });
          queryClient.invalidateQueries({ queryKey: ["pcrtotals"] });
          navigate("../batching-queue");
        },
        onError: (error) => {
          console.error(error);
          setButtonDisabled(false);
        },
      }
    );
  }

  return (
    <>
      <div className="flex flex-column align-items-center">
        <h2>Scan tube IDs:</h2>
        <div className="flex flex-column">
          {barcodes.map((b) => (
            <InputText value={b} className="mb-2" key={b} />
          ))}
          <InputText
            autoFocus
            onKeyDown={(e) => {
              if (e.code === "Tab") {
                e.preventDefault();
                e.stopPropagation();
                addBarcode();
              }
            }}
            value={newBarcode}
            onChange={(e) => setNewBarcode(e.target.value)}
          />
        </div>
        <Button
          label="Create tubes"
          className="w-3 mt-3"
          onClick={createTubes}
          disabled={buttonDisabled || barcodes.length === 0}
        />
      </div>
    </>
  );
}
