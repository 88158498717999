import { format, startOfToday, startOfTomorrow } from "date-fns";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useState } from "react";
import { useDequeuedTubesQuery } from "../../queries/admin/audits.query";
import { TubeState } from "../../queries/pcr/models/tube-state";
import { DATE_FORMAT } from "../../utils/constants";

export function TabDequeuedTubes() {
  const [dateFrom, setDateFrom] = useState<Date>(startOfToday());
  const [dateTo, setDateTo] = useState<Date>(startOfTomorrow());
  const { isLoading, data } = useDequeuedTubesQuery(dateFrom, dateTo);

  return (
    <div>
      <div>
        <div className="flex align-items-center mb-2">
          <label className="mr-2 w-5rem">Date from:</label>
          <Calendar
            value={dateFrom}
            onChange={(e) => setDateFrom(e.value as Date)}
          />
        </div>
        <div className="flex align-items-center mb-2">
          <label className="mr-2 w-5rem">Date to:</label>
          <Calendar
            value={dateTo}
            onChange={(e) => setDateTo(e.value as Date)}
          />
        </div>
      </div>
      {!isLoading && data && (
        <DataTable value={data}>
          <Column
            header="Date"
            dataType="date"
            body={(d) => <span>{format(d.timestamp, DATE_FORMAT)}</span>}
          />
          <Column field="tube" header="Barcode" />
          <Column field="user" header="User" />
          <Column
            header="Last state"
            body={(d) => <span>{TubeState[d.tubeState]}</span>}
          />
          <Column field="comment" header="Comment" />
        </DataTable>
      )}
    </div>
  );
}
