/* eslint-disable react-hooks/rules-of-hooks */
import { useMutation, useQuery } from "react-query";
import { microService } from "../../services/micro/micro.service";
import { CreateTubesModel } from "./models/create-tubes";
import { PrepareSampleTubesModel } from "./models/prepare-sample-tubes";
import { RemoveTubeRequest } from "./models/remove-tube.request";
import { TubeModel } from "./models/tube";
import { TubeState } from "./models/tube-state";
import { UpdateCultureTubesModel } from "./models/update-culture-tubes";
import { UpdatePendingResultsTubesModel } from "./models/update-pending-results-tubes";

async function fetchTubes(states: TubeState[]) {
  return await microService.getTubesByStates(states);
}
async function fetchNegativeTubes() {
  return await microService.getNegativeTubes();
}

async function fetchNoAstCheck(barcodes: string[]) {
  return await microService.getNoAstBarcodes(barcodes);
}

export function useTubesQuery(...states: TubeState[]) {
  return useQuery<TubeModel[]>(["tubes", states], () => fetchTubes(states));
}

export function useNegativeTubesQuery() {
  return useQuery<TubeModel[]>(["tubes"], () => fetchNegativeTubes());
}

export function useTubesNoAstQuery(barcodes: string[], enabled: boolean) {
  return useQuery<string[]>(
    ["tubes", "tubesNoAst", barcodes],
    () => fetchNoAstCheck(barcodes),
    { enabled: enabled }
  );
}

export function useCreateMicroTubesMutation() {
  return useMutation((model: CreateTubesModel) =>
    microService.createMicroTubes(model)
  );
}

export function useArchiveTubesMutation() {
  return useMutation((ids: number[]) => microService.archiveTubes(ids));
}

export function useUpdatePrecultureTubesMutation() {
  return useMutation((model: UpdateCultureTubesModel) =>
    microService.updatePrecultureTubes(model)
  );
}

export function useUpdatePlateCultureTubesMutation() {
  return useMutation((model: UpdateCultureTubesModel) =>
    microService.updatePlateCultureTubes(model)
  );
}

export function useUpdatePendingResultsTubesMutation() {
  return useMutation((model: UpdatePendingResultsTubesModel) =>
    microService.updatePendingResultsTubes(model)
  );
}

export function usePrepareSampleTubesMutation() {
  return useMutation((model: PrepareSampleTubesModel) =>
    microService.prepareSampleTubes(model)
  );
}

export function useRemoveTubeMutation() {
  return useMutation((model: RemoveTubeRequest) =>
    microService.removeTube(model)
  );
}
