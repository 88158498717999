import { PlateCoordinates } from "../../models/pcr/plate-coordinates";
import { SuperbatchItemModel } from "../../queries/pcr/models/super-batch-item";
import { PlateWell } from "./PlateWell";
import { TubeModel } from "../../queries/pcr/models/tube";

interface SuperBatchPlateProps {
  highlightedWells?: PlateCoordinates[];
  superbatchItems?: SuperbatchItemModel[];
  highlightColorClassName?: string;
  enabledIds?: number[];
  showAuditLegend?: boolean;
  highlightColorFunction?: (
    row: string,
    column: string,
    v?: TubeModel
  ) => string | null;
  onHoleClick?: (v: PlateCoordinates) => void;
}

export function SuperBatchPlate({
  highlightedWells,
  superbatchItems,
  highlightColorClassName,
  enabledIds,
  showAuditLegend,
  highlightColorFunction,
  onHoleClick,
}: SuperBatchPlateProps) {
  const rows = ["A", "B", "C", "D", "E", "F", "G", "H"];
  const columns = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
  ];

  function handleWellClick(row: string, column: string) {
    if (onHoleClick) {
      onHoleClick({ row, column });
    }
  }

  return (
    <div>
      <div className="border-1 border-round">
        <div className="flex flex-column">
          <div className="flex flex-row justify-content-evenly">
            <span className="w-1rem"></span>
            {columns.map((c) => (
              <span key={c} className="text-center w-4rem">
                {c}
              </span>
            ))}
          </div>
          {rows.map((r) => (
            <div key={r} className="flex flex-row justify-content-evenly mb-1">
              <span className="justify-content-center align-items-center flex w-1rem line">
                {r}
              </span>
              {columns.map((c) => {
                const tube = superbatchItems?.find(
                  (t) => t.column === c && t.row === r
                )?.tube;
                return (
                  <PlateWell
                    text={tube?.barcode}
                    key={r + "x" + c}
                    highlightColorClassName={
                      highlightColorFunction
                        ? highlightColorFunction(r, c, tube) ?? ""
                        : highlightColorClassName
                    }
                    highlighted={
                      (Boolean(highlightColorFunction) &&
                        Boolean(highlightColorFunction!(r, c, tube))) ||
                      highlightedWells?.some(
                        (w) => w.row === r && w.column === c
                      )
                    }
                    disabled={
                      enabledIds &&
                      superbatchItems &&
                      !enabledIds.some((b) => b === tube?.id)
                    }
                    unavailable={
                      superbatchItems &&
                      !superbatchItems.some(
                        (t) => t.column === c && t.row === r
                      )
                    }
                    onClick={() => handleWellClick(r, c)}
                  />
                );
              })}
            </div>
          ))}
        </div>
      </div>

      {showAuditLegend && (
        <div className="p-2">
          <div className="grid align-content-center align-items-center">
            <div
              className="col-fixed bg-green-400 h-1rem border-700 border-1"
              style={{ width: "50px" }}
            ></div>
            <div className="col">Pos – 4 hour incubation</div>
            <div
              className="col-fixed bg-blue-200 h-1rem border-700 border-1"
              style={{ width: "50px" }}
            ></div>
            <div className="col">Pos – 18 hour incubation</div>
            <div
              className="col-fixed bg-red-200 h-1rem border-700 border-1"
              style={{ width: "50px" }}
            ></div>
            <div className="col">Negative</div>
            <div
              className="col-fixed bg-yellow-200 h-1rem border-700 border-1"
              style={{ width: "50px" }}
            ></div>
            <div className="col">Pending results / Controls and FE Tubes</div>
          </div>
        </div>
      )}
    </div>
  );
}
