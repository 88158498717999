import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect, useState } from "react";
import { MinibatchModel } from "../../queries/pcr/models/mini-batch";
import { BatchResultSelector } from "./BatchResultSelector";
import { PcrBatchResultType } from "../../queries/pcr/models/pcr-batch-result-type";

export interface MiniBatchResultsTabContentProps {
  batches: MinibatchModel[];
  onSetResult: (minibatch: MinibatchModel, result: PcrBatchResultType) => void;
}
export function MiniBatchResultsTabContent({
  batches,
  onSetResult,
}: MiniBatchResultsTabContentProps) {
  const [selectedMinibatch, setSelectedMinibatch] = useState<MinibatchModel>();

  useEffect(() => {
    if (!batches.find((b) => b.id === selectedMinibatch?.id)) {
      setSelectedMinibatch(undefined);
    }
  }, [batches, selectedMinibatch]);

  return (
    <div className="flex flex-row">
      <div className="col-4">
        <DataTable
          value={batches}
          paginator
          rows={10}
          dataKey="id"
          selection={selectedMinibatch}
          onSelectionChange={(e) => setSelectedMinibatch(e.value)}
          selectionMode="single"
          rowClassName={(rowData) => (rowData.isRerun ? "" : "cursor-pointer")}
        >
          <Column
            header="MiniBatch ID"
            alignHeader={"center"}
            field="plateBarcode"
          />
        </DataTable>
      </div>
      {selectedMinibatch && (
        <div className="col-8">
          <BatchResultSelector
            batch={selectedMinibatch}
            header="Batch Result"
            onSubmitted={(minibatch, result) => onSetResult(minibatch, result)}
          />
        </div>
      )}
    </div>
  );
}
