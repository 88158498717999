import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useState } from "react";
import { UserModel } from "../models/admin/user";

export interface ChangePasswordProps {
  user: UserModel
  onChange: (newPassword: string, user: UserModel) => void;
}

export function ChangePassword({ onChange, user }: ChangePasswordProps) {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  function handleSave() {
    return onChange(password, user);
  }

  return (
    <div>
      <div className="grid p-fluid">
        <div className="field col-12">
          <label>Password</label>
          <InputText
            name="p1"
            type="password"
            autoFocus
            value={password}
            onChange={(v) => setPassword(v.target.value)}
          />
        </div>
        <div className="field col-12">
          <label>Confirm password</label>
          <InputText
            name="p2"
            type={"password"}
            value={confirmPassword}
            onChange={(v) => setConfirmPassword(v.target.value)}
          />
        </div>
      </div>
      <div>
        <Button
          label="Save"
          disabled={
            !password || !confirmPassword || password !== confirmPassword
          }
          onClick={handleSave}
        />
      </div>
    </div>
  );
}
