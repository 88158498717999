import { format, startOfTomorrow } from "date-fns";
import { startOfToday } from "date-fns/esm";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { OverlayPanel } from "primereact/overlaypanel";
import { useRef, useState } from "react";
import { ExtendedMetaDataInput } from "../../components/pcr/ExtendedMetaDataInput";
import { AuditModel } from "../../models/admin/audit";
import { useAuditsQuery } from "../../queries/admin/audits.query";
import { MinibatchModel } from "../../queries/pcr/models/mini-batch";
import { SuperbatchModel } from "../../queries/pcr/models/super-batch";
import { DATE_FORMAT } from "../../utils/constants";
import { MultiSelect } from "primereact/multiselect";
import { MicroResult } from "../../queries/micro/models/micro-result";
import { PcrResultType } from "../../queries/pcr/models/pcr-result-type";
import { PcrBatchResultType } from "../../queries/pcr/models/pcr-batch-result-type";
import { TubeWorkupType } from "../../queries/pcr/models/tube-workup-type";
import { TubeType } from "../../queries/pcr/models/tube-type";
import { AuditTrailCol, transformEnumToText } from "../../utils/helpers";
import { Button } from "primereact/button";
import { auditsService } from "../../services/admin/audits.service";
import { saveAs } from "file-saver";

export function TabAuditTrail() {
  const superbatchOp = useRef<any>(null);
  const [selectedSuperbatch, setSelectedSuperbatch] =
    useState<SuperbatchModel>();
  const minibatchhOp = useRef<any>(null);
  const [selectedMinibatch, setSelectedMinibatch] = useState<MinibatchModel>();
  const rawDataOp = useRef<any>(null);
  const [selectedRawDataJson, setSelectedRawDataJson] = useState<any>();

  const extraColumns: AuditTrailCol[] = [
    { field: "quantity", header: "Quantity" },
    { field: "microResultType", header: "Micro Result" },
    { field: "pcrResultType", header: "PCR Result" },
    { field: "pcrBatchResultType", header: "PCR Batch Result" },
    { field: "workupTypeEnum", header: "Workup Type" },
    { field: "tubeType", header: "Tube Type" },
    { field: "tubeState", header: "Tube State" },
    { field: "row", header: "Row" },
    { field: "column", header: "Column" },
    { field: "currentStepComment", header: "Micro Comment" },
    { field: "comment", header: "Comment" },
    { field: "plateBarcode", header: "Plate Barcode" },
  ];
  const [selectedExtraColumns, setSelectedExtraColumns] =
    useState<AuditTrailCol[]>();

  const [dateFrom, setDateFrom] = useState<Date>(startOfToday());
  const [dateTo, setDateTo] = useState<Date>(startOfTomorrow());

  const { isLoading, data } = useAuditsQuery(dateFrom, dateTo);

  async function handleExport() {
      const blob = await auditsService.getAuditExport(dateFrom, dateTo);
      saveAs(blob, `audit_report${format(dateFrom,'ddMMyyyy')}-${format(dateTo,'ddMMyyyy')}.xlsx`);
  }

  const dateTemplate = (rowData: AuditModel) => {
    return <span>{format(rowData.timestamp, DATE_FORMAT)}</span>;
  };

  const actionTemplate = (rowData: AuditModel) => {
    return (
      <span
        className="cursor-pointer transition-colors transition-duration-200 hover:bg-yellow-500 hover:text-gray-900 m-2 px-5 py-3"
        onClick={(e) => {
          if (rowData.rawData === undefined || rowData.rawData.length === 0) {
            return;
          }
          setSelectedRawDataJson(JSON.parse(rowData.rawData));
          rawDataOp.current!.toggle(e);
        }}
      >
        {rowData.actionDescription}
      </span>
    );
  };

  const superbatchTemplate = (rowData: AuditModel) => {
    if (!rowData.superbatch) {
      return "";
    }

    return (
      <span
        className="cursor-pointer transition-colors transition-duration-200 hover:bg-yellow-500 hover:text-gray-900 m-2 px-5 py-3"
        onClick={(e) => {
          setSelectedSuperbatch(rowData.superbatch);
          superbatchOp.current!.toggle(e);
        }}
      >
        {rowData.superbatch.plateBarcode}
      </span>
    );
  };

  const minibatchemplate = (rowData: AuditModel) => {
    if (!rowData.minibatchSnapshot) {
      return "";
    }

    return (
      <span
        className="cursor-pointer transition-colors transition-duration-200 hover:bg-yellow-500 hover:text-gray-900 m-2 px-5 py-3"
        onClick={(e) => {
          setSelectedMinibatch(rowData.minibatchSnapshot);
          minibatchhOp.current!.toggle(e);
        }}
      >
        {rowData.minibatchSnapshot.plateBarcode}
      </span>
    );
  };

  const dateFilterTemplate = (options: any) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        dateFormat="mm/dd/yy"
        placeholder="mm/dd/yyyy"
        mask="99/99/9999"
      />
    );
  };

  const onColumnToggle = (event: any) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = extraColumns.filter((col) =>
      selectedColumns.some(
        (sCol: { field: string }) => sCol.field === col.field
      )
    );
    setSelectedExtraColumns(orderedSelectedColumns);
  };

  const header = (
    <div style={{ textAlign: "left" }}>
      <MultiSelect
        value={selectedExtraColumns}
        options={extraColumns}
        optionLabel="header"
        onChange={onColumnToggle}
        style={{ width: "20em" }}
      />
    </div>
  );


  const extraDataTemplate = (rowData: AuditModel, field: string) => {
    let value = rowData.actionData[field]

    if(field === 'microResultType'){
      value = transformEnumToText(MicroResult[value])
    } if(field === 'pcrResultType'){
      value = transformEnumToText(PcrResultType[value])
    } if(field === 'pcrBatchResultType'){
      value = transformEnumToText(PcrBatchResultType[value])
    } if(field === 'workupTypeEnum'){
      value = transformEnumToText(TubeWorkupType[value])
    } if(field === 'tubeType'){
      value = transformEnumToText(TubeType[value])
    }

    return (
      <span >
        {value}
      </span>
    );
  };

  const columnComponents = selectedExtraColumns?.map((col) => {
      return (
        <Column
          key={col.field}
          field={col.field}
          header={col.header}
          filterField={col.field}
          sortable
          filter
          body={x => extraDataTemplate(x, col.field!)}
        />
      );
  });

  return (
    <div>
      <div>
        <div className="flex align-items-center mb-2">
          <label className="mr-2 w-5rem">Date from:</label>
          <Calendar
            value={dateFrom}
            onChange={(e) => setDateFrom(e.value as Date)}
          />
        </div>
        <div className="flex align-items-center mb-2">
          <label className="mr-2 w-5rem">Date to:</label>
          <Calendar
            value={dateTo}
            onChange={(e) => setDateTo(e.value as Date)}
          />
        </div>
          <Button
            className="p-button-outlined w-8rem justify-content-center"
            disabled={dateFrom === null || dateTo === null}
            onClick={handleExport}
          >
            Export
          </Button>
      </div>
      <hr />
      {!isLoading && data && (
        <DataTable
          header={header}
          filterDisplay="menu"
          value={data}
          paginator
          rows={12}
          globalFilterFields={["tubeId", "action", "user", "timestamp"]}
        >
          <Column field="tube" header="Tube" filter sortable />
          <Column
            field="actionDescription"
            header="Action"
            filter
            sortable
          />
          <Column
            field="minibatch"
            header="Minibatch"
            body={minibatchemplate}
            filter
            sortable
          />
          <Column
            field="superbatch"
            header="Superbatch"
            body={superbatchTemplate}
            filter
            sortable
          />
          <Column field="user" header="User" filter sortable />
          <Column
            field="timestamp"
            header="Timestamp"
            body={dateTemplate}
            filter
            sortable
            dataType="date"
            filterElement={dateFilterTemplate}
            filterField="timestamp"
          />
          {columnComponents}
        </DataTable>
      )}
      <OverlayPanel ref={superbatchOp}>
        {selectedSuperbatch && (
          <div className="flex flex-row">
            <div className="flex-1">
              <ExtendedMetaDataInput
                metadata={selectedSuperbatch!.metadata}
                disabled={true}
              />
            </div>
          </div>
        )}
      </OverlayPanel>
      <OverlayPanel ref={minibatchhOp}>
        {selectedMinibatch && (
          <div className="flex flex-row">
            <div className="flex-1">
              <ExtendedMetaDataInput
                metadata={selectedMinibatch!.metadata}
                disabled={true}
              />
            </div>
          </div>
        )}
      </OverlayPanel>
      <OverlayPanel ref={minibatchhOp}>
        {selectedMinibatch && (
          <div className="flex flex-row">
            <div className="flex-1">
              <ExtendedMetaDataInput
                metadata={selectedMinibatch!.metadata}
                disabled={true}
              />
            </div>
          </div>
        )}
      </OverlayPanel>
      <OverlayPanel ref={rawDataOp}>
        {selectedRawDataJson &&
          Object.keys(selectedRawDataJson).map((rawDataKey) => (
            <div key={rawDataKey} className="flex flex-row">
              {rawDataKey}: {JSON.stringify(selectedRawDataJson[rawDataKey])}
            </div>
          ))}
      </OverlayPanel>
    </div>
  );
}
