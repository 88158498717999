import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputNumber } from "primereact/inputnumber";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { PendingMicroTubeModel } from "../../models/micro/pending-micro-tube";
import { TubeState } from "../../queries/micro/models/tube-state";
import {
  usePrepareSampleTubesMutation,
  useTubesQuery,
} from "../../queries/micro/tubes.query";

export function PageMicroSamplePreparation() {
  const [tubes, setTubes] = useState<PendingMicroTubeModel[]>([]);

  const [selectedTube, setSelectedTube] = useState<PendingMicroTubeModel>();

  const [haloTubesNumber, setHaloTubesNumber] = useState(1);
  const [fungalTubesNumber, setFungalTubesNumber] = useState(1);
  const [semiTradTubesNumber, setSemiTradTubesNumber] = useState(4);

  const { isLoading, data, dataUpdatedAt } = useTubesQuery(
    TubeState.PendingFungal,
    TubeState.PendingHalo,
    TubeState.PendingSemiTraditional
  );
  const tubesMutation = usePrepareSampleTubesMutation();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!isLoading && data) {
      setTubes([
        ...data.map((d) => {
          return {
            id: d.id,
            barcode: d.barcode,
            state: d.state,
          } as PendingMicroTubeModel;
        }),
      ]);
    }
  }, [isLoading, data, dataUpdatedAt]);

  const barcodeCellTemplate = (rowData: PendingMicroTubeModel) => {
    return <span className="cursor-pointer">{rowData.barcode}</span>;
  };

  const stateCellTemplate = (rowData: PendingMicroTubeModel) => {
    return (
      <span>
        {rowData.state === TubeState.PendingFungal && "Fungal"}
        {rowData.state === TubeState.PendingHalo && "FE"}
        {rowData.state === TubeState.PendingSemiTraditional && "SEMI-TRAD"}
      </span>
    );
  };

  function createHaloTubes() {
    if (selectedTube) {
      tubesMutation.mutate(
        {
          parentId: selectedTube.id,
          quantity: haloTubesNumber,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries("tubes");
            queryClient.invalidateQueries("micrototals");
          },
          onError: (error) => console.error(error),
        }
      );
      setSelectedTube(undefined);
    }
  }

  function createFungalTubes() {
    if (selectedTube) {
      tubesMutation.mutate(
        {
          parentId: selectedTube.id,
          quantity: fungalTubesNumber,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries("tubes");
            queryClient.invalidateQueries("micrototals");
          },
          onError: (error) => console.error(error),
        }
      );
      setSelectedTube(undefined);
    }
  }

  function createSemiTradTubes() {
    if (selectedTube) {
      tubesMutation.mutate(
        {
          parentId: selectedTube.id,
          quantity: semiTradTubesNumber,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries("tubes");
            queryClient.invalidateQueries("micrototals");
          },
          onError: (error) => console.error(error),
        }
      );
      setSelectedTube(undefined);
    }
  }

  return (
    <div className="flex flex-row">
      <div className="col-6">
        <DataTable
          value={tubes}
          paginator
          rows={10}
          selectionMode="single"
          rowClassName={() => "cursor-pointer"}
          onRowClick={(d) => setSelectedTube(d.data)}
        >
          <Column
            header="Tube ID"
            alignHeader={"center"}
            body={barcodeCellTemplate}
          />
          <Column
            header="State"
            alignHeader={"center"}
            body={stateCellTemplate}
          />
        </DataTable>
      </div>
      {selectedTube && (
        <div className="col-6">
          {selectedTube.state === TubeState.PendingHalo && (
            <Card className="surface-200">
              <h3>ID: {selectedTube?.barcode}</h3>
              <h3>How many tubes will be generated for FE workup?</h3>
              <div className="flex flex-column align-items-center">
                <InputNumber
                  value={haloTubesNumber}
                  onValueChange={(v) => setHaloTubesNumber(v.target.value ?? 0)}
                  min={1}
                  max={5}
                  showButtons
                  buttonLayout="horizontal"
                />
              </div>

              <div className="flex justify-content-center mt-4">
                <Button
                  className="p-button-outlined"
                  label="Submit"
                  onClick={createHaloTubes}
                />
              </div>
            </Card>
          )}
          {selectedTube.state === TubeState.PendingFungal && (
            <Card className="surface-200">
              <h3>ID: {selectedTube?.barcode}</h3>
              <h3>How many tubes will be generated for fungal workup?</h3>
              <div className="flex flex-column align-items-center">
                <InputNumber
                  value={fungalTubesNumber}
                  onValueChange={(v) =>
                    setFungalTubesNumber(v.target.value ?? 0)
                  }
                  min={1}
                  max={3}
                  showButtons
                  buttonLayout="horizontal"
                />
              </div>

              <div className="flex justify-content-center mt-4">
                <Button
                  className="p-button-outlined"
                  label="Submit"
                  onClick={createFungalTubes}
                />
              </div>
            </Card>
          )}
          {selectedTube.state === TubeState.PendingSemiTraditional && (
            <Card className="surface-200">
              <h3>ID: {selectedTube?.barcode}</h3>
              <h3>How many tubes will be generated for workup?</h3>
              <div className="flex flex-column align-items-center">
                <InputNumber
                  value={semiTradTubesNumber}
                  onValueChange={(v) =>
                    setSemiTradTubesNumber(v.target.value ?? 0)
                  }
                  min={1}
                  max={4}
                  showButtons
                  buttonLayout="horizontal"
                />
              </div>

              <div className="flex justify-content-center mt-4">
                <Button
                  className="p-button-outlined"
                  label="Submit"
                  onClick={createSemiTradTubes}
                />
              </div>
            </Card>
          )}
        </div>
      )}
    </div>
  );
}
