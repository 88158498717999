import { format, startOfToday, startOfTomorrow } from "date-fns";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useState } from "react";
import { ActionType } from "../../queries/action-type";
import { useRerunTubesQuery } from "../../queries/admin/audits.query";
import { TubeState } from "../../queries/pcr/models/tube-state";
import { DATE_FORMAT } from "../../utils/constants";

export function TabTubeReruns() {
  const [dateFrom, setDateFrom] = useState<Date>(startOfToday());
  const [dateTo, setDateTo] = useState<Date>(startOfTomorrow());
  const { isLoading, data } = useRerunTubesQuery(dateFrom, dateTo);

  return (
    <div>
      <div>
        <div className="flex align-items-center mb-2">
          <label className="mr-2 w-5rem">Date from:</label>
          <Calendar
            value={dateFrom}
            onChange={(e) => setDateFrom(e.value as Date)}
          />
        </div>
        <div className="flex align-items-center mb-2">
          <label className="mr-2 w-5rem">Date to:</label>
          <Calendar
            value={dateTo}
            onChange={(e) => setDateTo(e.value as Date)}
          />
        </div>
      </div>
      {!isLoading && data && (
        <DataTable value={data}>
          <Column
            header="Date"
            dataType="date"
            body={(d) => <span>{format(d.timestamp, DATE_FORMAT)}</span>}
          />
          <Column
            header="Type"
            body={(data) => ActionType[data.type].toString()}
          />
          <Column field="tube" header="Tube barcode" />
          <Column field="minibatch" header="Minibatch barcode" />
          <Column field="user" header="User" />
          <Column
            header="State at rerun"
            body={(d) => <span>{TubeState[d.tubeState]}</span>}
          />
        </DataTable>
      )}
    </div>
  );
}
