import { classNames } from "primereact/utils";

interface PlateWellProps {
  text?: string;
  highlighted?: boolean;
  highlightColorClassName?: string;
  disabled?: boolean;
  unavailable?: boolean;
  onClick?: () => void;
}

export function PlateWell({
  text,
  highlighted = false,
  highlightColorClassName = "bg-green-200",
  disabled = false,
  unavailable = false,
  onClick,
}: PlateWellProps) {
  return (
    <>
      <div
        className={classNames(
          "border-1 border-circle text-xs h-4rem w-4rem flex justify-content-center align-items-center text-center",
          !highlighted && !disabled && !unavailable ? "bg-yellow-200" : "",
          highlighted ? highlightColorClassName : "",
          disabled ? "bg-gray-400" : "",
          unavailable ? "background-striped" : "",
          onClick && !disabled ? "cursor-pointer" : ""
        )}
        onClick={disabled ? undefined : onClick}
      >
        <span className="word-break-all">{text}</span>
      </div>
    </>
  );
}
