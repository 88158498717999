/* eslint-disable react-hooks/rules-of-hooks */
import { useMutation, useQuery } from "react-query";
import { pcrService } from "../../services/pcr/pcr.service";
import { AssignTubeResultRequest } from "./models/assign-tube-result-request";
import { BatchTubesRequest } from "./models/batch-tubes-request";
import { CreateTubesModel } from "./models/create-tubes";
import { RemoveTubeRequest } from "./models/remove-tube.request";
import { RerunTubeRequest } from "./models/rerun-tube.request";
import { TubeModel } from "./models/tube";
import { TubeState } from "./models/tube-state";

async function fetchTubes(states: TubeState[]) {
  return await pcrService.getTubesByStates(states);
}

export function useTubesQuery(...states: TubeState[]) {
  return useQuery<TubeModel[]>(["tubes", states], () => fetchTubes(states));
}

export function useCreateTubesMutation() {
  return useMutation((model: CreateTubesModel) =>
    pcrService.createPcrTubes(model)
  );
}

export function useBatchTubesMutation() {
  return useMutation((model: BatchTubesRequest) =>
    pcrService.batchTubes(model)
  );
}

export function useAssignResultMutation() {
  return useMutation((model: AssignTubeResultRequest) =>
    pcrService.assignResult(model)
  );
}

export function useRerunTubesMutation() {
  return useMutation((model: RerunTubeRequest) => pcrService.rerunTubes(model));
}

export function useRemoveTubeMutation() {
  return useMutation((model: RemoveTubeRequest) =>
    pcrService.removeTube(model)
  );
}
