import { TabPanel, TabView } from "primereact/tabview";
import { TabPcrBatchResultsAbr } from "./TabPcrBatchResultsAbr";
import { TabPcrBatchResultsUti } from "./TabPcrBatchResultsUti";

export function PagePcrBatchResults() {
  return (
    <TabView>
      <TabPanel header="UTI">
        <TabPcrBatchResultsUti />
      </TabPanel>
      <TabPanel header="ABR">
        <TabPcrBatchResultsAbr />
      </TabPanel>
    </TabView>
  );
}
