import { PageWrapper } from "../../components/ui/PageWrapper";
import { Outlet } from "react-router-dom";
import { RouterAwareTabMenu } from "../../components/ui/RouterAwareTabMenu";
import { TopLoader } from "../../components/TopLoader";
import { usePcrTotalsQuery } from "../../queries/admin/configuration.query";
import { useEffect, useState } from "react";

export function Pcr() {
  
  const [items, setItems] = useState<{ label: string; url: string }[]>([
    { label: "New", url: "new" },
    { label: "Batching Queue", url: "batching-queue" },
    { label: "SuperBatch", url: "superbatch" },
    { label: "UTI Setup", url: "uti-setup" },
    { label: "UTI Run Queue", url: "uti-run" },
    { label: "ABR Batching", url: "abr-batching" },
    { label: "ABR Setup", url: "abr-setup" },
    { label: "ABR Run Queue", url: "abr-run" },
    { label: "Batch Results", url: "batch-results" },
    { label: "Pending Results", url: "pending-results" },
  ] as { label: string; url: string }[]);
  const { isLoading, data, dataUpdatedAt } = usePcrTotalsQuery();

  useEffect(() => {
    if (!isLoading && data) {
      setItems([
        { label: `New`, url: "new" },
        { label: `Batching Queue (${data.batchingQueue})`, url: "batching-queue" },
        { label: `SuperBatch (${data.superBatch})`, url: "superbatch" },
        { label: `UTI Setup (${data.utiSetup})`, url: "uti-setup" },
        { label: `UTI Run Queue (${data.utiRunQueue})`, url: "uti-run" },
        { label: `ABR Batching (${data.abrBatching})`, url: "abr-batching" },
        { label: `ABR Setup (${data.abrSetup})`, url: "abr-setup" },
        { label: `ABR Run Queue (${data.abrRunQueue})`, url: "abr-run" },
        { label: `Batch Results (${data.batchResults})`, url: "batch-results" },
        { label: `Pending Results (${data.pendingResults})`, url: "pending-results" },
      ] as { label: string; url: string }[]);
    }
  }, [isLoading, data, dataUpdatedAt]);


  return (
    <div>
      <RouterAwareTabMenu items={items} parentRoute="/pcr" />
      <TopLoader />
      <PageWrapper>
        <Outlet />
      </PageWrapper>
    </div>
  );
}
