import { Button } from "primereact/button";
import { TabPanel, TabView } from "primereact/tabview";
import { useEffect, useMemo, useState } from "react";
import { MiniBatchPlate } from "../../components/pcr/MiniBatchPlate";
import { SuperBatchPlate } from "../../components/pcr/SuperBatchPlate";
import { SuperbatchModel } from "../../queries/pcr/models/super-batch";
import {
  useCompleteMinibatchUtiMutation,
  useSuperBatchesQuery,
  useSuperBatchesWithMinibatchesQuery,
  useSuperBatchQuery,
} from "../../queries/pcr/superBatches.query";
import { SuperbatchSelector } from "../../components/pcr/SuperbatchSelector";
import { SuperBatchState } from "../../queries/pcr/models/super-batch-state";
import { InputText } from "primereact/inputtext";
import { PlateCoordinates } from "../../models/pcr/plate-coordinates";
import { MiniBatchState } from "../../queries/pcr/models/mini-batch-state";
import { useQueryClient } from "react-query";
import { ExtendedMetaData } from "../../queries/pcr/models/meta-data";
import { useToast } from "../../components/ui/ToastContext";
import { PlateWell } from "../../components/pcr/PlateWell";
import { Tag } from "primereact/tag";
import { TubeType } from "../../queries/pcr/models/tube-type";
import { classNames } from "primereact/utils";
import { RemoveTubeButton } from "../../components/ui/RemoveTubeButton";
import { TubeModel } from "../../queries/pcr/models/tube";
import { useRemoveTubeMutation } from "../../queries/pcr/tubes.query";
import { isMinibatchUti } from "../../utils/minibatch-utils";
import { MiniBatchMetaDataInput } from "../../components/pcr/MiniBatchMetaDataInput";
import { isMetaDataValid } from "../../utils/helpers";
import { CommentInput } from "../../components/pcr/CommentInput";

export function PagePcrUtiSetup() {
  const [selectedSuperBatch, setSelectedSuperBatch] =
    useState<SuperbatchModel>();
  const [highlightedWell, setHighlightedWell] = useState<PlateCoordinates>();
  const [selectedId, setSelectedId] = useState<number>();
  const [selectedTube, setSelectedTube] = useState<TubeModel>();

  const [selectedMiniBatchIndex, setSelectedMiniBatchIndex] = useState(0);
  const [selectedPlateBarcode, setSelectedPlateBarcode] = useState<string>("");
  const [selectedMiniBatchMetaData, setSelectedMiniBatchMetaData] =
    useState<ExtendedMetaData>({} as ExtendedMetaData);
  const [selectedMiniBatchComment, setSelectedMinibatchComment] =
    useState<string>("");

  const [enabledSuperBatchTubeIds, setEnabledSuperBatchTubeIds] =
    useState<number[]>();
  const completeMinibatchUtiMutation = useCompleteMinibatchUtiMutation();
  const removeTubeMutation = useRemoveTubeMutation();

  const superBatchQuery = useSuperBatchQuery(selectedSuperBatch?.id);
  const superBatchesQuery = useSuperBatchesWithMinibatchesQuery(
    SuperBatchState.PendingUti,
    SuperBatchState.UtiSetupCompleted,
    SuperBatchState.UtiRunCompleted,
    SuperBatchState.PendingAbr
  );

  const toast = useToast();
  const queryClient = useQueryClient();

  const minibatches = useMemo(() => {
    if (superBatchQuery.data) {
      return superBatchQuery.data.miniBatches?.filter((x) => isMinibatchUti(x));
    }
  }, [superBatchQuery.data, superBatchQuery.dataUpdatedAt]);

  const superBatchDetails = superBatchQuery.data;

  function handleTabIndexChange(index: number) {
    //setSelectedPlateBarcode("");
    setSelectedMiniBatchIndex(index);
  }

  const selectedMinibatch = useMemo(() => {
    return minibatches?.[selectedMiniBatchIndex];
  }, [selectedMiniBatchIndex, minibatches]);

  useEffect(() => {
    if (minibatches) {
      //if (selectedMinibatch?.state !== MiniBatchState.PendingUtiSetup) {
      //  setSelectedPlateBarcode(selectedMinibatch?.plateBarcode ?? "");
      // }

      setSelectedPlateBarcode(selectedMinibatch?.plateBarcode ?? "");

      if (selectedMinibatch) {
        setSelectedMiniBatchMetaData(selectedMinibatch.metadata);
        setSelectedMinibatchComment(selectedMinibatch.comment ?? "");
      }
    }
  }, [selectedMinibatch, minibatches]);

  useEffect(() => {
    if (minibatches && minibatches.length > 0) {
      const enabledTubesIds = minibatches?.[
        selectedMiniBatchIndex
      ].items.flatMap((s) => s.tube.id);

      setEnabledSuperBatchTubeIds(enabledTubesIds);
    }
  }, [selectedMiniBatchIndex, minibatches]);

  function metadataIsFilled() {
    return isMetaDataValid(selectedMiniBatchMetaData);
  }

  function canBeCompleted() {
    return (
      selectedPlateBarcode.trim() &&
      selectedMinibatch?.state === MiniBatchState.PendingUtiSetup &&
      metadataIsFilled()
    );
  }

  function canBeModified() {
    let result = selectedMinibatch?.state === MiniBatchState.PendingUtiSetup;
    return result;
  }

  function canBeSaved() {
    let result =
      superBatchDetails &&
      superBatchDetails.miniBatches &&
      selectedMinibatch?.state === MiniBatchState.CompletedUtiSetup;
    return result;
  }

  function save() {
    if (selectedSuperBatch) {
      setSelectedSuperBatch(undefined);
    }
  }

  function toggleWell(c: PlateCoordinates) {
    const tube = superBatchDetails!.items.find(
      (t) => t.column === c.column && t.row === c.row
    );

    if (tube) {
      if (
        highlightedWell?.column === c.column &&
        highlightedWell.row === c.row
      ) {
        setSelectedId(undefined);
        setHighlightedWell(undefined);
        setSelectedTube(undefined);
      } else {
        setSelectedId(tube.tube.id);
        setHighlightedWell(c);
        setSelectedTube(tube.tube);
      }
    }
  }

  function complete() {
    if (
      selectedPlateBarcode &&
      superBatchDetails &&
      superBatchDetails.miniBatches
    ) {
      completeMinibatchUtiMutation.mutate(
        {
          id: selectedMinibatch!.id,
          plateBarcode: selectedPlateBarcode,
          metadata: selectedMiniBatchMetaData,
          comment: selectedMiniBatchComment,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries();
            toast.current!.show({
              detail: "Minibatch confirmed",
              severity: "success",
            });
          },
          onError: (error) => console.error(error),
        }
      );
    }
  }

  function removeTube(tubeId: number, comment: string) {
    removeTubeMutation.mutate(
      {
        tubeId: tubeId,
        comment: comment,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries("superBatches");
          queryClient.invalidateQueries("tubes");
          queryClient.invalidateQueries("pcrtotals");
          setSelectedTube(undefined);
          setSelectedId(undefined);
          setHighlightedWell(undefined);
          toast.current!.show({
            detail: "Tube removed",
            severity: "success",
          });
        },
        onError: (error) => console.error(error),
      }
    );
  }

  function shouldTabBeDisabled(index: number) {
    if (index === 0) return false;

    const previousMiniBatch = superBatchDetails!.miniBatches?.[index - 1];

    return previousMiniBatch!.state === MiniBatchState.PendingUtiSetup;
  }

  function renderControlWell(tubeType: TubeType) {
    const tube = selectedMinibatch?.items.find(
      (i) => i.tube.type === tubeType
    )?.tube;

    if (!tube) {
      return <div></div>;
    }

    return (
      <div className="ml-1">
        <PlateWell
          text={tube.barcode}
          highlighted={selectedId === tube.id}
          onClick={() => {
            if (selectedId === tube.id) {
              setSelectedId(undefined);
            } else {
              setSelectedId(tube.id);
            }
            setHighlightedWell(undefined);
          }}
        />
      </div>
    );
  }

  return (
    <>
      {!superBatchesQuery.isLoading &&
        superBatchesQuery.data?.filter((x) =>
          x.miniBatches?.some((x) => x.state === MiniBatchState.PendingUtiSetup)
        ) &&
        !selectedSuperBatch && (
          <SuperbatchSelector
            batches={superBatchesQuery.data?.filter((x) =>
              x.miniBatches?.some(
                (x) => x.state === MiniBatchState.PendingUtiSetup
              )
            )}
            header="SuperBatch ID"
            showPlateIdColumn={true}
            onSelect={setSelectedSuperBatch}
          />
        )}
      {selectedSuperBatch && superBatchDetails && (
        <div className="flex flex-row">
          <div className="col-6">
            <div className="flex flex-row justify-content-evenly align-items-center mb-3">
              <span>SUPERBATCH ID: {selectedSuperBatch.reference}</span>
            </div>
            <div>
              <SuperBatchPlate
                superbatchItems={superBatchDetails.items}
                highlightedWells={highlightedWell ? [highlightedWell] : []}
                onHoleClick={(c) => toggleWell(c)}
                enabledIds={enabledSuperBatchTubeIds}
              />
            </div>
            {selectedMinibatch && (
              <div className="flex flex-row justify-content-end mt-2">
                {renderControlWell(TubeType.ControlPcrPos)}
              </div>
            )}
            <div className="mt-2 flex flex-row justify-content-between">
              <Button disabled={!canBeSaved()} onClick={save}>
                Save
              </Button>
              {selectedTube && selectedTube.type === TubeType.Regular && (
                <RemoveTubeButton
                  tube={selectedTube}
                  onConfirmRemove={removeTube}
                />
              )}
            </div>
          </div>
          {minibatches && (
            <div className="col-6">
              <TabView
                activeIndex={selectedMiniBatchIndex}
                onTabChange={(e) => handleTabIndexChange(e.index)}
              >
                {minibatches.map((miniBatch, index) => (
                  <TabPanel
                    header={"Plate " + (index + 1)}
                    key={index}
                    disabled={shouldTabBeDisabled(index)}
                    headerClassName={classNames(
                      miniBatch.state !== MiniBatchState.PendingUtiSetup
                        ? "line-through"
                        : undefined
                    )}
                  >
                    <div className="mb-3">
                      <span>Minibatch plate ID: </span>
                      <InputText
                        disabled={!canBeModified()}
                        autoFocus={!miniBatch.plateBarcode}
                        value={selectedPlateBarcode}
                        onChange={(e) =>
                          setSelectedPlateBarcode(e.target.value)
                        }
                      ></InputText>
                    </div>
                    <MiniBatchPlate
                      miniBatch={miniBatch}
                      selectedIds={selectedId}
                    />
                  </TabPanel>
                ))}
              </TabView>
              <div>
                <MiniBatchMetaDataInput
                  metadata={selectedMiniBatchMetaData}
                  disabled={
                    selectedMinibatch?.state !== MiniBatchState.PendingUtiSetup
                  }
                  onChange={setSelectedMiniBatchMetaData}
                />
              </div>
              <div>
                <CommentInput
                  comment={selectedMiniBatchComment}
                  onChange={setSelectedMinibatchComment}
                  disabled={
                    selectedMinibatch?.state !== MiniBatchState.PendingUtiSetup
                  }
                />
              </div>
              <div className=" flex mt-2 justify-content-end align-items-center">
                {!selectedPlateBarcode.trim() && (
                  <Tag
                    className="mr-2"
                    icon="pi pi-exclamation-triangle"
                    severity="warning"
                    value="Plate ID not filled"
                  ></Tag>
                )}
                {!metadataIsFilled() && (
                  <Tag
                    className="mr-2"
                    icon="pi pi-exclamation-triangle"
                    severity="warning"
                    value="Metadata not filled"
                  ></Tag>
                )}
                <Button disabled={!canBeCompleted()} onClick={complete}>
                  Complete Minibatch
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}
