import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useToast } from "../../components/ui/ToastContext";
import { NegativePcrTube } from "../../models/micro/negative-pcr-tube";
import { TubeModel } from "../../queries/micro/models/tube";
import {
  useArchiveTubesMutation,
  useNegativeTubesQuery,
} from "../../queries/micro/tubes.query";

export function PageMicroNegativePcrResults() {
  const toast = useToast();
  const queryClient = useQueryClient();
  const { isLoading, data, dataUpdatedAt } = useNegativeTubesQuery();
  const tubesMutation = useArchiveTubesMutation();

  const [tubes, setTubes] = useState(data);

  const [selectedTubes, setSelectedTubes] = useState<NegativePcrTube[]>([]);

  useEffect(() => {
    if (!isLoading && data) {
      setTubes([
        ...data.map((d) => {
          return {
            id: d.id,
            barcode: d.barcode,
            state: d.state,
          } as TubeModel;
        }),
      ]);
    }
  }, [isLoading, data, dataUpdatedAt]);

  function deleteSelected() {
    tubesMutation.mutate(
      selectedTubes.map((x) => x.id),
      {
        onSuccess: () => {
          queryClient.invalidateQueries();
          toast.current!.show({
            severity: "success",
            summary: "Success Message",
            detail: "Tube archived",
          });
        },
        onError: (error) => console.error(error),
      }
    );
  }

  return (
    <div className="flex flex-row justify-content-center">
      <div className="col-6 flex flex-column align-items-center">
        <DataTable
          value={tubes}
          paginator
          rows={10}
          rowClassName={() => "cursor-pointer"}
          selection={selectedTubes}
          onSelectionChange={(e) => setSelectedTubes(e.value)}
        >
          <Column selectionMode="multiple" headerClassName="w-3rem" />
          <Column
            field="barcode"
            header="Tube ID"
            alignHeader={"center"}
            body={(rowData) => <span>{rowData.barcode}</span>}
          />
        </DataTable>

        <Button
          className="p-button-outlined mt-3"
          label="Delete"
          onClick={deleteSelected}
          disabled={selectedTubes.length === 0}
        />
      </div>
    </div>
  );
}
