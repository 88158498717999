import { InputTextarea } from "primereact/inputtextarea";

export interface CommentInputProps {
  comment: string;
  disabled?: boolean;
  onChange: (v: string) => void;
}
export function CommentInput({
  comment,
  disabled = false,
  onChange,
}: CommentInputProps) {
  return (
    <div className="flex flex-row align-items-center justify-content-end mb-1">
      <label className="mr-2">Comment</label>
      <InputTextarea
        value={comment}
        disabled={disabled}
        className="w-30rem"
        onChange={(v) => onChange(v.target.value)}
        maxLength={500}
      />
    </div>
  );
}
