import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { confirmDialog } from "primereact/confirmdialog";
import { UserModel } from "../../models/admin/user";
import { UserType } from "../../models/admin/user-type";
import { Dialog } from "primereact/dialog";
import { useMemo, useState } from "react";
import { AddUser } from "../../components/AddUser";
import { ChangePassword } from "../../components/ChangePassword";
import {
  useChangePasswordMutation,
  useCreateUserMutation,
  useArchiveUserMutation,
  useUnarchiveUserMutation,
  useUsersQuery,
} from "../../queries/admin/users.query";
import { CreateUserModel } from "../../queries/admin/models/create-user";
import { useQueryClient } from "react-query";

export function PageUsers() {
  const [addUserVisible, setAddUserVisible] = useState(false);
  const [changePasswordVisible, setChangePasswordVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState<UserModel | null>(null);

  const queryClient = useQueryClient();
  const usersQuery = useUsersQuery();
  const createUserMutation = useCreateUserMutation();
  const archiveUserMutation = useArchiveUserMutation();
  const changePasswordMutation = useChangePasswordMutation();
  const unarchiveUserMutation = useUnarchiveUserMutation();

  const users = useMemo(() => {
    return usersQuery.data?.sort((a, b) => a.email.localeCompare(b.email));
  }, [usersQuery.data, usersQuery.dataUpdatedAt]);

  const typeTemplate = (rowData: UserModel) => {
    let rolesArray = [];
    if ((rowData.roles & UserType.Admin) == UserType.Admin) {
      rolesArray.push(UserType[UserType.Admin]);
    }
    if ((rowData.roles & UserType.Pcr) == UserType.Pcr) {
      rolesArray.push(UserType[UserType.Pcr]);
    }
    if ((rowData.roles & UserType.Micro) == UserType.Micro) {
      rolesArray.push(UserType[UserType.Micro]);
    }
    return <span>{rolesArray.join(", ")}</span>;
  };

  const actionsTemplate = (rowData: UserModel) => {
    return (
      <div className="flex justify-content-end">
        <Button
          icon="pi pi-key"
          className="mr-2"
          title="Reset password"
          onClick={() => showChangePasswordDialog(rowData)}
        />
        {rowData.isArchived ? (
          <Button
            icon="pi pi-undo"
            className="p-button-warning"
            title="Restore user"
            onClick={() => showUnarchiveUserConfirmation(rowData)}
          />
        ) : (
          <Button
            icon="pi pi-trash"
            className="p-button-danger"
            title="Archive user"
            onClick={() => showArchiveUserConfirmation(rowData)}
          />
        )}
      </div>
    );
  };

  function showChangePasswordDialog(user: UserModel) {
    setSelectedUser(user);
    setChangePasswordVisible(true);
  }

  function showArchiveUserConfirmation(user: UserModel) {
    setSelectedUser(user);
    confirmDialog({
      message: "Are you sure you want to archive this user?",
      header: "Archive user",
      icon: "pi pi-exclamation-triangle",
      accept: () => archiveUser(user.id),
      reject: () => setSelectedUser(null),
    });
  }

  function showUnarchiveUserConfirmation(user: UserModel) {
    setSelectedUser(user);
    confirmDialog({
      message: "Are you sure you want to restore access for this user?",
      header: "Restore user",
      icon: "pi pi-exclamation-triangle",
      accept: () => unarchiveUser(user.id),
      reject: () => setSelectedUser(null),
    });
  }

  function addUser(model: CreateUserModel) {
    createUserMutation.mutate(model, {
      onSuccess: () => {
        queryClient.invalidateQueries("users");
      },
      onError: (error) => console.error("Error:", error),
    });
  }

  function archiveUser(userId: number) {
    archiveUserMutation.mutate(userId, {
      onSuccess: () => {
        queryClient.invalidateQueries("users");
      },
    });
  }

  function unarchiveUser(userId: number) {
    unarchiveUserMutation.mutate(userId, {
      onSuccess: () => {
        queryClient.invalidateQueries("users");
      },
    });
  }

  return (
    <>
      {users && (
        <div>
          <div className="flex justify-content-end">
            <Button
              icon="pi pi-plus"
              className="p-button-success mb-2"
              onClick={() => setAddUserVisible(true)}
            />
          </div>
          <DataTable
            value={users}
            paginator
            rows={10}
            globalFilterFields={["email", "roles"]}
            rowClassName={(rowData) => {
              return rowData.isArchived ? "text-500" : "";
            }}
          >
            <Column field="email" header="Email" />
            <Column field="roles" header="Roles" body={typeTemplate} />
            <Column body={actionsTemplate} />
          </DataTable>
          <Dialog
            visible={addUserVisible}
            header="Add user"
            onHide={() => setAddUserVisible(false)}
          >
            <AddUser
              onAdd={(u) => {
                addUser(u);
                setAddUserVisible(false);
              }}
            />
          </Dialog>
          <Dialog
            visible={changePasswordVisible}
            header="Change password"
            onHide={() => setChangePasswordVisible(false)}
          >
            <ChangePassword
              user={selectedUser!}
              onChange={(p, u) => {
                changePasswordMutation.mutate({ password: p, userId: u.id });
                setChangePasswordVisible(false);
              }}
            />
          </Dialog>
        </div>
      )}
    </>
  );
}
