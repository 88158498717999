import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { RadioButton } from "primereact/radiobutton";
import { useMemo, useState } from "react";
import { PcrResultType } from "../../queries/pcr/models/pcr-result-type";
import { TubeModel } from "../../queries/pcr/models/tube";
import { TubeState } from "../../queries/pcr/models/tube-state";
import { InputTextarea } from "primereact/inputtextarea";

interface PendingResultSelectorProps<T> {
  tubes: T[];
  header: string;
  showOptions?: boolean;
  onSubmitted: (v: T[], r: PcrResultType, c: string) => void;
}

export function PendingResultSelector<T extends TubeModel>({
  tubes,
  header,
  showOptions = true,
  onSubmitted,
}: PendingResultSelectorProps<T>) {
  const [selectedResult, setSelectedResult] = useState<PcrResultType>();
  const [comment, setComment] = useState<string>();

  function submit() {
    if (showOptions) {
      onSubmitted(tubes, selectedResult!, comment!);
    } else {
      onSubmitted(tubes, PcrResultType.WorkupComplete, comment!);
    }
  }

  const canBeSubmitted = useMemo(() => {
    return selectedResult && (selectedResult !== PcrResultType.Tnp || comment);
  }, [selectedResult, comment]);

  const state = useMemo(() => {
    return tubes?.[0]?.state ?? undefined;
  }, [tubes]);

  return (
    <Card className="surface-100">
      <h2>{header}</h2>

      <div className="mt-2">
        {showOptions && (
          <div>
            <div className="field-radiobutton">
              <RadioButton
                value={PcrResultType.Neg}
                name="state"
                onChange={(e) => setSelectedResult(e.value)}
                checked={selectedResult === PcrResultType.Neg}
                inputId="Neg"
              />
              <label htmlFor="stateNeg" className="cursor-pointer">
                Negative
              </label>
            </div>
            <div className="field-radiobutton">
              <RadioButton
                value={PcrResultType.Tnp}
                name="state"
                onChange={(e) => setSelectedResult(e.value)}
                checked={selectedResult === PcrResultType.Tnp}
                inputId="TNP"
              />
              <label htmlFor="stateNeg" className="cursor-pointer">
                TNP
              </label>
            </div>
            <div className="field-radiobutton">
              <RadioButton
                value={PcrResultType.Pos}
                name="state"
                onChange={(e) => setSelectedResult(e.value)}
                checked={selectedResult === PcrResultType.Pos}
                inputId="Pos"
              />
              <label htmlFor="stateNeedsHalo" className="cursor-pointer">
                Positive
              </label>
            </div>
          </div>
        )}
        {!showOptions && (
          <div className="mt-4 mb-4">
            {state === TubeState.PendingPcrHaloResults
              ? "Workup complete!"
              : "Negative/Positive/TNP"}
          </div>
        )}

        {selectedResult === PcrResultType.Tnp && (
          <div className="field col-12">
            <InputTextarea
              rows={3}
              cols={30}
              name="commentName"
              placeholder="Comment"
              autoFocus
              value={comment}
              onChange={(v) => setComment(v.target.value)}
            />
          </div>
        )}

        <Button
          className="p-button-outlined"
          label="Submit"
          onClick={submit}
          disabled={!canBeSubmitted && showOptions}
        />
      </div>

      <h3 className="mb-1">Selected IDs ({tubes?.length}):</h3>
      <div className="">
        {tubes?.map((t) => (
          <div>{t.barcode}</div>
        ))}
      </div>
    </Card>
  );
}
