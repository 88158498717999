import { useState } from "react";
import { useQueryClient } from "react-query";
import { SuperbatchModel } from "../../queries/pcr/models/super-batch";
import { useAssignSuperbatchItemPositionMutation } from "../../queries/pcr/superBatches.query";
import { ScanBarcodeDialog } from "../ui/ScanBarcodeDialog";
import { useToast } from "../ui/ToastContext";
import { PlateCoordinates } from "../../models/pcr/plate-coordinates";
import { PlateWell } from "./PlateWell";
import { TubeType } from "../../queries/pcr/models/tube-type";

interface SuperBatchPlateForAssigningProps {
  superbatch: SuperbatchModel;
  disabledWells: PlateCoordinates[];
}

interface InsertTubeData {
  row: string;
  column: string;
  tubeCode: string;
}

export function SuperBatchPlateForAssigning({
  superbatch,
  disabledWells,
}: SuperBatchPlateForAssigningProps) {
  const [showScanTubeDialog, setShowScanTubeDialog] = useState(false);
  const [position, setPosition] = useState<PlateCoordinates>();

  const assignMutation = useAssignSuperbatchItemPositionMutation();

  const rows = ["A", "B", "C", "D", "E", "F", "G", "H"];
  const columns = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
  ];

  const toast = useToast();
  const queryClient = useQueryClient();

  function scanTube(pos: PlateCoordinates) {
    setPosition(pos);
    setShowScanTubeDialog(true);
  }

  function getNextWell(pos: PlateCoordinates | undefined) {
    pos = getNextWellImpl(pos);
    while(disabledWells.some(x => x.row === pos?.row && x.column === pos?.column)){
      pos = getNextWellImpl(pos);
    }

    return pos;
  }

  function getNextWellImpl(pos: PlateCoordinates | undefined) {
    if (!pos) return;

    if (rows.indexOf(pos.row) !== rows.length - 1) {
      // we haven't yet reached the bottom of the plate
      return {
        row: rows[rows.indexOf(pos.row) + 1],
        column: pos.column,
      } as PlateCoordinates;
    } else if (columns.indexOf(pos.column) !== columns.length - 1) {
      // we haven't yet reached the far end of the plate
      return {
        row: rows[0],
        column: columns[columns.indexOf(pos.column) + 1],
      } as PlateCoordinates;
    } else {
      // we are at bottom right
      return undefined;
    }
  }

  async function insertTubeIntoPlate(data: InsertTubeData) {
    const currentTubeInThisHole = superbatch.items.find(
      (t) => t.column === data.column && t.row === data.row
    );

    let wasUnassigning = false;

    if (currentTubeInThisHole) {
      currentTubeInThisHole.column = undefined;
      currentTubeInThisHole.row = undefined;

      if (currentTubeInThisHole.tube.barcode === data.tubeCode) {
        wasUnassigning = true;
      }

      await assignMutation.mutateAsync({
        row: "",
        column: "",
        superbatchId: superbatch.id,
        superbatchItemId: currentTubeInThisHole.id,
      });
      queryClient.invalidateQueries("superBatches");
      queryClient.invalidateQueries("pcrtotals");
      wasUnassigning = true;
    }

    if (!wasUnassigning) {
      let itemToAssign = superbatch.items
        // first check unassigned (several control tubes can have same barcode so we need to skip already assigned ones)
        .filter((i) => !i.row && !i.column)
        .find((t) => t.tube.barcode === data.tubeCode);

      if (!itemToAssign) {
        // then find in already assigned items
        itemToAssign = superbatch.items.find(
          (t) => t.tube.barcode === data.tubeCode
        );
      }

      if (itemToAssign) {
        itemToAssign.row = data.row;
        itemToAssign.column = data.column;

        await assignMutation.mutateAsync({
          row: data.row,
          column: data.column,
          superbatchId: superbatch.id,
          superbatchItemId: itemToAssign.id,
        });
      } else {
        toast.current?.show({
          detail: "Tube with wrong code scanned.",
          severity: "error",
        });
      }

      queryClient.invalidateQueries("superBatches");
      queryClient.invalidateQueries("pcrtotals");

      if (superbatch.items.some((i) => !i.row && !i.column)) {
        // auto trigger next well
        let nextWell = getNextWell(position);

        while (
          nextWell !== undefined &&
          superbatch.items.find(
            (t) => t.column === nextWell!.column && t.row === nextWell!.row
          )
        ) {
          nextWell = getNextWell(nextWell);
        }

        if (nextWell) {
          scanTube(nextWell);
        }
      }
    }
  }

  return (
    <div className="border-1 border-round">
      <div className="flex flex-column">
        <div className="flex flex-row justify-content-evenly">
          <span className="w-1rem"></span>
          {columns.map((c) => (
            <span key={c} className="text-center w-4rem">
              {c}
            </span>
          ))}
        </div>
        {rows.map((r) => (
          <div key={r} className="flex flex-row justify-content-evenly mb-1">
            <span className="justify-content-center align-items-center flex w-1rem line">
              {r}
            </span>
            {columns.map((c) => {
              const superbatchItem = superbatch.items.find(
                (t) => t.column === c && t.row === r
              );
              return (
                <PlateWell
                  key={r + "x" + c}
                  text={superbatchItem?.tube.barcode}
                  disabled={disabledWells.some(dw => dw.row === r && dw.column === c)}
                  highlighted={!!superbatchItem}
                  highlightColorClassName={
                    superbatchItem?.tube.type === TubeType.Regular
                      ? "bg-green-200"
                      : "bg-blue-200"
                  }
                  onClick={() => scanTube({ row: r, column: c })}
                />
              );
            })}
          </div>
        ))}
      </div>
      <ScanBarcodeDialog
        headerText="Scan barcode"
        bodyText={`Scan the barcode of a tube that will be pippetted at ${position?.row}${position?.column}`}
        visible={showScanTubeDialog}
        onHide={() => setShowScanTubeDialog(false)}
        onScan={(code) => {
          insertTubeIntoPlate({
            row: position!.row,
            column: position!.column,
            tubeCode: code,
          });
          setShowScanTubeDialog(false);
        }}
      />
    </div>
  );
}
