import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { useState } from "react";

export interface RemoveTubeButtonProps {
  tube: { id: number; barcode: string };
  iconOnly?: boolean;
  onConfirmRemove: (id: number, comment: string) => void;
}

export function RemoveTubeButton({
  tube,
  iconOnly = false,
  onConfirmRemove,
}: RemoveTubeButtonProps) {
  const [visible, setVisible] = useState(false);
  const [comment, setComment] = useState("");

  function showConfirmDialog() {
    setComment("");
    setVisible(true);
  }

  return (
    <>
      {!iconOnly && (
        <Button
          icon="pi pi-trash"
          className="p-button-outlined p-button-danger"
          onClick={showConfirmDialog}
        />
      )}
      {iconOnly && (
        <Button
          className="p-button-rounded p-button-text p-button-danger"
          icon="pi pi-trash"
          onClick={showConfirmDialog}
        />
      )}
      <Dialog
        visible={visible}
        header={`Removing tube ${tube.barcode}`}
        onHide={() => setVisible(false)}
      >
        <div className="flex flex-column align-items-center w-25rem">
          <span className="mb-2">Enter reason of removing the tube:</span>
          <InputTextarea
            value={comment}
            maxLength={500}
            onChange={(e) => setComment(e.target.value)}
            autoFocus
            className="mb-2 w-20rem"
          />
          <Button
            className="p-button p-button-danger"
            onClick={() => {
              onConfirmRemove(tube.id, comment);
              setVisible(false);
            }}
            type="submit"
            disabled={!comment}
          >
            Confirm removing
          </Button>
        </div>
      </Dialog>
    </>
  );
}
