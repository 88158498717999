import { format } from "date-fns";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { OverlayPanel } from "primereact/overlaypanel";
import { useRef, useState } from "react";
import { ExtendedMetaDataInput } from "../../components/pcr/ExtendedMetaDataInput";
import { MetaDataReportItem } from "../../queries/admin/models/metadata-report-item";
import { useMetaDataReportQuery } from "../../queries/admin/reports.query";
import { DATE_FORMAT } from "../../utils/constants";
import { ExtendedMetaData, MetaData } from "../../queries/pcr/models/meta-data";

export function TabMetaData() {
  const [metadata, setMetadata] = useState<string>("");
  const [barcode, setBarcode] = useState<string>("");
  const [metaDataForQuery, setMetaDataForQuery] = useState<string>("");
  const [barcodeForQuery, setBarcodeForQuery] = useState<string>("");
  const [selectedItem, setSelectedItem] = useState<MetaDataReportItem>();

  const { isLoading, data } = useMetaDataReportQuery(
    metaDataForQuery!,
    barcodeForQuery!
  );
  const metadataOp = useRef<any>(null);

  const metadataCell = (rowData: MetaDataReportItem) => {
    return (
      <span
        className="cursor-pointer transition-colors transition-duration-200 hover:bg-yellow-500 hover:text-gray-900 m-2 px-5 py-3"
        onClick={(e) => {
          setSelectedItem(rowData);
          metadataOp.current!.toggle(e);
        }}
      >
        Click to show
      </span>
    );
  };

  return (
    <div>
      <div className="flex flex-row">
        <div className="mr-3">
          <label className="mr-2">Find in metadata:</label>
          <InputText
            value={metadata}
            onChange={(e) => setMetadata(e.target.value)}
            onBlur={(e) => setMetaDataForQuery(e.target.value)}
          />
        </div>
        <div>
          <label className="mr-2">Plate barcode:</label>
          <InputText
            value={barcode}
            onChange={(e) => setBarcode(e.target.value)}
            onBlur={(e) => setBarcodeForQuery(e.target.value)}
          />
        </div>
      </div>
      <hr />

      {!isLoading && data && (
        <DataTable value={data}>
          <Column
            header="Date"
            dataType="date"
            body={(d) => <span>{format(new Date(d.date), DATE_FORMAT)}</span>}
          />
          <Column field="tubeBarcode" header="Tube Barcode" />
          <Column
            field="minibatchPlateBarcode"
            header="Minibatch Plate Barcode"
          />
          <Column
            field="superbatchPlateBarcode"
            header="SuperBatch Plate Barcode"
          />
          <Column header="Metadata" body={metadataCell} />
        </DataTable>
      )}
      <OverlayPanel ref={metadataOp}>
        {selectedItem && selectedItem.metaData && (
          <div className="flex flex-row">
            <div className="flex-1">
              <ExtendedMetaDataInput
                metadata={selectedItem!.metaData as ExtendedMetaData}
                disabled
              />
            </div>
          </div>
        )}
      </OverlayPanel>
    </div>
  );
}
