import { UserType } from "../models/admin/user-type";
import { LoggedUserInfo } from "../models/_shared/logged-user-info";
import { LoginRequest } from "../queries/_shared/models/login-request";
import { LoginResult } from "../queries/_shared/models/login-result";
import { API_ENDPOINTS } from "../utils/endpoints";
import { BaseService } from "./base.service";

class AuthService extends BaseService {

  readonly AUTH_USER_INFO = 'auth.userInfo';


  async login(model: LoginRequest): Promise<LoginResult> {
    return this.http.post(`${this.endpointPath}/login`, model).then((res) => res.data);;
  }

  getLoggedUser(): LoggedUserInfo{
    const valueString = localStorage.getItem(this.AUTH_USER_INFO);
    if (valueString) {
      return JSON.parse(valueString);
    }

    return null as any;
  }

  setLoggedUser(info: LoggedUserInfo){
    localStorage.setItem(this.AUTH_USER_INFO, JSON.stringify(info));
  }

  logout(){
    localStorage.removeItem(this.AUTH_USER_INFO);
  }

  hasRole(role: UserType){
    return (this.getLoggedUser()?.type & role) === role;
  }
}

export const authService = new AuthService(API_ENDPOINTS.AUTHENTICATION);
