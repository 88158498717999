/* eslint-disable react-hooks/rules-of-hooks */
import { useQuery } from "react-query";
import { reportsService } from "../../services/admin/reports.service";
import { MetaDataReportItem } from "./models/metadata-report-item";
import { TubeHistory } from "./models/tube-history-report";

async function fetchTubeHistoryReport(code: string) {
  const result = await reportsService.getTubeHistory(code);

  return result;
}

async function fetchMetaDataReport(query: string, barcode: string) {
  const result = await reportsService.getMetadataReport(query, barcode);
  return result;
}

export function useTubeHistoryReportQuery(code: string) {
  return useQuery<TubeHistory | undefined>(["reports", code], () =>
    fetchTubeHistoryReport(code)
  );
}

export function useMetaDataReportQuery(metadata: string, barcode: string) {
  return useQuery<MetaDataReportItem[] | undefined>(
    ["reports", "metadata-report", metadata, barcode],
    () => fetchMetaDataReport(metadata, barcode)
  );
}
