/* eslint-disable react-hooks/rules-of-hooks */
import { useMutation, useQuery } from "react-query";
import { ConfigurationModel } from "../../models/admin/configuration";
import { configurationService } from "../../services/admin/configuration.service";
import { PcrTotalsModel } from "./models/pcr-totals";
import { MicroTotalsModel } from "./models/micro-totals";

async function fetchConfiguration() {
  const configuration = await configurationService.getConfiguration();

  return {
    superbatchSize: configuration.superbatchSize,
    minibatchUtiSize: configuration.minibatchUtiSize,
    minibatchAbrSize: configuration.minibatchAbrSize,
    haloSuffix: configuration.haloSuffix,
    disabledAbrMinibatchWells: configuration.disabledAbrMinibatchWells,
    disabledSuperbatchWells: configuration.disabledSuperbatchWells,
    disabledUtiMinibatchWells: configuration.disabledUtiMinibatchWells,
    showSupplementalElutionBufferInMetaData: configuration.showSupplementalElutionBufferInMetaData,
    showSupplementalProteinaseInMetaData: configuration.showSupplementalProteinaseInMetaData,
  } as ConfigurationModel;
}

export function useConfigurationQuery() {
  return useQuery<ConfigurationModel>("configuration", fetchConfiguration);
}

export function useConfigurationMutation() {
  return useMutation((model: ConfigurationModel) =>
    configurationService.saveConfiguration({
      superbatchSize: model.superbatchSize,
      minibatchUtiSize: model.minibatchUtiSize,
      minibatchAbrSize: model.minibatchAbrSize,
      haloSuffix: model.haloSuffix,
      showSupplementalElutionBufferInMetaData: model.showSupplementalElutionBufferInMetaData,
      showSupplementalProteinaseInMetaData: model.showSupplementalProteinaseInMetaData,
      disabledAbrMinibatchWells: model.disabledAbrMinibatchWells,
      disabledSuperbatchWells: model.disabledSuperbatchWells,
      disabledUtiMinibatchWells: model.disabledUtiMinibatchWells
    })
  );
}

export function useDisabledWellsMutation() {
  return useMutation((model: ConfigurationModel) =>
    configurationService.saveConfiguration({
      superbatchSize: model.superbatchSize,
      minibatchUtiSize: model.minibatchUtiSize,
      minibatchAbrSize: model.minibatchAbrSize,
      haloSuffix: model.haloSuffix,
      showSupplementalElutionBufferInMetaData: model.showSupplementalElutionBufferInMetaData,
      showSupplementalProteinaseInMetaData: model.showSupplementalProteinaseInMetaData,
      disabledAbrMinibatchWells: model.disabledAbrMinibatchWells,
      disabledSuperbatchWells: model.disabledSuperbatchWells,
      disabledUtiMinibatchWells: model.disabledUtiMinibatchWells
    })
  );
}

async function fetchPcrTotals() {
  const res = await configurationService.getPcrTotals();

  return {
    batchingQueue: res.batchingQueue,
    superBatch: res.superBatch,
    utiSetup: res.utiSetup,
    utiRunQueue: res.utiRunQueue,
    abrBatching: res.abrBatching,
    abrSetup: res.abrSetup,
    abrRunQueue: res.abrRunQueue,
    batchResults: res.batchResults,
    pendingResults: res.pendingResults,
  } as PcrTotalsModel;
}

async function fetchMicroTotals() {
  const res = await configurationService.getMicroTotals();

  return {
    preculture: res.preculture,
    plateCulture: res.plateCulture,
    pendingResults: res.pendingResults,
    samplePrep: res.samplePrep,
    negPcrResults: res.negPcrResults,
  } as MicroTotalsModel;
}

export function usePcrTotalsQuery() {
  return useQuery<PcrTotalsModel | undefined>(["pcrtotals"], () =>
    fetchPcrTotals()
  );
}

export function useMicroTotalsQuery() {
  return useQuery<MicroTotalsModel | undefined>(["micrototals"], () =>
    fetchMicroTotals()
  );
}
