import { InputText } from "primereact/inputtext";
import { useEffect, useState } from "react";
import { MiniBatchPlate } from "../../components/pcr/MiniBatchPlate";
import { SuperBatchPlate } from "../../components/pcr/SuperBatchPlate";
import { PlateCoordinates } from "../../models/pcr/plate-coordinates";
import { useSuperBatchByCodeQuery } from "../../queries/pcr/superBatches.query";
import { isMinibatchAbr, isMinibatchUti } from "../../utils/minibatch-utils";
import { TubeModel } from "../../queries/pcr/models/tube";
import { TubeResult } from "../../queries/pcr/models/tube-result";
import { PcrSubresult } from "../../queries/pcr/models/pcr-subresult";
import { TubeType } from "../../queries/pcr/models/tube-type";

export function TabPlateLayouts() {
  const [superbatchId, setSuperbatchId] = useState<string>("");
  const [codeForQuery, setCodeForQuery] = useState<string>();
  const [selectedTubeId, setSelectedTubeId] = useState<number>();
  const [selectedWell, setSelectedWell] = useState<PlateCoordinates>();

  const { data, isLoading } = useSuperBatchByCodeQuery(codeForQuery);

  function selectTube(c: PlateCoordinates) {
    if (data) {
      if (selectedWell?.column === c.column && selectedWell.row === c.row) {
        setSelectedWell(undefined);
      } else {
        setSelectedWell(c);
      }
    }
  }

  useEffect(() => {
    if (data) {
      if (!selectedWell) {
        setSelectedTubeId(undefined);
      } else {
        const tubeId = data.items.find(
          (i) => i.column === selectedWell.column && i.row === selectedWell.row
        )?.tubeId;

        setSelectedTubeId(tubeId);
      }
    }
  }, [data, selectedWell]);

  function getHighlightColor(row: string, column: string, tube?: TubeModel) {
    if (selectedWell?.row === row && selectedWell?.column === column) {
      return "bg-green-200";
    } else if (tube && tube.type === TubeType.Regular) {
      if (
        tube.pcrResult === TubeResult.Positive &&
        tube.pcrSubresult === PcrSubresult.Positive_Preculture4HourQueue
      ) {
        return "bg-green-400";
      } else if (
        tube.pcrResult === TubeResult.Positive &&
        tube.pcrSubresult === PcrSubresult.Positive_Preculture18HourQueue
      ) {
        return "bg-blue-200";
      } else if (tube.pcrResult === TubeResult.Negative) {
        return "bg-red-200";
      }
    }

    return null;
  }

  return (
    <div>
      <div>
        <label className="mr-2">SuperBatch ID:</label>
        <InputText
          value={superbatchId}
          onChange={(e) => setSuperbatchId(e.target.value)}
          onBlur={(e) => setCodeForQuery(e.target.value)}
        />
      </div>
      <hr />

      {!isLoading && data && (
        <div className="grid">
          <div className="col-6">
            <h3>SuperBatch plate</h3>
            <label className="font-bold mb-1">{data.plateBarcode}</label>
            <SuperBatchPlate
              superbatchItems={data.items}
              showAuditLegend={true}
              enabledIds={data.items.map((i) => i.tubeId)}
              onHoleClick={(c) => selectTube(c)}
              highlightColorFunction={getHighlightColor}
            />
          </div>
          <div className="col-6">
            <h3>UTI Miniplates</h3>
            {data.miniBatches
              ?.filter((mb) => isMinibatchUti(mb))
              .map((mb) => (
                <div className="mb-2">
                  <label className="font-bold mb-1">{mb.plateBarcode}</label>
                  <MiniBatchPlate miniBatch={mb} selectedIds={selectedTubeId} />
                </div>
              ))}
            <h3>ABR Miniplates</h3>
            {data.miniBatches
              ?.filter((mb) => isMinibatchAbr(mb))
              .map((mb) => (
                <div className="mb-2">
                  <label className="font-bold mb-1">{mb.plateBarcode}</label>
                  <MiniBatchPlate miniBatch={mb} selectedIds={selectedTubeId} />
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
}
