import axios from "axios";
import { handleDates } from "../utils/serialize-date-helpers";
import { authService } from "../services/auth.service";

const request = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 30000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

request.interceptors.request.use(
  (config) => {
    const loggedUser = authService.getLoggedUser();
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${loggedUser ? loggedUser.accessToken : ""}`,
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

request.interceptors.response.use((response) => {
  handleDates(response.data);
  return response;
});

request.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      window.location.href = "/";
    }
  }
);

request.interceptors.response.use((response) => {
  const newToken = response.headers["x-refresh-token"];

  if (newToken) {
    const user = authService.getLoggedUser();

    user.accessToken = newToken;

    authService.setLoggedUser(user);
  }

  return response;
});

export default request;
