import { debug } from "console";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import { useState } from "react";
import { CreateUserModel } from "../queries/admin/models/create-user";
import { UserType } from "../queries/admin/models/user-type";
import { PcrResultType } from "../queries/pcr/models/pcr-result-type";

export interface AddUserProps {
  onAdd: (user: CreateUserModel) => void;
}

export function AddUser({ onAdd }: AddUserProps) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [type, setType] = useState<UserType>();

  function handleAdd() {
    return onAdd({ email: email, password: password, type: type!});
  }

  return (
    <div>
      <div className="grid p-fluid">
        <div className="field col-12">
          <label>Email</label>
          <InputText
            name="p1"
            type="email"
            autoFocus
            value={email}
            onChange={(v) => setEmail(v.target.value)}
          />
        </div>
        <div className="field col-12">
          <label>Password</label>
          <InputText
            name="p2"
            type={"password"}
            value={password}
            onChange={(v) => setPassword(v.target.value)}
          />
        </div>
      </div>
      <div>
        <div className="field-radiobutton">
          <RadioButton
            value={UserType.Admin}
            name="stateAdmin"
            onChange={(e) => setType(e.value)}
            checked={type === UserType.Admin}
            inputId="Admin"
          />
          <label htmlFor="stateAdmin" className="cursor-pointer">
            Admin
          </label>
        </div>
        <div className="field-radiobutton">
          <RadioButton
            value={UserType.Micro}
            name="stateMicro"
            onChange={(e) => setType(e.value)}
            checked={type === UserType.Micro}
            inputId="Micro"
          />
          <label htmlFor="stateMicro" className="cursor-pointer">
            Micro
          </label>
        </div>
        <div className="field-radiobutton">
          <RadioButton
            value={UserType.Pcr}
            name="statePcr"
            onChange={(e) => setType(UserType.Pcr)}
            checked={type === UserType.Pcr}
            inputId="Pcr"
          />
          <label htmlFor="statePcr" className="cursor-pointer">
          Pcr
          </label>
        </div>
        <div className="field-radiobutton">
          <RadioButton
            value={UserType.MicroAndPcr}
            name="stateMicroAndPcr"
            onChange={(e) => setType(UserType.MicroAndPcr)}
            checked={type === UserType.MicroAndPcr}
            inputId="MicroAndPcr"
          />
          <label htmlFor="stateMicroAndPcr" className="cursor-pointer">
          Micro & Pcr
          </label>
        </div>
      </div>
      <div>
        <Button
          label="Add"
          disabled={!email || !password || !type}
          onClick={handleAdd}
        />
      </div>
    </div>
  );
}
