import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { useLoginMutation } from "../queries/_shared/auth.query";
import { useState } from "react";
import { authService } from "../services/auth.service";
import { UserType } from "../queries/admin/models/user-type";
import { useToast } from "../components/ui/ToastContext";
import { Dialog } from "primereact/dialog";
import { ChangePassword } from "../components/ChangePassword";
import { UserModel } from "../queries/admin/models/user";
import { useChangeSelfPasswordMutation } from "../queries/admin/users.query";
import { tr } from "date-fns/locale";

export function Login() {
  const [username, setUsername] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [responseUser, setResponseUser] = useState<UserModel>();
  const [changePasswordVisible, setChangePasswordVisible] = useState(false);

  const loginMutation = useLoginMutation();
  const changeSelfPasswordMutation = useChangeSelfPasswordMutation();

  const navigate = useNavigate();
  const toast = useToast();
  function executeLogin() {
    loginMutation.mutate(
      {
        username: username!,
        password: password!,
      },
      {
        onSuccess: (response) => {
          authService.setLoggedUser({
            accessToken: response.token,
            id: response.user.id,
            type: response.user.roles,
            username: response.user.username,
          });

          if(response.user.isResetPasswordPending){
            setResponseUser(response.user);
            setChangePasswordVisible(true);
            return;
          }

          if ((response.user.roles & UserType.Admin) === UserType.Admin) {
            navigate("admin");
          } else if ((response.user.roles & UserType.MicroAndPcr) === UserType.MicroAndPcr) {
            navigate("my-account");
          } else if ((response.user.roles & UserType.Pcr) === UserType.Pcr) {
            navigate("pcr");
          }else if (
            (response.user.roles & UserType.Micro) ===
            UserType.Micro
          ) {
            navigate("micro");
          }
        },
        onError: (error) => {
          toast.current!.show({
            detail: "Wrong user or password",
            severity: "error",
          });
          console.error(error);
        },
      }
    );
  }

  return (
    <>
      <div className="flex justify-content-center align-items-center h-screen">
        <Card className="w-20rem">
          <h2>MDx Health</h2>
          <span className="p-float-label mb-2">
            <InputText
              id="username"
              value={username ?? ""}
              onChange={(e) => setUsername(e.target.value)}
            />
            <label htmlFor="username">Username:</label>
          </span>
          <span className="p-float-label mb-2">
            <InputText
              id="password"
              type={"password"}
              value={password ?? ""}
              onChange={(e) => setPassword(e.target.value)}
            />
            <label htmlFor="password">Password:</label>
          </span>
          <Button label="Login" onClick={() => executeLogin()} />
        </Card>
          <Dialog
            visible={changePasswordVisible}
            header="Set new password"
            onHide={() => setChangePasswordVisible(false)}
          >
            <ChangePassword
            user={responseUser!}
              onChange={(p, u) => {
                changeSelfPasswordMutation.mutate({password: p, userId: u.id})
                setPassword('');
                setChangePasswordVisible(false);
              }}
            />
          </Dialog>
      </div>
    </>
  );
}
