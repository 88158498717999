import { UserModel } from "../../models/admin/user";
import { ChangePasswordModel } from "../../queries/admin/models/change-password";
import { CreateUserModel } from "../../queries/admin/models/create-user";
import { API_ENDPOINTS } from "../../utils/endpoints";
import { BaseService } from "../base.service";

class UsersService extends BaseService {
  async getUsers(): Promise<UserModel[]> {
    let url = `${this.endpointPath}/users`;

    return this.http.get<UserModel[]>(url).then((res) => res.data);
  }

  async create(model: CreateUserModel): Promise<UserModel> {
    return this.http.post(`${this.endpointPath}/users`, {
      email: model.email,
      password: model.password,
      roles: model.type,
    });
  }

  async delete(id: number): Promise<void> {
    return this.http.delete(`${this.endpointPath}/users/${id}`);
  }

  async unarchive(id: number): Promise<void> {
    return this.http.post(`${this.endpointPath}/users/${id}/unarchive`);
  }

  async changePassword(model: ChangePasswordModel): Promise<void> {
    return this.http.put(`${this.endpointPath}/users/change-password`, {
      id: model.userId,
      password: model.password,
    });
  }

  async changeSelfPassword(model: ChangePasswordModel): Promise<void> {
    return this.http.put(`${this.endpointPath}/user/change-self-password`, {
      id: model.userId,
      password: model.password,
    });
  }
}

export const usersService = new UsersService(API_ENDPOINTS.ADMIN);
