/* eslint-disable react-hooks/rules-of-hooks */
import { useMutation, useQuery } from "react-query";
import { UserModel } from "../../models/admin/user";
import { usersService } from "../../services/admin/users.service";
import { ChangePasswordModel } from "./models/change-password";
import { CreateUserModel } from "./models/create-user";

async function fetchUsers() {
  return await usersService.getUsers();
}

export function useUsersQuery() {
  return useQuery<UserModel[]>("users", fetchUsers);
}

export function useCreateUserMutation() {
  return useMutation((model: CreateUserModel) => usersService.create(model));
}

export function useArchiveUserMutation() {
  return useMutation((userId: number) => usersService.delete(userId));
}

export function useUnarchiveUserMutation() {
  return useMutation((userId: number) => usersService.unarchive(userId));
}

export function useChangePasswordMutation() {
  return useMutation((model: ChangePasswordModel) =>
    usersService.changePassword(model)
  );
}
export function useChangeSelfPasswordMutation() {
  return useMutation((model: ChangePasswordModel) =>
    usersService.changeSelfPassword(model)
  );
}
