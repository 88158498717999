import { AuditModel } from "../../models/admin/audit";
import { DequeuedAuditModel } from "../../models/admin/dequeued-audit-model";
import { RerunAuditModel } from "../../models/admin/rerun-audit-model";
import { API_ENDPOINTS } from "../../utils/endpoints";
import { BaseService } from "../base.service";
import { getTimezoneName } from "../../utils/helpers";
import { ServerLog } from "../../models/admin/server-log";

class AuditsService extends BaseService {
  async getAudits(dateFrom: Date, dateTo: Date): Promise<AuditModel[]> {
    let url = `${
      this.endpointPath
    }?dateFrom=${dateFrom.toISOString()}&dateTo=${dateTo.toISOString()}`;

    return this.http.get<AuditModel[]>(url).then((res) => res.data);
  }

  async getRerunTubes(
    dateFrom: Date,
    dateTo: Date
  ): Promise<RerunAuditModel[]> {
    let url = `${
      this.endpointPath
    }/reruns?dateFrom=${dateFrom.toISOString()}&dateTo=${dateTo.toISOString()}`;

    return this.http.get<RerunAuditModel[]>(url).then((res) => res.data);
  }

  async getDequeuedTubes(
    dateFrom: Date,
    dateTo: Date
  ): Promise<DequeuedAuditModel[]> {
    let url = `${
      this.endpointPath
    }/dequeued?dateFrom=${dateFrom.toISOString()}&dateTo=${dateTo.toISOString()}`;

    return this.http.get<DequeuedAuditModel[]>(url).then((res) => res.data);
  }

  async getUtiParsingLogs(
    tubeCode: string,
  ): Promise<ServerLog[]> {
    let url = `${
      this.endpointPath
    }/uti-parsing-logs?tubeCode=${tubeCode}`;

    return this.http.get<ServerLog[]>(url).then((res) => res.data);
  }

  async getAuditExport(dateFrom: Date, dateTo: Date) {
    const timezone = getTimezoneName();

    return this.http
      .get(
        `${
          this.endpointPath
        }/report?dateFrom=${dateFrom.toISOString()}&dateTo=${dateTo.toISOString()}&tz=${timezone}`,
        {
          responseType: "blob",
        }
      )
      .then((res) => new Blob([res.data]));
  }
}

export const auditsService = new AuditsService(API_ENDPOINTS.AUDITS);
