import { differenceInMilliseconds } from "date-fns";
import { MicroTubeModel } from "../models/micro/micro-tube";
import { ExtendedMetaData } from "../queries/pcr/models/meta-data";
import { TubeModel } from "../queries/micro/models/tube";

export function groupBy(collection: any[], property: string): any[] {
  var i = 0, val, index,
    values = [], result = [];
  for (; i < collection.length; i++) {
    val = collection[i][property];
    index = values.indexOf(val);
    if (index > -1)
      result[index].push(collection[i]);
    else {
      values.push(val);
      result.push([collection[i]]);
    }
  }
  result.sort();
  return result;
}

export function nextChar(c: string) {
  return String.fromCharCode(c.charCodeAt(0) + 1);
}

export function padArray(array: any[], object: any, size: number) {
  if (!array) {
    array = [];
  }
  if (size <= array.length) return array;

  return [...array, ...Array(size - array.length).fill(object)];
}

export function transformEnumToText(value: any): any {
  if (value) {
    const result = value.replace(/([A-Z])/g, " $1").trim();
    return result.charAt(0) + result.substring(1).toLowerCase();
  }
  return "";
}

export function getTimezoneName(): string {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export function isMetaDataValid(metadata: ExtendedMetaData): boolean {
  return (
    (!metadata?.equipments ||
      metadata.equipments.every(
        (e) => (e.required && Boolean(e.value)) || !e.required
      )) &&
    (!metadata?.reagentLots ||
      metadata.reagentLots.every(
        (e) => (e.required && Boolean(e.value)) || !e.required
      )) &&
    (!metadata?.instruments ||
      metadata.instruments.every(
        (e) => (e.required && Boolean(e.value)) || !e.required
      ))
  );
}

export function isMetaDataEmpty(metadata: ExtendedMetaData): boolean {
  return (
    (!metadata?.equipments ||
      metadata.equipments.every((e) => !Boolean(e.value))) &&
    (!metadata?.reagentLots ||
      metadata.reagentLots.every((e) => !Boolean(e.value))) &&
    (!metadata?.instruments ||
      metadata.instruments.every((e) => !Boolean(e.value)))
  );
}

export function getMilisecondsTimeInIncubator(tube: MicroTubeModel): number {
  let totalMiliseconds = 0;
  if (tube.timeIn) {
    totalMiliseconds = differenceInMilliseconds(
      tube.timeIn,
      tube.timeOut ?? new Date()
    );
    if (tube.secondTimeIn) {
      totalMiliseconds += differenceInMilliseconds(
        tube.secondTimeIn,
        tube.secondTimeOut ?? new Date()
      );
    }
  }
  return totalMiliseconds;
}
export function getMilisecondsTimeInIncubatorAlternative(tube: TubeModel): number {
  let totalMiliseconds = 0;
  if (tube.currentStepTimeIn) {
    totalMiliseconds = differenceInMilliseconds(
      tube.currentStepTimeIn,
      tube.currentStepTimeOut ?? new Date()
    );
    if (tube.currentStepSecondTimeIn) {
      totalMiliseconds += differenceInMilliseconds(
        tube.currentStepSecondTimeIn,
        tube.currentStepSecondTimeOut ?? new Date()
      );
    }
  }
  return totalMiliseconds;
}

export class AuditTrailCol {
  field: string | undefined;
  header: string | undefined;
}
