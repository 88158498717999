import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useState } from "react";
import { MinibatchModel } from "../../queries/pcr/models/mini-batch";
import { ScanBarcodeDialog } from "../ui/ScanBarcodeDialog";

interface MinibatchSelectorProps<T> {
  batches: T[];
  header: string;
  showPlateIdColumn?: boolean;
  showStatusColumn?: boolean;
  statusSelector?: (v: T) => string;
  onSelect: (v: T) => void;
}

export function MinibatchSelector<T extends MinibatchModel>({
  batches,
  header,
  showPlateIdColumn = false,
  showStatusColumn = false,
  statusSelector,
  onSelect,
}: MinibatchSelectorProps<T>) {
  const [scanPlateIdVisible, setScanPlateIdVisible] = useState(false);
  const [selectedMinibatch, setSelectedMinibatch] = useState<T>();

  function selectMinibatch(minibatch: T) {
    setSelectedMinibatch(minibatch);
    setScanPlateIdVisible(true);
  }

  function confirmPlateId(scanned: string) {
    setScanPlateIdVisible(false);
    if (scanned === selectedMinibatch!.plateBarcode) {
      onSelect(selectedMinibatch!);
    }
  }

  return (
    <div className="flex justify-content-center">
      <DataTable
        className="w-6"
        rowClassName={() => "cursor-pointer"}
        value={batches}
        onRowClick={(d) => selectMinibatch(d.data)}
      >
        <Column header={header} field="plateBarcode" />
        {showPlateIdColumn && <Column header="Plate ID" field="plateBarcode" />}
        {showStatusColumn && statusSelector && (
          <Column header="Status" body={(rowData) => statusSelector(rowData)} />
        )}
      </DataTable>
      <ScanBarcodeDialog
        bodyText={"SCAN " + selectedMinibatch?.plateBarcode + " PLATE ID:"}
        headerText="Scan Plate ID"
        visible={scanPlateIdVisible}
        onHide={() => setScanPlateIdVisible(false)}
        onScan={confirmPlateId}
        requiredBarcode={selectedMinibatch?.plateBarcode}
      />
    </div>
  );
}
