/* eslint-disable react-hooks/rules-of-hooks */
import { useMutation, useQuery } from "react-query";
import { pcrService } from "../../services/pcr/pcr.service";
import { AssignMinibatchControlItemBarcode } from "./models/assign-minibatch-control-item-barcode";
import { AssignMinibatchMetadata } from "./models/assign-minibatch-metadata-request";
import { AssignMinibatchResultRequest } from "./models/assign-minibatch-result-request";
import { MinibatchModel } from "./models/mini-batch";
import { MiniBatchState } from "./models/mini-batch-state";
import { SaveMiniBatchModel } from "./models/save-mini-batch";

async function fetchMiniBatches(states: MiniBatchState[]) {
  return await pcrService.getMiniBatchesByStates(states);
}

export function useMiniBatchesQuery(...states: MiniBatchState[]) {
  return useQuery<MinibatchModel[]>(["minibatches", states], () =>
    fetchMiniBatches(states)
  );
}

export function useAssignMinibatchControlItemBarcodeMutation() {
  return useMutation((model: AssignMinibatchControlItemBarcode) =>
    pcrService.assignMinibatchControlItemBarcode(model)
  );
}

export function useSaveUtiRunMinibatchMutation() {
  return useMutation((model: SaveMiniBatchModel) =>
    pcrService.updateUtiRunMinibatch(model)
  );
}

export function useCompleteUtiRunMinibatchMutation() {
  return useMutation((model: SaveMiniBatchModel) =>
    pcrService.completeUtiRunMinibatch(model)
  );
}

export function useAssignMinibatchMetadataMutation() {
  return useMutation((model: AssignMinibatchMetadata) =>
    pcrService.assignMinibatchMetadata(model)
  );
}

export function useReportMinibatchExportedMutation() {
  return useMutation((model: number) =>
    pcrService.reportMinibatchExported(model)
  );
}

export function useSaveAbrRunMinibatchMutation() {
  return useMutation((model: SaveMiniBatchModel) =>
    pcrService.updateAbrRunMinibatch(model)
  );
}

export function useCompleteAbrRunMinibatchMutation() {
  return useMutation((model: SaveMiniBatchModel) =>
    pcrService.completeAbrRunMinibatch(model)
  );
}

export function useAssignBatchAbrResultMinibatchMutation() {
  return useMutation((model: AssignMinibatchResultRequest) =>
    pcrService.assignAbrBatchResult(model)
  );
}

export function useAssignBatchUtiResultMinibatchMutation() {
  return useMutation((model: AssignMinibatchResultRequest) =>
    pcrService.assignUtiBatchResult(model)
  );
}
