import { MenuItem } from "primereact/menuitem";
import { TabMenu } from "primereact/tabmenu";
import { useState, useEffect } from "react";
import {
  matchPath,
  resolvePath,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { authService } from "../../services/auth.service";

export interface RouterAwareTabMenuProps {
  parentRoute: string;
  items: RouterAwareTabMenuItem[];
}

export interface RouterAwareTabMenuItem {
  label: string;
  url: string;
}

export function RouterAwareTabMenu({
  parentRoute,
  items,
}: RouterAwareTabMenuProps) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [activeIndex, setActiveIndex] = useState<number>();
  const [myAccountItem, setMyAccountItem] = useState<MenuItem>();

  useEffect(() => {
    if (!myAccountItem) {
      setMyAccountItem({
        label: authService.getLoggedUser()?.username,
        icon: "pi pi-fw pi-user",
        style: { position: "absolute", right: "0" },
      });
    }

    items.forEach((item, index) => {
      if (matchPath(resolvePath(item.url, parentRoute).pathname, pathname)) {
        setActiveIndex(index);
      }
    });
  }, [pathname]);

  const menuItems = items.map((i) => {
    return { label: i.label } as MenuItem;
  });
  if (myAccountItem) {
    menuItems.push(myAccountItem!);
  }

  function goToTab(index: number, menuItem: MenuItem) {
    if (menuItem == myAccountItem) {
      navigate("/my-account");
    } else {
      navigate(items[index].url);
    }
  }

  return (
    <TabMenu
      model={menuItems}
      activeIndex={activeIndex}
      onTabChange={(e) => goToTab(e.index, e.value)}
    />
  );
}
