import { MinibatchModel } from "../queries/pcr/models/mini-batch";
import { MiniBatchState } from "../queries/pcr/models/mini-batch-state";

export function isMinibatchUti(minibatch: MinibatchModel): boolean {
  return (
    minibatch.state === MiniBatchState.PendingUtiSetup ||
    minibatch.state === MiniBatchState.CompletedUtiSetup ||
    minibatch.state === MiniBatchState.PendingUtiRun ||
    minibatch.state === MiniBatchState.CompletedUtiRun ||
    minibatch.state === MiniBatchState.PendingUtiResult
  );
}

export function isMinibatchAbr(minibatch: MinibatchModel): boolean {
  return (
    minibatch.state === MiniBatchState.PendingAbrSetup ||
    minibatch.state === MiniBatchState.PendingAbrRun ||
    minibatch.state === MiniBatchState.CompletedAbrSetup ||
    minibatch.state === MiniBatchState.CompletedAbrRun ||
    minibatch.state === MiniBatchState.PendingAbrResult
  );
}
