/* eslint-disable react-hooks/rules-of-hooks */
import { useMutation, useQuery } from "react-query";
import { pcrService } from "../../services/pcr/pcr.service";
import { AddSuperbatchControlItemRequest } from "./models/add-superbatch-control-item-request";
import { AssignSuperbatchItemPositionRequest } from "./models/assign-superbatch-item-position-request";
import { AssignSuperbatchMetadata } from "./models/assign-superbatch-metadata-request";
import { AssignSuperbatchPlate } from "./models/assign-superbatch-plate-request";
import { BatchForAbrModel } from "./models/batch-for-abr";
import { SaveMiniBatchSetupModel } from "./models/save-mini-batch-uti";
import { SaveSuperBatchModel } from "./models/save-super-batch";
import { SuperbatchModel } from "./models/super-batch";
import { SuperBatchState } from "./models/super-batch-state";

async function fetchSuperBatches(states: SuperBatchState[]) {
  return await pcrService.getSuperBatchesByStates(states);
}

async function fetchSuperBatchesWithTubes(states: SuperBatchState[]) {
  return await pcrService.getSuperBatchesWithTubesByStates(states);
}

async function fetchSuperBatchesWithMinibatches(states: SuperBatchState[]) {
  return await pcrService.getSuperBatchesWithMinibatchesByStates(states);
}

async function fetchSuperBatch(id: number | undefined) {
  if (!id) {
    return;
  } else {
    return await pcrService.getSuperBatchDetails(id);
  }
}

async function fetchSuperBatchByCode(code: string | undefined) {
  if (!code) {
    return;
  } else {
    return await pcrService.getSuperBatchDetailsByCode(code);
  }
}

async function fetchSuperBatchByPlate(code: string | undefined) {
  if (!code) {
    return;
  } else {
    return await pcrService.getSuperBatchDetailsByPlate(code);
  }
}

export function useSuperBatchesQuery(...states: SuperBatchState[]) {
  return useQuery<SuperbatchModel[]>(["superBatches", states], () =>
    fetchSuperBatches(states)
  );
}

export function useSuperBatchesWithTubesQuery(...states: SuperBatchState[]) {
  return useQuery<SuperbatchModel[]>(["superBatches", "tubes", states], () =>
    fetchSuperBatchesWithTubes(states)
  );
}

export function useSuperBatchesWithMinibatchesQuery(...states: SuperBatchState[]) {
  return useQuery<SuperbatchModel[]>(["superBatches", "minibatches", states], () =>
    fetchSuperBatchesWithMinibatches(states)
  );
}

export function useSuperBatchQuery(id: number | undefined) {
  return useQuery<SuperbatchModel | undefined>(
    ["superBatches", id],
    () => fetchSuperBatch(id),
    { refetchOnWindowFocus: false }
  );
}

export function useSuperBatchByCodeQuery(code: string | undefined) {
  return useQuery<SuperbatchModel | undefined>(["superBatches", code], () =>
    fetchSuperBatchByCode(code)
  );
}

export function useSuperBatchByPlateQuery(code: string | undefined) {
  return useQuery<SuperbatchModel | undefined>(["superBatches", code], () =>
    fetchSuperBatchByPlate(code)
  );
}

export function useAssignSuperbatchItemPositionMutation() {
  return useMutation((model: AssignSuperbatchItemPositionRequest) =>
    pcrService.assignSuperbatchItemPosition(model)
  );
}

export function useAddSuperbatchControlItemMutation() {
  return useMutation((model: AddSuperbatchControlItemRequest) =>
    pcrService.addSuperbatchControlItem(model)
  );
}

export function useSaveSuperBatchMutation() {
  return useMutation((model: SaveSuperBatchModel) =>
    pcrService.saveSuperBatchQueue(model)
  );
}

export function useCompleteSuperBatchMutation() {
  return useMutation((model: SaveSuperBatchModel) =>
    pcrService.completeSuperBatchQueue(model)
  );
}

export function useAssignSuperbatchPlateMutation() {
  return useMutation((model: AssignSuperbatchPlate) =>
    pcrService.assignSuperbatchPlate(model)
  );
}

export function useAssignSuperbatchMetadataMutation() {
  return useMutation((model: AssignSuperbatchMetadata) =>
    pcrService.assignSuperbatchMetadata(model)
  );
}

export function useCompleteMinibatchUtiMutation() {
  return useMutation((model: SaveMiniBatchSetupModel) =>
    pcrService.completeMinibatchUtiSetup(model)
  );
}

export function useCompleteMinibatchAbrMutation() {
  return useMutation((model: SaveMiniBatchSetupModel) =>
    pcrService.completeMinibatchAbrSetup(model)
  );
}

export function useBatchForAbrMutation() {
  return useMutation((model: BatchForAbrModel) =>
    pcrService.batchForAbr(model)
  );
}
