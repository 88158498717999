import {
  ExtendedMetaData,
  ExtendedMetaDataItem,
} from "../../queries/micro/models/meta-data";
import { ExtendedMetaDataInputItem } from "./ExtendedMetaDataInputItem";

export interface ExtendedMetaDataInputProps {
  metadata: ExtendedMetaData | null;
  disabled?: boolean;
  onChange?: (v: ExtendedMetaData) => void;
}

export function ExtendedMetaDataInput({
  metadata,
  disabled = false,
  onChange,
}: ExtendedMetaDataInputProps) {
  function update() {
    if (onChange) {
      const data = {
        equipments: metadata?.equipments ? [...metadata.equipments] : undefined,
        reagentLots: metadata?.reagentLots
          ? [...metadata?.reagentLots]
          : undefined,
        instruments: metadata?.instruments
          ? [...metadata?.instruments]
          : undefined,
      } as ExtendedMetaData;

      onChange(data);
    }
  }

  function setMetaDataItemValue(item: ExtendedMetaDataItem, value: string) {
    item.value = value;
    update();
  }

  return (
    <>
      {metadata?.reagentLots?.map((reagentLot) => (
        <ExtendedMetaDataInputItem
          item={reagentLot}
          onChange={(v) => setMetaDataItemValue(reagentLot, v)}
          key={reagentLot.label}
          disabled={disabled}
        />
      ))}
      {metadata?.equipments?.map((equipment) => (
        <ExtendedMetaDataInputItem
          item={equipment}
          onChange={(v) => setMetaDataItemValue(equipment, v)}
          key={equipment.label}
          disabled={disabled}
        />
      ))}
    </>
  );
}
