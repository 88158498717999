import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Tag } from "primereact/tag";
import { classNames } from "primereact/utils";
import { useEffect, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { ExtendedMetaData, MetaData } from "../../queries/pcr/models/meta-data";
import { SuperbatchModel } from "../../queries/pcr/models/super-batch";
import { SuperbatchItemModel } from "../../queries/pcr/models/super-batch-item";
import {
  useSuperBatchQuery,
  useSaveSuperBatchMutation,
  useCompleteSuperBatchMutation,
} from "../../queries/pcr/superBatches.query";
import { useRemoveTubeMutation } from "../../queries/pcr/tubes.query";
import { useToast } from "../ui/ToastContext";
import { MetaDataInput } from "./MetaDataInput";
import { RemoveTubeButton } from "../ui/RemoveTubeButton";
import { SuperBatchPlateForAssigning } from "./SuperBatchPlateForAssigning";
import { isMetaDataValid } from "../../utils/helpers";
import { CommentInput } from "./CommentInput";
import { ConfigurationModel } from "../../models/admin/configuration";
import { SuperBatchMetaDataInput } from "./SuperBatchMetaDataInput";
import { TubeType } from "../../queries/pcr/models/tube-type";

export interface SuperBatchEditorProps {
  id: number;
  configuration: ConfigurationModel;
  onComplete: () => void;
}

export function SuperBatchEditor({
  id,
  onComplete,
  configuration,
}: SuperBatchEditorProps) {
  const [selectedSuperBatch, setSelectedSuperBatch] =
    useState<SuperbatchModel>();

  const [selectedTube, setSelectedTube] = useState<SuperbatchItemModel>();
  const [manuallySaved, setManuallySaved] = useState(false);

  const [metadata, setMetaData] = useState<ExtendedMetaData>(
    {} as ExtendedMetaData
  );
  const [comment, setComment] = useState<string>("");

  const superBatchQuery = useSuperBatchQuery(id);
  const saveMutation = useSaveSuperBatchMutation();
  const completeMutation = useCompleteSuperBatchMutation();
  const removeTubeMutation = useRemoveTubeMutation();

  const queryClient = useQueryClient();

  const toast = useToast();

  useEffect(() => {
    if (superBatchQuery.data && !superBatchQuery.isLoading) {
      setMetaData(superBatchQuery.data.metadata);
      setComment(superBatchQuery.data.comment ?? "");
      setSelectedSuperBatch(superBatchQuery.data);
      setSelectedTube(undefined);
    }
  }, [
    superBatchQuery.data,
    superBatchQuery.dataUpdatedAt,
    superBatchQuery.isLoading,
  ]);

  const canBeCompleted = useMemo(() => {
    return (
      superBatchQuery.data &&
      superBatchQuery.data.items.every((t) => t.column && t.row) &&
      isMetaDataValid(superBatchQuery.data.metadata) &&
      manuallySaved
    );
  }, [superBatchQuery.data, superBatchQuery.dataUpdatedAt]);

  async function save(manual: boolean) {
    if (selectedSuperBatch) {
      await saveMutation.mutateAsync({
        id: selectedSuperBatch.id,
        metadata: metadata,
        comment: comment,
      });

      if (manual) {
        setManuallySaved(true);
        queryClient.invalidateQueries("superBatches");
        toast.current!.show({
          severity: "success",
          detail: "Metadata saved",
        });
      } else {
        setManuallySaved(false);
      }
    }
  }

  function complete() {
    if (selectedSuperBatch) {
      completeMutation.mutate(
        {
          id: selectedSuperBatch.id,
          metadata: metadata,
          comment: comment,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries("superBatches");
            queryClient.invalidateQueries("pcrtotals");
            toast.current!.show({
              detail: "SuperBatch completed",
              severity: "success",
            });
            onComplete();
          },
          onError: (error) => console.error(error),
        }
      );
    }
  }

  function removeTube(tubeId: number, comment: string) {
    removeTubeMutation.mutate(
      {
        tubeId: tubeId,
        comment: comment,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries("superBatches");
          queryClient.invalidateQueries("tubes");
          toast.current!.show({
            detail: "Tube removed",
            severity: "success",
          });
        },
        onError: (error) => console.error(error),
      }
    );
  }

  return (
    <>
      {selectedSuperBatch && (
        <div>
          <div>
            <Button
              className="p-button-outlined p-button-plain"
              icon="pi pi-arrow-left"
              label="Back"
              onClick={onComplete}
            ></Button>
          </div>
          <div className="flex flex-row">
            <div className="col-5">
              <DataTable
                value={superBatchQuery.data!.items}
                paginator
                rows={13}
                selectionMode="single"
                onRowSelect={(e) => setSelectedTube(e.data)}
                onRowClick={(e) =>
                  e.data === selectedTube
                    ? setSelectedTube(undefined)
                    : undefined
                }
                selection={selectedTube}
                rowClassName={(rowData: SuperbatchItemModel) =>
                  classNames(
                    rowData.column &&
                      rowData.column.length > 0 &&
                      rowData.row &&
                      rowData.row.length > 0
                      ? "bg-green-200"
                      : "",
                    rowData === selectedTube ? "bg-green-400" : ""
                  ) ?? {}
                }
              >
                <Column
                  header="Tube ID"
                  alignHeader={"center"}
                  body={(rowData) => <span>{rowData.tube.barcode}</span>}
                />
              </DataTable>
              <div className="flex flex-row justify-content-between mt-2">
                <div className="flex align-items-center">
                  <Button disabled={!canBeCompleted} onClick={complete}>
                    Complete SuperBatch
                  </Button>
                  {!isMetaDataValid(selectedSuperBatch.metadata) && (
                    <Tag
                      className="ml-2"
                      icon="pi pi-exclamation-triangle"
                      severity="warning"
                      value="Metadata not filled"
                    ></Tag>
                  )}
                  {!selectedSuperBatch?.items.every(
                    (t) => t.column && t.row
                  ) && (
                    <Tag
                      className="ml-2"
                      icon="pi pi-exclamation-triangle"
                      severity="warning"
                      value="Not all tubes assigned"
                    ></Tag>
                  )}
                  {!manuallySaved && (
                    <Tag
                      className="ml-2"
                      icon="pi pi-exclamation-triangle"
                      severity="warning"
                      value="Not manually saved"
                    ></Tag>
                  )}
                </div>
                {selectedTube &&
                  selectedTube.tube.type === TubeType.Regular && (
                    <RemoveTubeButton
                      tube={selectedTube.tube}
                      onConfirmRemove={(id, comment) => removeTube(id, comment)}
                    />
                  )}
              </div>
            </div>
            <div className="col-7">
              <div className="flex flex-row justify-content-evenly align-items-center mb-3">
                <span>PLATE ID: {selectedSuperBatch.plateBarcode}</span>
              </div>
              <div>
                <SuperBatchPlateForAssigning
                  superbatch={selectedSuperBatch}
                  disabledWells={configuration.disabledSuperbatchWells}
                />
              </div>
              <div className="flex flex-row">
                <div className="flex-1">
                  <SuperBatchMetaDataInput
                    metadata={selectedSuperBatch.metadata}
                    onChange={setMetaData}
                    onBlur={() => save(false)}
                  />
                </div>
                <CommentInput comment={comment} onChange={setComment} />
              </div>
              <div className="flex justify-content-end">
                <Button onClick={() => save(true)}>Save</Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
