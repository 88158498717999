import { PageWrapper } from "../../components/ui/PageWrapper";
import { Outlet } from "react-router-dom";
import { RouterAwareTabMenu } from "../../components/ui/RouterAwareTabMenu";
import { TopLoader } from "../../components/TopLoader";
import { useMicroTotalsQuery } from "../../queries/admin/configuration.query";
import { useEffect, useState } from "react";

export function Micro() {

  
  const [items, setItems] = useState<{ label: string; url: string }[]>([
    { label: "New", url: "new" },
    { label: "Preculture", url: "preculture" },
    { label: "Plate culture", url: "plate-culture" },
    { label: "Pending results", url: "pending-results" },
    { label: "Sample prep", url: "sample-preparation" },
   // { label: "Neg PCR results", url: "negative-pcr-results" },
  ] as { label: string; url: string }[]);
  const { isLoading, data, dataUpdatedAt } = useMicroTotalsQuery();

  useEffect(() => {
    if (!isLoading && data) {
      setItems([
        { label: "New", url: "new" },
        { label: `Preculture (${data.preculture})`, url: "preculture" },
        { label: `Plate culture (${data.plateCulture})`, url: "plate-culture" },
        { label: `Pending results (${data.pendingResults})`, url: "pending-results" },
        { label: `Sample prep (${data.samplePrep})`, url: "sample-preparation" },
       // { label: `Neg PCR results (${data.negPcrResults})`, url: "negative-pcr-results" },
      ] as { label: string; url: string }[]);
    }
  }, [isLoading, data, dataUpdatedAt]);

  return (
    <div>
      <RouterAwareTabMenu items={items} parentRoute="/micro" />
      <TopLoader/>
      <PageWrapper>
        <Outlet />
      </PageWrapper>
    </div>
  );
}
