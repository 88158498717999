/* eslint-disable react-hooks/rules-of-hooks */
import { useQuery } from "react-query";
import { AuditModel } from "../../models/admin/audit";
import { DequeuedAuditModel } from "../../models/admin/dequeued-audit-model";
import { RerunAuditModel } from "../../models/admin/rerun-audit-model";
import { auditsService } from "../../services/admin/audits.service";
import { ServerLog } from "../../models/admin/server-log";

async function fetchAudits(dateFrom: Date, dateTo: Date) {
  const audits = await auditsService.getAudits(dateFrom, dateTo);

  return audits.map((a) => {
    return {
      id: a.id,
      action: a.action,
      timestamp: a.timestamp,
      tube: a.tube,
      user: a.user,
      minibatch: a.minibatch,
      minibatchSnapshot: a.minibatchSnapshot,
      superbatch: a.superbatch,
      rawData: a.rawData,
      actionDescription: a.actionDescription,
      actionData: a.actionData
    } as AuditModel;
  });
}

async function fetchRerunTubes(dateFrom: Date, dateTo: Date) {
  return await auditsService.getRerunTubes(dateFrom, dateTo);
}

async function fetchDequeuedTubes(dateFrom: Date, dateTo: Date) {
  return await auditsService.getDequeuedTubes(dateFrom, dateTo);
}


async function fetchUtiParsingLogs(tubeCode: string) {
  return await auditsService.getUtiParsingLogs(tubeCode);
}

export function useAuditsQuery(dateFrom: Date, dateTo: Date) {
  return useQuery<AuditModel[]>(["audits", dateFrom, dateTo], () =>
    fetchAudits(dateFrom, dateTo)
  );
}

export function useRerunTubesQuery(dateFrom: Date, dateTo: Date) {
  return useQuery<RerunAuditModel[]>(["tubes", dateFrom, dateTo], () =>
    fetchRerunTubes(dateFrom, dateTo)
  );
}

export function useDequeuedTubesQuery(dateFrom: Date, dateTo: Date) {
  return useQuery<DequeuedAuditModel[]>(["tubes", dateFrom, dateTo], () =>
  fetchDequeuedTubes(dateFrom, dateTo)
  );
}

export function useUtiParsingLogsQuery(tubeCode: string) {
  return useQuery<ServerLog[]>(["uti-parsing-logs", tubeCode], () =>
  fetchUtiParsingLogs(tubeCode),
  {enabled: Boolean(tubeCode)}
  );
}
