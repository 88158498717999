import { Button } from "primereact/button";
import { TabPanel, TabView } from "primereact/tabview";
import { useEffect, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { MiniBatchPlate } from "../../components/pcr/MiniBatchPlate";
import { SuperBatchPlate } from "../../components/pcr/SuperBatchPlate";
import { useCompleteMinibatchAbrMutation } from "../../queries/pcr/superBatches.query";
import { InputText } from "primereact/inputtext";
import { PlateCoordinates } from "../../models/pcr/plate-coordinates";
import { MiniBatchState } from "../../queries/pcr/models/mini-batch-state";
import { ExtendedMetaData } from "../../queries/pcr/models/meta-data";
import { useToast } from "../../components/ui/ToastContext";
import { PlateWell } from "../../components/pcr/PlateWell";
import { Tag } from "primereact/tag";
import { TubeType } from "../../queries/pcr/models/tube-type";
import { classNames } from "primereact/utils";
import { TubeModel } from "../../queries/pcr/models/tube";
import { useRemoveTubeMutation } from "../../queries/pcr/tubes.query";
import { RemoveTubeButton } from "../../components/ui/RemoveTubeButton";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
  useAbrBatchesQuery,
  useAbrBatchQuery,
} from "../../queries/pcr/abrBatches.query";
import { AbrBatchModel } from "../../queries/pcr/models/abr-batch";
import { AbrBatchState } from "../../queries/pcr/models/abr-batch-state";
import { TubeResult } from "../../queries/pcr/models/tube-result";
import { format } from "date-fns";
import { DATE_FORMAT } from "../../utils/constants";
import { isMetaDataValid } from "../../utils/helpers";
import { MiniBatchMetaDataInput } from "../../components/pcr/MiniBatchMetaDataInput";
import { CommentInput } from "../../components/pcr/CommentInput";

export function PagePcrAbrSetup() {
  const [selectedAbrBatch, setSelectedAbrBatch] = useState<AbrBatchModel>();
  const [highlightedWell, setHighlightedWell] = useState<PlateCoordinates>();
  const [selectedId, setSelectedId] = useState<number>();
  const [selectedTube, setSelectedTube] = useState<TubeModel>();

  const [selectedMiniBatchIndex, setSelectedMiniBatchIndex] = useState(0);
  const [selectedSuperBatchIndex, setSelectedSuperBatchIndex] = useState(0);
  const [selectedPlateBarcode, setSelectedPlateBarcode] = useState<string>("");
  const [selectedMiniBatchMetaData, setSelectedMiniBatchMetaData] =
    useState<ExtendedMetaData>({} as ExtendedMetaData);
  const [selectedMiniBatchComment, setSelectedMinibatchComment] =
    useState<string>("");

  const [enabledSuperBatchTubeIds, setEnabledSuperBatchTubeIds] =
    useState<number[]>();
  const completeMinibatchAbrMutation = useCompleteMinibatchAbrMutation();
  const removeTubeMutation = useRemoveTubeMutation();

  const abrBatchQuery = useAbrBatchQuery(selectedAbrBatch?.id);
  const abrBatchesQuery = useAbrBatchesQuery(AbrBatchState.SetupPending);

  const toast = useToast();
  const queryClient = useQueryClient();

  const abrBatchDetails = abrBatchQuery.data;

  const minibatches = useMemo(() => {
    if (abrBatchQuery.data) {
      return abrBatchQuery.data.miniBatches.filter(
        (mb) => mb.state !== MiniBatchState.Reruned // filter out minibatches that were rerun before
      );
    }
  }, [abrBatchQuery.data, abrBatchQuery.dataUpdatedAt]);

  const superbatches = useMemo(() => {
    if (abrBatchQuery.data) {
      return abrBatchQuery.data.superBatches;
    }
  }, [abrBatchQuery.data, abrBatchQuery.dataUpdatedAt]);

  const selectedMinibatch = useMemo(() => {
    return minibatches?.[selectedMiniBatchIndex];
  }, [selectedMiniBatchIndex, minibatches]);

  const selectedSuperbatch = useMemo(() => {
    return superbatches?.[selectedSuperBatchIndex];
  }, [selectedSuperBatchIndex, superbatches]);

  useEffect(() => {
    if (minibatches) {
      setSelectedPlateBarcode(selectedMinibatch?.plateBarcode ?? "");

      if (selectedMinibatch) {
        setSelectedMiniBatchMetaData(selectedMinibatch.metadata);
        setSelectedMinibatchComment(selectedMinibatch.comment ?? "");
      }
    }
  }, [selectedMinibatch, minibatches]);

  useEffect(() => {
    if (minibatches && minibatches.length > 0) {
      const enabledTubesIds = minibatches?.[selectedMiniBatchIndex].items
        .filter((i) => i.tube.pcrResult !== TubeResult.Negative)
        .flatMap((s) => s.tube.id);

      setEnabledSuperBatchTubeIds(enabledTubesIds);
    }
  }, [selectedMiniBatchIndex, minibatches]);

  useEffect(() => {
    setSelectedTube(undefined);
    setSelectedId(undefined);
    setHighlightedWell(undefined);
  }, [selectedSuperBatchIndex]);

  function metadataIsFilled() {
    return isMetaDataValid(selectedMiniBatchMetaData);
  }

  const canBeCompleted = useMemo(() => {
    return (
      selectedPlateBarcode.trim() &&
      abrBatchDetails &&
      abrBatchDetails.miniBatches &&
      selectedMinibatch?.state === MiniBatchState.PendingAbrSetup &&
      metadataIsFilled()
    );
  }, [
    selectedMinibatch?.state,
    selectedPlateBarcode,
    abrBatchDetails,
    metadataIsFilled,
  ]);

  const canBeModified = useMemo(() => {
    let result =
      abrBatchDetails &&
      abrBatchDetails.miniBatches &&
      selectedMinibatch?.state === MiniBatchState.PendingAbrSetup;
    return result;
  }, [selectedMinibatch, abrBatchDetails]);

  const canBeSaved = useMemo(() => {
    let result =
      abrBatchDetails &&
      abrBatchDetails.miniBatches &&
      selectedMinibatch?.state === MiniBatchState.CompletedAbrSetup;
    return result;
  }, [selectedMinibatch, abrBatchDetails]);

  function handleMiniBatchTabIndexChange(index: number) {
    setSelectedMiniBatchIndex(index);
  }

  function handleSuperBatchTabIndexChange(index: number) {
    setSelectedSuperBatchIndex(index);
  }

  function handleAbrBatchSelected(abrBatch: AbrBatchModel) {
    setSelectedAbrBatch(abrBatch);
  }

  function save() {
    if (selectedAbrBatch) {
      setSelectedAbrBatch(undefined);
    }
  }

  function toggleWell(c: PlateCoordinates) {
    const tube = selectedSuperbatch!.items.find(
      (t) => t.column === c.column && t.row === c.row
    );
    if (tube) {
      if (
        highlightedWell?.column === c.column &&
        highlightedWell.row === c.row
      ) {
        setSelectedId(undefined);
        setHighlightedWell(undefined);
        setSelectedTube(undefined);
      } else {
        setSelectedId(tube.tube.id);
        setHighlightedWell(c);
        setSelectedTube(tube.tube);
      }
    }
  }

  function complete() {
    if (
      selectedPlateBarcode &&
      abrBatchDetails &&
      abrBatchDetails.miniBatches
    ) {
      completeMinibatchAbrMutation.mutate(
        {
          id: selectedMinibatch!.id,
          plateBarcode: selectedPlateBarcode,
          metadata: selectedMiniBatchMetaData,
          comment: selectedMiniBatchComment,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries();
            toast.current!.show({
              detail: "Minibatch confirmed",
              severity: "success",
            });
          },
          onError: (error) => console.error(error),
        }
      );
    }
  }

  function removeTube(tubeId: number, comment: string) {
    removeTubeMutation.mutate(
      {
        tubeId: tubeId,
        comment: comment,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries("superBatches");
          queryClient.invalidateQueries("abrBatches");
          queryClient.invalidateQueries("tubes");
          queryClient.invalidateQueries("pcrtotals");
          setSelectedTube(undefined);
          setSelectedId(undefined);
          setHighlightedWell(undefined);
          toast.current!.show({
            detail: "Tube removed",
            severity: "success",
          });
        },
        onError: (error) => console.error(error),
      }
    );
  }

  function shouldTabBeDisabled(index: number) {
    if (index === 0) return false;

    const previousMiniBatch = minibatches?.[index - 1];

    const result = previousMiniBatch!.state === MiniBatchState.PendingAbrSetup;

    return result;
  }

  function renderControlWell(tubeType: TubeType) {
    const tube = selectedMinibatch?.items.find(
      (i) => i.tube.type === tubeType
    )?.tube;

    if (!tube) {
      return <div></div>;
    }

    return (
      <div className="ml-1">
        <PlateWell
          text={tube.barcode}
          highlighted={selectedId === tube.id}
          onClick={() => {
            if (selectedId === tube.id) {
              setSelectedId(undefined);
            } else {
              setSelectedId(tube.id);
            }
            setHighlightedWell(undefined);
            setSelectedTube(undefined);
          }}
        />
      </div>
    );
  }

  function getSuperBatchIds(abrBatch: AbrBatchModel): string {
    return abrBatch.superBatches.map((sb) => sb.reference).join(", ");
  }

  return (
    <>
      {!abrBatchesQuery.isLoading &&
        abrBatchesQuery.data &&
        !selectedAbrBatch && (
          <DataTable
            className="w-6"
            rowClassName={() => "cursor-pointer"}
            value={abrBatchesQuery.data}
            onRowClick={(d) => handleAbrBatchSelected(d.data)}
          >
            <Column
              header="Date merged"
              body={(b) => <span>{format(b.dateCreated, DATE_FORMAT)}</span>}
            />
            <Column
              header="SuperBatches"
              body={(b) => <span>{getSuperBatchIds(b)}</span>}
            />
          </DataTable>
        )}
      {selectedAbrBatch && abrBatchDetails && (
        <div className="flex flex-row">
          <div className="col-6">
            <TabView
              activeIndex={selectedSuperBatchIndex}
              onTabChange={(e) => handleSuperBatchTabIndexChange(e.index)}
            >
              {superbatches?.map((sb) => (
                <TabPanel header={sb.reference} key={sb.id}>
                  <>
                    <div className="flex flex-row justify-content-evenly align-items-center mb-3">
                      <span>SUPERBATCH ID: {sb.reference}</span>
                    </div>
                    <div>
                      <SuperBatchPlate
                        superbatchItems={sb.items}
                        highlightedWells={
                          highlightedWell ? [highlightedWell] : []
                        }
                        onHoleClick={(c) => toggleWell(c)}
                        enabledIds={enabledSuperBatchTubeIds}
                      />
                    </div>
                    {selectedMinibatch && (
                      <div className="flex flex-row justify-content-end mt-2">
                        {renderControlWell(TubeType.ControlPcrPos)}
                        {renderControlWell(TubeType.ControlPcrNeg)}
                      </div>
                    )}
                  </>
                </TabPanel>
              ))}
            </TabView>
            <div className="mt-2 flex flex-row justify-content-between">
              <Button disabled={!canBeSaved} onClick={save}>
                Save
              </Button>
              {selectedTube && selectedTube.type === TubeType.Regular && (
                <RemoveTubeButton
                  tube={selectedTube}
                  onConfirmRemove={removeTube}
                />
              )}
            </div>
          </div>
          {minibatches && (
            <div className="col-6">
              <TabView
                activeIndex={selectedMiniBatchIndex}
                onTabChange={(e) => handleMiniBatchTabIndexChange(e.index)}
              >
                {minibatches.map((miniBatch, index) => (
                  <TabPanel
                    header={"Plate " + (index + 1)}
                    key={index}
                    disabled={shouldTabBeDisabled(index)}
                    headerClassName={classNames(
                      miniBatch.state !== MiniBatchState.PendingAbrSetup
                        ? "line-through"
                        : undefined
                    )}
                  >
                    <div className="mb-3">
                      <span>Minibatch plate ID: </span>
                      <InputText
                        disabled={!canBeModified}
                        autoFocus={!miniBatch.plateBarcode}
                        value={selectedPlateBarcode}
                        onChange={(e) =>
                          setSelectedPlateBarcode(e.target.value)
                        }
                      ></InputText>
                    </div>
                    <MiniBatchPlate
                      miniBatch={miniBatch}
                      selectedIds={selectedId}
                    />
                  </TabPanel>
                ))}
              </TabView>
              <div>
                <MiniBatchMetaDataInput
                  metadata={selectedMiniBatchMetaData}
                  isAbr
                  disabled={
                    selectedMinibatch?.state !== MiniBatchState.PendingAbrSetup
                  }
                  onChange={setSelectedMiniBatchMetaData}
                />
              </div>
              <div>
                <CommentInput
                  comment={selectedMiniBatchComment}
                  onChange={setSelectedMinibatchComment}
                  disabled={
                    selectedMinibatch?.state !== MiniBatchState.PendingAbrSetup
                  }
                />
              </div>
              <div className=" flex mt-2 justify-content-end">
                {!selectedPlateBarcode.trim() && (
                  <Tag
                    className="mr-2"
                    icon="pi pi-exclamation-triangle"
                    severity="warning"
                    value="Plate ID not filled"
                  ></Tag>
                )}
                {!metadataIsFilled() && (
                  <Tag
                    className="mr-2"
                    icon="pi pi-exclamation-triangle"
                    severity="warning"
                    value="Metadata not filled"
                  ></Tag>
                )}
                <Button disabled={!canBeCompleted} onClick={complete}>
                  Complete Minibatch
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}
