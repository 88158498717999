import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCreateMicroTubesMutation } from "../../queries/micro/tubes.query";
import { useQueryClient } from "react-query";

export function PageMicroNewTube() {
  const [barcodes, setBarcodes] = useState<string[]>([]);
  const [newBarcode, setNewBarcode] = useState("");

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const createTubesMutation = useCreateMicroTubesMutation();

  function addBarcode() {
    let subject = newBarcode.trim();
    if (subject !== "" && barcodes.indexOf(subject) === -1) {
      setBarcodes([...barcodes, subject]);
      setNewBarcode("");
    }
    return true;
  }

  function createTubes() {
    createTubesMutation.mutate(
      { barcodes: barcodes },
      {
        onSuccess: () => {
          queryClient.invalidateQueries("micrototals");
          navigate("../preculture");
        },
        onError: (error) => console.error(error),
      }
    );
  }

  return (
    <div className="flex flex-column align-items-center">
      <h2>Scan tube IDs:</h2>
      <div className="flex flex-column">
        {barcodes.map((b) => (
          <InputText value={b} className="mb-2" key={b} />
        ))}
        <InputText
          autoFocus
          onKeyDown={(e) => {
            if (e.code === "Tab") {
              e.preventDefault();
              e.stopPropagation();
              addBarcode();
            }
          }}
          value={newBarcode}
          onChange={(e) => setNewBarcode(e.target.value)}
        />
      </div>
      <Button
        label="Create tubes"
        className="w-3 mt-3"
        onClick={createTubes}
        disabled={barcodes.length === 0}
      />
    </div>
  );
}
