export enum MiniBatchState {
  Undefined = 0,
  Created = 10,
  PendingUtiSetup = 30,
  CompletedUtiSetup = 31,
  PendingUtiRun = 32,
  CompletedUtiRun = 33,
  PendingUtiResult = 34,
  PendingAbrSetup = 40,
  CompletedAbrSetup = 41,
  PendingAbrRun = 42,
  CompletedAbrRun = 43,
  PendingAbrResult = 44,
  Reruned = 998,
  WorkupCompleted = 999,
}
