import { useEffect, useMemo, useState } from "react";
import { MicroTubeModel } from "../../models/micro/micro-tube";
import {
  useTubesNoAstQuery,
  useTubesQuery,
  useUpdatePlateCultureTubesMutation,
} from "../../queries/micro/tubes.query";
import { TubeState } from "../../queries/micro/models/tube-state";
import { UpdateCultureTubesModel } from "../../queries/micro/models/update-culture-tubes";
import { useQueryClient } from "react-query";
import {
  IncubatorDataChanges,
  IncubatorDataPanel,
} from "../../components/micro/IncubatorDataPanel";
import { CultureTubesSelector } from "../../components/micro/CultureTubesSelector";
import { ExtendedMetaData } from "../../queries/micro/models/meta-data";

export function PageMicroPlateCulture() {
  const [tubes, setTubes] = useState<MicroTubeModel[]>([]);
  const [selectedTubes, setSelectedTubes] = useState<MicroTubeModel[]>([]);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);

  const { isLoading, data, dataUpdatedAt } = useTubesQuery(
    TubeState.PendingPlateCulture
  );

  const tubesMutation = useUpdatePlateCultureTubesMutation();
  const queryClient = useQueryClient();

  useEffect(() => {
    const tubeIds = tubes.map((t) => t.id);

    const validSelectedIds = selectedIds.filter((id) =>
      Boolean(tubeIds.find((tid) => tid === id))
    );

    if (validSelectedIds.length !== selectedIds.length) {
      setSelectedIds(validSelectedIds);
    }
  }, [tubes, selectedIds]);

  useEffect(() => {
    setSelectedTubes(tubes.filter((t) => selectedIds.find((i) => i === t.id)));
  }, [selectedIds, tubes]);

  useEffect(() => {
    if (!isLoading && data) {
      setTubes([
        ...data.map((d) => {
          return {
            id: d.id,
            barcode: d.barcode,
            selectable: true,
            timeIn: d.currentStepTimeIn,
            timeOut: d.currentStepTimeOut,
            metadata: ensureMetaData(d.currentStepMetaData),
            comment: d.currentStepComment ?? "",
          } as MicroTubeModel;
        }),
      ]);
    }
  }, [isLoading, data, dataUpdatedAt]);

  function ensureMetaData(metadata: ExtendedMetaData | null): ExtendedMetaData {
    if (!metadata) {
      return {
        reagentLots: [
          {
            label: "Mueller Hiton Agar Lot No.",
            value: "",
            required: true,
          },
        ],
        equipments: [
          {
            label: "Incubator",
            value: "",
            required: true,
          },
          {
            label: "P100 or P200",
            value: "",
            required: true,
          },
          {
            label: "Vortex",
            value: "",
            required: true,
          },
        ],
      } as ExtendedMetaData;
    } else {
      return metadata;
    }
  }

  function saveTubes(data: IncubatorDataChanges) {
    const model = {
      completed: false,
      tubeIds: selectedTubes.map((t) => t.id),
      timeIn: data.timeIn,
      timeOut: data.timeOut,
      metadata: data.metadata,
      comment: data.comment,
    } as UpdateCultureTubesModel;

    tubesMutation.mutate(model, {
      onSuccess: () => {
        queryClient.invalidateQueries("micrototals");
        queryClient.invalidateQueries("tubes");
        setSelectedIds([]);
      },
      onError: (error) => console.error(error),
    });
  }

  function completeTubes(data: IncubatorDataChanges) {
    const model = {
      completed: true,
      tubeIds: selectedTubes.map((t) => t.id),
      timeIn: data.timeIn,
      timeOut: data.timeOut,
      noAstPerformed: data.noAstPerformed,
      metadata: data.metadata,
      comment: data.comment,
    } as UpdateCultureTubesModel;

    tubesMutation.mutate(model, {
      onSuccess: () => {
        queryClient.invalidateQueries("micrototals");
        queryClient.invalidateQueries("tubes");
      },
      onError: (error) => console.error(error),
    });
  }

  return (
    <div className="flex">
      <div className="col-6">
        <h2>Plateculture Queue</h2>

        <div className="flex flex-column ">
          <CultureTubesSelector
            tubes={tubes}
            selectedTubes={selectedTubes}
            selectedIds={selectedIds}
            showNoAstWarning
            onChangeSelection={setSelectedIds}
          />
        </div>
      </div>
      <div className="col-6">
        {selectedTubes.length > 0 && (
          <IncubatorDataPanel
            selectedTubes={selectedTubes}
            allowBypass
            saveTubes={saveTubes}
            completeTubes={completeTubes}
          />
        )}
      </div>
    </div>
  );
}
