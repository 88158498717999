import { useEffect } from "react";
import { ExtendedMetaData } from "../../queries/pcr/models/meta-data";
import { ExtendedMetaDataInput } from "./ExtendedMetaDataInput";

export interface MiniBatchMetaDataInputProps {
  metadata: ExtendedMetaData;
  isAbr?: boolean;
  disabled?: boolean;
  onChange?: (v: ExtendedMetaData) => void;
  onBlur?: () => void;
}

export function MiniBatchMetaDataInput({
  metadata,
  isAbr = false,
  disabled,
  onChange,
  onBlur,
}: MiniBatchMetaDataInputProps) {
  useEffect(() => {
    let changesMade = false;
    if (!metadata.isAutomated) {
      metadata.isAutomated = false;
    }
    if (!metadata.equipments) {
      metadata.equipments = [];

      if(isAbr){
        metadata.equipments.push({
          label: "ClipTip Pipette (125)",
          value: "",
          required: true,
          disabled: false
        });

      } else{
        metadata.equipments.push({
          label: "ClipTip Pipette (125)",
          value: "",
          required: !metadata.isAutomated ,
          disabled: metadata.isAutomated
        });
        metadata.equipments.push({
          label: "Hamilton Starlet",
          value: "",
          required: metadata.isAutomated ,
          disabled: !metadata.isAutomated
        });
      }

      metadata.equipments.push({
        label: "Vortex",
        value: "",
        required: true,
        disabled: false,
      });
      metadata.equipments.push({
        label: "Picofuge",
        value: "",
        required: true,
        disabled: false,
      });
      metadata.equipments.push({
        label: "Additional Pipette",
        value: "",
        required: false,
        disabled: false,
      });
    }
    if (!metadata.reagentLots) {
      metadata.reagentLots = [];
      metadata.reagentLots.push({
        label: "TaqMan Master Mix",
        value: "",
        required: true,
        disabled: false,
      });
      metadata.reagentLots.push({
        label: "Positive Control",
        value: "",
        required: true,
        disabled: false,
      });
      if (isAbr) {
        metadata.reagentLots.push({
          label: "Negative Control (NTC)",
          value: "",
          required: true,
          disabled: false,
        });
        metadata.reagentLots.push({
          label: "ABR Custom Plate Lot No.",
          value: "",
          required: true,
          disabled: false,
        });
      } else {
        metadata.reagentLots.push({
          label: "UTM Custom Plate Lot No.",
          value: "",
          required: true,
          disabled: false,
        });
      }

      changesMade = true;
    }

    if (onChange && changesMade) {
      onChange({ ...metadata });
    }
  }, [metadata, isAbr, onChange]);

  return (
    <>
      {metadata && (
        <ExtendedMetaDataInput
          metadata={metadata}
          disabled={disabled}
          showAutomatedSwitch={!isAbr}
          onBlur={onBlur}
          onChange={onChange}
        />
      )}
    </>
  );
}
