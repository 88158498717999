import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import {
  FileUpload,
  FileUploadHandlerParam,
  FileUploadProps,
  FileUploadSelectParams,
} from "primereact/fileupload";
import { ProgressSpinner } from "primereact/progressspinner";
import { useRef, useState } from "react";
import { useUploadUtiResultFileMutation } from "../../queries/pcr/result-file.query";
import { PcrSubresult } from "../../queries/pcr/models/pcr-subresult";
import { ProgressBar } from "primereact/progressbar";
import { UtiParsingResult } from "../../queries/pcr/models/uti-parsing-result";
import {
  useAssignResultMutation,
  useRerunTubesMutation,
} from "../../queries/pcr/tubes.query";
import { PcrResultType } from "../../queries/pcr/models/pcr-result-type";
import { classNames } from "primereact/utils";
import { useQueryClient } from "react-query";

enum FileStatus {
  Pending,
  Processing,
  Processed,
  Error,
}

export function ResultFilesImporter() {
  const [showModal, setShowModal] = useState(false);
  const [showProcessingModal, setShowProcessingModal] = useState(false);
  const [fileStatus, setFileStatus] = useState(new Map<string, FileStatus>());
  const [fileResult, setFileResult] = useState(
    new Map<string, UtiParsingResult>()
  );
  const [progressBarValue, setProgressBarValue] = useState(0);

  const uploadFileMutation = useUploadUtiResultFileMutation();
  const rerunMutation = useRerunTubesMutation();
  const setResultMutation = useAssignResultMutation();

  const fileUploadRef = useRef<FileUpload>(null);

  const queryClient = useQueryClient();

  function handleShowModal() {
    setShowModal(true);
  }

  async function handleUpload(e: FileUploadHandlerParam) {
    setFileResult(new Map());

    for (const file of e.files) {
      const fileContent = new Blob([file], { type: file.type });

      setFileStatus((prevState) => {
        const newStatus = new Map(prevState);
        newStatus.set(file.name, FileStatus.Processing);
        return newStatus;
      });

      uploadFileMutation.mutateAsync(fileContent).then(async (response) => {
        setFileStatus((prevState) => {
          const newStatus = new Map(prevState);
          newStatus.set(file.name, FileStatus.Processed);
          return newStatus;
        });
        setFileResult((prevState) => {
          const newResult = new Map(prevState);
          newResult.set(file.name, response);
          return newResult;
        });
      });
    }
  }

  async function handleAddFiles(e: FileUploadSelectParams) {
    for (const file of e.files) {
      setFileStatus((prevState) => {
        const newStatus = new Map(prevState);
        newStatus.set(file.name, FileStatus.Pending);
        return newStatus;
      });
    }
  }

  function clearFiles() {
    setFileStatus(new Map());
    setFileResult(new Map());
  }

  function handleCancel() {
    clearFiles();
    setProgressBarValue(0);
    setShowModal(false);
  }

  function handleRemove(name: string) {
    setFileStatus((prevState) => {
      const newStatus = new Map(prevState);
      newStatus.delete(name);
      return newStatus;
    });

    setFileResult((prevState) => {
      const newResult = new Map(prevState);
      newResult.delete(name);
      return newResult;
    });
  }

  async function uploadResults() {
    setShowProcessingModal(true);
    setProgressBarValue(0);

    const results = Array.from(fileResult.values()).filter(
      (status) =>
        status.isPcrTubePresent &&
        (status.isRerun || status.pcrResult !== PcrResultType.Undefined)
    );

    for (let i = 0; i < results.length; i++) {
      const result = results[i];

      if (result.isRerun) {
        await rerunMutation.mutateAsync({ tubeIds: [result.tubeId] });
      } else {
        await setResultMutation.mutateAsync({
          tubeIds: [result.tubeId],
          pcrResultType: result.pcrResult,
          pcrSubresultType: result.pcrSubresult,
          precultureTubeId: result.precultureTubeId,
          comment: "",
        });
      }
      setProgressBarValue(((i + 1) / results.length) * 100);
    }

    queryClient.invalidateQueries("tubes");
    queryClient.invalidateQueries("pcrtotals");

    setShowProcessingModal(false);
    clearFiles();
    setProgressBarValue(0);
    setShowModal(false);
  }

  function resultLabel(result: UtiParsingResult) {
    if (result.parsingFailed) {
      return "Parsing failed";
    }
    if (!result.isPcrTubePresent) {
      return "PCR Sample not found";
    }
    if (result.isRerun) {
      return "Sample Re-exctraction";
    }
    if (result.pcrResult === PcrResultType.Neg) {
      return "PCR Negative";
    }
    if (result.pcrSubresult === PcrSubresult.Positive_Preculture4HourQueue) {
      return "Positive PCR, ABR, 4h incubation";
    }
    if (result.pcrSubresult === PcrSubresult.Positive_Preculture18HourQueue) {
      return "Positive PCR, ABR, 18h incubation";
    }
    if (result.pcrSubresult === PcrSubresult.Negative_PlateCultureQueue) {
      return "Positive PCR, No AST";
    }
    if (!result.isPrecultureTubePresent) {
      return "Micro Sample not found";
    }
    return "Unknown";
  }

  const fileTemplate = (file: FileUploadProps, props: any) => {
    const status = fileStatus.get(file.name!);
    const result = fileResult.get(file.name!);

    const failed = result?.parsingFailed;

    return (
      <div className="flex flex-row justify-content-between">
        <div
          className={classNames("flex align-items-center", {
            "text-red-500": failed,
          })}
        >
          {file.name}
        </div>
        <div className="flex flex-row align-items-center">
          {status === FileStatus.Processing && (
            <div>
              <ProgressSpinner
                style={{ width: "20px", height: "20px" }}
                strokeWidth="8"
              />
            </div>
          )}
          {status === FileStatus.Processed && !result?.isPrecultureTubePresent &&  (
            <span className="p-2 text-yellow-600">not present in preculture</span>
          )}
          {status === FileStatus.Processed && (
            <>
              <span
                className={classNames({
                  "text-red-500": failed,
                })}
              >
                {resultLabel(result!)}
              </span>
            </>
          )}
          <div className="ml-2">{props.removeElement}</div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div>
        <Button label="Import sample results" onClick={handleShowModal} />
      </div>
      <Dialog
        header="Import sample results"
        visible={showModal}
        onHide={handleCancel}
      >
        <FileUpload
          ref={fileUploadRef}
          multiple
          accept=".xlsm, .xlsx"
          customUpload
          uploadHandler={handleUpload}
          style={{ width: "800px" }}
          itemTemplate={fileTemplate}
          cancelLabel="Clear"
          onSelect={handleAddFiles}
          onClear={() => clearFiles()}
          onRemove={(e) => handleRemove(e.file.name)}
        />
        <h2>Import summary</h2>
        <div>
          <table className="w-full mb-2">
            <thead>
              <tr>
                <th>
                  Positive PCR, ABR,
                  <br />4 hour incubation
                </th>
                <th>
                  Positive PCR, ABR,
                  <br />
                  18 hour incubation
                </th>
                <th>
                  PCR
                  <br />
                  Negative
                </th>
                <th>
                  Positive PCR,
                  <br />
                  No AST
                </th>
                <th>
                  Sample
                  <br />
                  Re-extraction
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-center">
                  {
                    Array.from(fileResult.values()).filter(
                      (result) =>
                        result.pcrSubresult ===
                        PcrSubresult.Positive_Preculture4HourQueue
                    ).length
                  }
                </td>
                <td className="text-center">
                  {
                    Array.from(fileResult.values()).filter(
                      (result) =>
                        result.pcrSubresult ===
                        PcrSubresult.Positive_Preculture18HourQueue
                    ).length
                  }
                </td>
                <td className="text-center">
                  {
                    Array.from(fileResult.values()).filter(
                      (result) => result.pcrResult === PcrResultType.Neg
                    ).length
                  }
                </td>
                <td className="text-center">
                  {
                    Array.from(fileResult.values()).filter(
                      (result) =>
                        result.pcrResult === PcrResultType.Pos &&
                        result.pcrSubresult ===
                          PcrSubresult.Negative_PlateCultureQueue
                    ).length
                  }
                </td>
                <td className="text-center">
                  {
                    Array.from(fileResult.values()).filter(
                      (result) => result.isRerun && result.isPcrTubePresent
                    ).length
                  }
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="flex flex-row justify-content-end">
          <Button
            label="Confirm"
            className="mr-2 p-button-success"
            onClick={uploadResults}
          />
          <Button
            label="Cancel"
            className="p-button-warning"
            onClick={handleCancel}
          />
        </div>
        <Dialog
          header="Processing the results..."
          visible={showProcessingModal}
          onHide={() => setShowProcessingModal(false)}
          closable={false}
        >
          <div className="flex flex-column">
            <ProgressSpinner
              style={{ width: "64px", height: "64px" }}
              strokeWidth="3"
              className="mb-3"
            />
            <ProgressBar
              mode="determinate"
              value={progressBarValue}
              showValue={false}
            />
          </div>
        </Dialog>
      </Dialog>
    </>
  );
}
