import { Card } from "primereact/card";
import { useNavigate } from "react-router-dom";
import { authService } from "../services/auth.service";
import { Button } from "primereact/button";
import { UserType } from "../models/admin/user-type";

export function MyAccount() {
  const navigate = useNavigate();

  function executeNavigate(url: string) {
    navigate(url);
  }

  function logout() {
    authService.logout();
    navigate("/");
  }

  return (
    <>
      <div className="flex flex-column align-items-center">
        <Card className="flex align-items-center justify-content-center w-full">
          <div className="flex flex-column">
            <div className="flex justify-content-center">
              <h2>Welcome {authService.getLoggedUser()?.username}!</h2>
            </div>
            <div className="flex flex-row justify-content-evenly mt-3">
              <Button
                className="p-button-outlined w-8rem justify-content-center"
                onClick={() => logout()}
              >
                Logout
              </Button>
            </div>
          </div>
        </Card>

        <div className="flex flex-row flex-nowrap justify-content-center w-full">
          {authService.hasRole(UserType.Admin) && (
            <Card className="flex align-items-center justify-content-center m-3 w-full max-w-30rem">
              <div className="flex flex-column">
                <div className="flex justify-content-center">
                  <h3>Admin panel</h3>
                </div>
                <div className="flex flex-row justify-content-evenly mt-3">
                  <Button
                    className="p-button-outlined w-8rem justify-content-center"
                    onClick={() => executeNavigate("/admin")}
                  >
                    Navigate
                  </Button>
                </div>
              </div>
            </Card>
          )}
          {authService.hasRole(UserType.Micro) && (
            <Card className="flex align-items-center justify-content-center m-3 w-full max-w-30rem">
              <div className="flex flex-column">
                <div className="flex justify-content-center">
                  <h3>Micro flow</h3>
                </div>
                <div className="flex flex-row justify-content-evenly mt-3">
                  <Button
                    className="p-button-outlined w-8rem justify-content-center"
                    onClick={() => executeNavigate("/micro")}
                  >
                    Navigate
                  </Button>
                </div>
              </div>
            </Card>
          )}
          {authService.hasRole(UserType.Pcr) && (
            <Card className="flex align-items-center justify-content-center m-3 w-full max-w-30rem">
              <div className="flex flex-column">
                <div className="flex justify-content-center">
                  <h3>PCR flow</h3>
                </div>
                <div className="flex flex-row justify-content-evenly mt-3">
                  <Button
                    className="p-button-outlined w-8rem justify-content-center"
                    onClick={() => executeNavigate("/pcr")}
                  >
                    Navigate
                  </Button>
                </div>
              </div>
            </Card>
          )}
        </div>
      </div>
    </>
  );
}
