import { TabPanel, TabView } from "primereact/tabview";
import { TabAuditTrail } from "./TabAuditTrail";
import { TabDequeuedTubes } from "./TabDequeuedTubes";
import { TabMetaData } from "./TabMetaData";
import { TabPlateLayouts } from "./TabPlateLayouts";
import { TabTubeHistory } from "./TabTubeHistory";
import { TabTubeReruns } from "./TabTubeReruns";
import { TabUtiParsingResults } from "./TabUtiParsingResults";

export function PageAudit() {
  return (
    <TabView>
      <TabPanel header="Audit Trail">
        <TabAuditTrail />
      </TabPanel>
      <TabPanel header="Plate layouts">
        <TabPlateLayouts />
      </TabPanel>
      <TabPanel header="Tube history">
        <TabTubeHistory />
      </TabPanel>
      <TabPanel header="Metadata">
        <TabMetaData />
      </TabPanel>
      <TabPanel header="Reruns">
        <TabTubeReruns />
      </TabPanel>
      <TabPanel header="Dequeued">
        <TabDequeuedTubes />
      </TabPanel>
      <TabPanel header="Uti parsing results">
        <TabUtiParsingResults />
      </TabPanel>
    </TabView>
  );
}
