import {
  differenceInMilliseconds,
  formatDuration,
  intervalToDuration,
  isBefore,
} from "date-fns";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Card } from "primereact/card";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { useEffect, useState } from "react";
import { MicroTubeModel } from "../../models/micro/micro-tube";
import { ExtendedMetaData } from "../../queries/micro/models/meta-data";
import {
  getMilisecondsTimeInIncubator,
  groupBy as getGroupsOf,
  isMetaDataValid,
} from "../../utils/helpers";
import { CommentInput } from "./CommentInput";
import { ExtendedMetaDataInput } from "./ExtendedMetaDataInput";
import { IncubationType } from "../../queries/micro/models/incubation-type";

export interface IncubatorDataPanelProps {
  selectedTubes: MicroTubeModel[];
  allowBypass?: boolean;
  useSecondTime?: boolean;
  saveTubes: (v: IncubatorDataChanges) => void;
  completeTubes: (v: IncubatorDataChanges) => void;
}

export interface IncubatorDataChanges {
  timeIn: Date | undefined;
  timeOut: Date | undefined;
  secondTimeIn: Date | undefined;
  secondTimeOut: Date | undefined;
  metadata: ExtendedMetaData;
  noAstPerformed: boolean;
  comment: string;
}

export function IncubatorDataPanel({
  selectedTubes,
  allowBypass = false,
  useSecondTime = false,
  saveTubes,
  completeTubes,
}: IncubatorDataPanelProps) {
  const [incubationTypes, setIncubationTypes] =
    useState<{ type: IncubationType; count: number }[]>();
  const [timeIn, setTimeIn] = useState<Date | undefined>();
  const [timeOut, setTimeOut] = useState<Date | undefined>();
  const [secondTimeIn, setSecondTimeIn] = useState<Date | undefined>();
  const [secondTimeOut, setSecondTimeOut] = useState<Date | undefined>();
  const [bypassChecked, setBypassChecked] = useState(false);
  const [metadata, setMetadata] = useState({} as ExtendedMetaData);
  const [comment, setComment] = useState<string>("");
  const [elapsedTime, setElapsedTime] = useState(0);
  const [formatedDuration, setFormatedDuration] = useState<string>("Pending");

  useEffect(() => {
    // Set up the interval to trigger the event every second
    const timer = setInterval(() => {
      if (selectedTubes && selectedTubes.length > 0) {
        let totalMiliseconds = getMilisecondsTimeInIncubator(selectedTubes[0]);
        if (!totalMiliseconds) {
          setFormatedDuration("Pending");
        } else {
          let duration = intervalToDuration({
            start: 0,
            end: totalMiliseconds,
          });
          setFormatedDuration(formatDuration(duration));
        }
      }
      setElapsedTime((prevElapsedTime) => prevElapsedTime + 1);
    }, 1000);

    // Clean up the interval when the component is unmounted
    return () => {
      clearInterval(timer);
    };
  }, [timeIn, timeOut, secondTimeIn, secondTimeOut]);

  useEffect(() => {
    const first = selectedTubes[0];

    if (first) {
      setTimeIn(first.timeIn);
      setTimeOut(first.timeOut);
      setSecondTimeIn(first.secondTimeIn);
      setSecondTimeOut(first.secondTimeOut);
      setMetadata(first.metadata);
      setComment(first.comment);
    }
    setIncubationTypes(
      getGroupsOf(selectedTubes, "incubationType").map((x) => ({
        type: x[0].incubationType,
        count: x.length,
      }))
    );
  }, [selectedTubes]);

  useEffect(() => {
    if (bypassChecked) {
      setTimeIn(undefined);
      setTimeOut(undefined);
      metadata.equipments = metadata.equipments?.map((e) => {
        e.value = "";
        return e;
      });
      metadata.instruments = metadata.instruments?.map((i) => {
        i.value = "";
        return i;
      });
      metadata.reagentLots = metadata.reagentLots?.map((r) => {
        r.value = "";
        return r;
      });
      setMetadata({ ...metadata });
    }
  }, [bypassChecked, selectedTubes]);

  function isDataIncomplete() {
    return useSecondTime
      ? !bypassChecked &&
          (!timeIn ||
            !timeOut ||
            isBefore(timeOut, timeIn) ||
            (secondTimeIn !== null && !secondTimeOut) ||
            (!secondTimeIn && secondTimeOut !== null) ||
            (secondTimeIn &&
              secondTimeOut &&
              isBefore(secondTimeOut, secondTimeIn)) ||
            !isMetaDataValid(metadata))
      : !bypassChecked &&
          (!timeIn ||
            !timeOut ||
            isBefore(timeOut, timeIn) ||
            !isMetaDataValid(metadata));
  }
  function isSecondIncubationPresent(): boolean {
    return secondTimeIn !== undefined || secondTimeOut !== undefined;
  }

  return (
    <Card className="surface-100">
      <div className="flex flex-column align-items-center">
        {selectedTubes.map((t) => (
          <span key={t.id}>{t.barcode}</span>
        ))}
      </div>
      <div className="flex flex-row">
        <div className="col-4">
          <div className="surface-400 m-1 p-1 text-center">
            <div className="p-2">Total Time in Incubator:</div>
            <div className="bg-white m-2 p-2 text-center">
              {formatedDuration}
            </div>
          </div>
          {useSecondTime && (
            <div className="surface-400 m-1 p-1 text-center">              
            {incubationTypes?.map((x) => (
              <div key={x.type} className="bg-white m-2 p-2 text-center">
                {x.type === IncubationType.Preculture4Hour
                  ? "4-hour incubation "
                  : x.type === IncubationType.Preculture18Hour
                  ? "18-hour incubation "
                  : x.type === IncubationType.PosNoAst
                  ? "Positive PCR, NO AST "
                  : x.type === IncubationType.Negative
                  ? "Negative PCR "
                  : "Pending PCR Results "}
                  <span>
                    ({x.count})
                  </span>
              </div>
            ))}
            </div>
          )}
        </div>
        <div className="col-8">
          {allowBypass && (
            <div className="flex flex-row align-items-left justify-content-start mb-1">
              <label>No AST Performed</label>
              <Checkbox
                className="ml-2"
                checked={bypassChecked}
                onChange={(e) => setBypassChecked(e.checked)}
              />
            </div>
          )}
          <div className="flex flex-row align-items-center justify-content-end mb-1">
            <label className="mr-2">{useSecondTime ? "1st " : ""}Time in</label>
            <Calendar
              value={timeIn}
              onChange={(e) => setTimeIn(e.target.value as Date)}
              showTime
              showButtonBar
              hideOnDateTimeSelect
              disabled={
                useSecondTime && (bypassChecked || secondTimeIn !== null)
              }
            />
          </div>
          <div className="flex flex-row align-items-center justify-content-end mb-1">
            <label className="mr-2">
              {useSecondTime ? "1st " : ""}Time out
            </label>
            <Calendar
              value={timeOut}
              onChange={(e) => setTimeOut(e.target.value as Date)}
              showTime
              showButtonBar
              hideOnDateTimeSelect
              disabled={
                useSecondTime && (bypassChecked || secondTimeIn !== null)
              }
              className={classNames(
                timeOut && timeIn && isBefore(timeOut, timeIn)
                  ? "p-invalid"
                  : undefined
              )}
            />
          </div>

          {useSecondTime && (
            <div className="flex flex-row align-items-center justify-content-end mb-1">
              <label className="mr-2">2nd Time in</label>
              <Calendar
                value={secondTimeIn}
                onChange={(e) => setSecondTimeIn(e.target.value as Date)}
                showTime
                showButtonBar
                hideOnDateTimeSelect
                disabled={bypassChecked || !timeOut}
              />
            </div>
          )}

          {useSecondTime && (
            <div className="flex flex-row align-items-center justify-content-end mb-1">
              <label className="mr-2">2nd Time out</label>
              <Calendar
                value={secondTimeOut}
                onChange={(e) => setSecondTimeOut(e.target.value as Date)}
                showTime
                showButtonBar
                hideOnDateTimeSelect
                disabled={bypassChecked || !timeOut}
                className={classNames(
                  secondTimeOut &&
                    secondTimeIn &&
                    isBefore(secondTimeOut, secondTimeIn)
                    ? "p-invalid"
                    : undefined
                )}
              />
            </div>
          )}

          <ExtendedMetaDataInput
            metadata={metadata}
            onChange={setMetadata}
            disabled={bypassChecked}
          />
          <CommentInput comment={comment} onChange={setComment} />
        </div>
      </div>

      <div className="flex flex-row align-items-center justify-content-between mb-1">
        <Button
          label="Save"
          disabled={!isDataIncomplete() && !isSecondIncubationPresent()}
          className="p-button-outlined"
          onClick={() =>
            saveTubes({
              timeIn,
              timeOut,
              secondTimeIn,
              secondTimeOut,
              noAstPerformed: bypassChecked,
              metadata: metadata,
              comment: comment,
            })
          }
        />
        <Button
          label="Complete"
          disabled={isDataIncomplete()}
          onClick={() =>
            completeTubes({
              timeIn,
              timeOut,
              secondTimeIn,
              secondTimeOut,
              noAstPerformed: bypassChecked,
              metadata: metadata,
              comment: comment,
            })
          }
        />
      </div>
    </Card>
  );
}
