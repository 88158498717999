import { InputTextarea } from "primereact/inputtextarea";

export interface CommentInputProps {
  comment: string;
  disabled?: boolean;
  onChange: (v: string) => void;
}
export function CommentInput({
  comment,
  disabled = false,
  onChange,
}: CommentInputProps) {
  return (
    <div className="flex justify-content-center mt-2">
      <div>
        <div className="mb-2 font-bold">Comment</div>
        <div className="flex flex-column">
          <InputTextarea
            value={comment}
            disabled={disabled}
            className="w-30rem"
            onChange={(v) => onChange(v.target.value)}
            maxLength={500}
          />
        </div>
      </div>
    </div>
  );
}
