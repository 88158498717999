import { useEffect, useState } from "react";
import { InputNumber } from "primereact/inputnumber";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import {
  useConfigurationMutation,
  useConfigurationQuery,
} from "../../queries/admin/configuration.query";
import { ConfigurationModel } from "../../models/admin/configuration";
import { useQueryClient } from "react-query";
import { SuperBatchPlate } from "../../components/pcr/SuperBatchPlate";
import { PlateCoordinates } from "../../models/pcr/plate-coordinates";
import { SuperbatchModel } from "../../queries/pcr/models/super-batch";
import {
  createDummyAbrMinibatch,
  createDummySuperbatch,
  createDummyUtiMinibatch,
} from "../../utils/configuration-utils";
import { MinibatchModel } from "../../queries/pcr/models/mini-batch";
import { MiniBatchPlate } from "../../components/pcr/MiniBatchPlate";
import { TubeModel } from "../../queries/pcr/models/tube";
import { useToast } from "../../components/ui/ToastContext";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";

export function PageConfiguration() {
  const [maxSuperbatch, setMaxSuperbatch] = useState(93);
  const [haloSuffix, setHaloSuffix] = useState("FE");
  const [
    showSupplementalElutionBufferInMetaData,
    setShowSupplementalElutionBufferInMetaData,
  ] = useState(false);
  const [
    showSupplementalProteinaseInMetaData,
    setShowSupplementalProteinaseInMetaData,
  ] = useState(false);
  const [maxMinibatchUti, setMaxMinibatchUti] = useState(23);
  const [maxMinibatchAbr, setMaxMinibatchAbr] = useState(20);
  const [disabledDummySuperbatch, setDisabledDummySuperbatch] =
    useState<SuperbatchModel>();
  const [disabledSuperbatchWells, setDisabledSuperbatchWells] = useState<
    PlateCoordinates[]
  >([]);
  const [disabledDummyUtiMinibatch, setDisabledDummyUtiMinibatch] =
    useState<MinibatchModel>();
  const [disabledUtiMinibatchWells, setDisabledUtiMinibatchWells] = useState<
    PlateCoordinates[]
  >([]);
  const [disabledUtiMinibatchIds, setDisabledUtiMinibatchIds] = useState<
    number[]
  >([]);
  const [disabledDummyAbrMinibatch, setDisabledDummyAbrMinibatch] =
    useState<MinibatchModel>();
  const [disabledAbrMinibatchWells, setDisabledAbrMinibatchWells] = useState<
    PlateCoordinates[]
  >([]);
  const [disabledAbrMinibatchIds, setDisabledAbrMinibatchIds] = useState<
    number[]
  >([]);

  const { isLoading, data } = useConfigurationQuery();
  const configurationMutation = useConfigurationMutation();
  const queryClient = useQueryClient();
  const toast = useToast();

  const fixedDisabledSbWells: PlateCoordinates[] = [
    { row: "H", column: "4" },
    { row: "H", column: "8" },
    { row: "H", column: "12" },
  ]
    ;

  function saveConfiguration() {
    configurationMutation.mutate(
      {
        superbatchSize: maxSuperbatch,
        minibatchUtiSize: maxMinibatchUti,
        minibatchAbrSize: maxMinibatchAbr,
        haloSuffix: haloSuffix,
        showSupplementalElutionBufferInMetaData:
          showSupplementalElutionBufferInMetaData,
        showSupplementalProteinaseInMetaData:
          showSupplementalProteinaseInMetaData,
        disabledSuperbatchWells: disabledSuperbatchWells,
        disabledUtiMinibatchWells: disabledUtiMinibatchWells,
        disabledAbrMinibatchWells: disabledAbrMinibatchWells,
      } as ConfigurationModel,
      {
        onSuccess: () => {
          toast.current!.show({
            detail: "Saved",
            severity: "success",
          });
          queryClient.invalidateQueries("configuration");
        },
      }
    );
  }

  useEffect(() => {
    if (data) {
      setMaxSuperbatch(data.superbatchSize);
      setMaxMinibatchUti(data.minibatchUtiSize);
      setMaxMinibatchAbr(data.minibatchAbrSize);
      setHaloSuffix(data.haloSuffix);
      setShowSupplementalElutionBufferInMetaData(
        data.showSupplementalElutionBufferInMetaData
      );
      setShowSupplementalProteinaseInMetaData(
        data.showSupplementalProteinaseInMetaData
      );

      let dummySb = createDummySuperbatch(data.minibatchUtiSize);
      let dummyAbr = createDummyAbrMinibatch(data.minibatchAbrSize);

      setDisabledDummySuperbatch(dummySb);
      setDisabledDummyUtiMinibatch(
        createDummyUtiMinibatch(dummySb?.items!, data.minibatchUtiSize)
      );

      setDisabledSuperbatchWells(data.disabledSuperbatchWells ?? []);

      setDisabledDummyAbrMinibatch(dummyAbr);
      setDisabledAbrMinibatchWells(data.disabledAbrMinibatchWells ?? []);

      let disabledAbrIds = dummyAbr?.items
        .filter((x) =>
          data.disabledAbrMinibatchWells?.some(
            (y) => x.column === y.column && x.row === y.row
          )
        )
        .map((x) => x.tubeId);

      setDisabledAbrMinibatchIds([...disabledAbrIds!]);
    }
  }, [data]);

  useEffect(() => {
    if (disabledDummySuperbatch) {
      let disabledIds = disabledDummySuperbatch?.items
        .filter((x) =>
          disabledSuperbatchWells.some(
            (y) => x.column === y.column && x.row === y.row
          )
        )
        .map((x) => x.tubeId);

      setDisabledUtiMinibatchIds([...disabledIds!]);
    }
  }, [disabledSuperbatchWells]);

  function toggledSuperbatchWell(c: PlateCoordinates): void {
    let fixedDisabled = fixedDisabledSbWells.find(
      (x) => x.row === c.row && x.column === c.column
    );

    if(fixedDisabled){
      return;
    }

    let existing = disabledSuperbatchWells.find(
      (x) => x.row === c.row && x.column === c.column
    );
    if (existing != null) {
      let tempArray = disabledSuperbatchWells;
      tempArray.splice(tempArray.indexOf(existing), 1);
      setDisabledSuperbatchWells([...tempArray]);
    } else {
      let tempArray = disabledSuperbatchWells;
      tempArray.push(c);
      setDisabledSuperbatchWells([...tempArray]);
    }
  }

  // Do not remove this function yet, maybe needed one day
  function toggledUtiMinibatchWell(coords: PlateCoordinates): void {
    let clickedTube = disabledDummyUtiMinibatch?.items.find(
      (x) => x.row === coords.row && x.column === coords.column
    );
    let superbatchWellCoordinates = disabledDummySuperbatch?.items
      .filter((x) => x.tubeId === clickedTube?.tubeId)
      .map(
        (x) =>
          ({
            row: x.row,
            column: x.column,
          } as PlateCoordinates)
      );

    let tempArray = disabledSuperbatchWells;

    let wasSelected = disabledUtiMinibatchIds.some(
      (x) => x === clickedTube?.tubeId
    );

    superbatchWellCoordinates?.forEach((sw) => {
      let existing = disabledSuperbatchWells.find(
        (x) => x.row === sw.row && x.column === sw.column
      );

      if (!existing && !wasSelected) {
        tempArray.push(sw);
      } else if (existing) {
        tempArray.splice(tempArray.indexOf(existing), 1);
      }
    });

    setDisabledSuperbatchWells([...(tempArray ?? disabledSuperbatchWells)]);
  }

  function toggledAbrMinibatchWell(coords: PlateCoordinates): void {
    let clickedTube = disabledDummyAbrMinibatch?.items.find(
      (x) => x.row === coords.row && x.column === coords.column
    );
    let minibatchWellCoordinates = disabledDummyAbrMinibatch?.items
      .filter((x) => x.tubeId === clickedTube?.tubeId)
      .map(
        (x) =>
          ({
            row: x.row,
            column: x.column,
          } as PlateCoordinates)
      );

    let tempArray = disabledAbrMinibatchWells;

    let wasSelected = disabledAbrMinibatchIds.some(
      (x) => x === clickedTube?.tubeId
    );

    minibatchWellCoordinates?.forEach((sw) => {
      let existing = disabledAbrMinibatchWells.find(
        (x) => x.row === sw.row && x.column === sw.column
      );

      if (!existing && !wasSelected) {
        tempArray.push(sw);
      } else if (existing) {
        tempArray.splice(tempArray.indexOf(existing), 1);
      }
    });

    setDisabledAbrMinibatchWells([...(tempArray ?? disabledAbrMinibatchWells)]);

    let disabledIds = disabledDummyAbrMinibatch?.items
      .filter((x) =>
        disabledAbrMinibatchWells.some(
          (y) => x.column === y.column && x.row === y.row
        )
      )
      .map((x) => x.tubeId);

    setDisabledAbrMinibatchIds([...disabledIds!]);
  }

  return (
    <>
      {!isLoading && data && (
        <div>
          <div className="grid p-fluid">
            <div className="field col-3">
              <label>Maximum superbatch size</label>
              <InputNumber
                value={maxSuperbatch}
                onValueChange={(n) => setMaxSuperbatch(n.value ?? 0)}
                showButtons
                min={1}
                max={93}
                step={1}
              />
            </div>
            <div className="field col-3">
              <label>Maximum minibatch ABR size</label>
              <InputNumber
                value={maxMinibatchAbr}
                onValueChange={(n) => setMaxMinibatchAbr(n.value ?? 0)}
                showButtons
                min={1}
                max={22}
                step={1}
              />
            </div>
            <div className="field col-3">
              <label>Maximum minibatch UTI size</label>
              <InputNumber
                value={maxMinibatchUti}
                onValueChange={(n) => setMaxMinibatchUti(n.value ?? 0)}
                showButtons
                min={1}
                max={31}
                step={1}
              />
            </div>
            <div className="field col-3">
              <label>Halo tube suffix</label>
              <InputText
                value={haloSuffix}
                onChange={(v) => setHaloSuffix(v.target.value)}
              />
            </div>

            <div className="field col-3">
              <div className="flex flex-row align-items-center">
                <InputSwitch
                  checked={showSupplementalElutionBufferInMetaData}
                  onChange={(e) =>
                    setShowSupplementalElutionBufferInMetaData(e.value)
                  }
                />
                <span className="ml-2">Show supplemental elution buffer</span>
              </div>
            </div>

            <div className="field col-3">
              <div className="flex flex-row align-items-center">
                <InputSwitch
                  checked={showSupplementalProteinaseInMetaData}
                  onChange={(e) =>
                    setShowSupplementalProteinaseInMetaData(e.value)
                  }
                />
                <span className="ml-2">Show supplemental proteinase</span>
              </div>
            </div>
          </div>

          <div>
            <h3>SuperBatch disabled wells</h3>
            <SuperBatchPlate
              superbatchItems={disabledDummySuperbatch?.items}
              highlightColorClassName={"bg-red-400"}
              highlightedWells={disabledSuperbatchWells}
              onHoleClick={(c) => toggledSuperbatchWell(c)}
            />
          </div>
          {disabledDummyUtiMinibatch && (
            <div className="grid">
              <div className="col-6">
                <h3>UTI Miniplate disabled wells</h3>
                <MiniBatchPlate
                  highlightColorClassName={"bg-red-400"}
                  miniBatch={disabledDummyUtiMinibatch!}
                  selectedIds={disabledUtiMinibatchIds}
                />
              </div>
              <div className="col-6">
                <h3>ABR Miniplate disabled wells</h3>
                <MiniBatchPlate
                  highlightColorClassName={"bg-red-400"}
                  onHoleClick={toggledAbrMinibatchWell}
                  miniBatch={disabledDummyAbrMinibatch!}
                  selectedIds={disabledAbrMinibatchIds}
                />
              </div>
            </div>
          )}
          <div>
            <Button label="Save" onClick={saveConfiguration} />
          </div>
        </div>
      )}
    </>
  );
}
