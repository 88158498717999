import { MinibatchModel } from "../queries/pcr/models/mini-batch";
import { MinibatchItemModel } from "../queries/pcr/models/mini-batch-item";
import { MiniBatchState } from "../queries/pcr/models/mini-batch-state";
import { PcrSubresult } from "../queries/pcr/models/pcr-subresult";
import { SuperbatchModel } from "../queries/pcr/models/super-batch";
import { SuperbatchItemModel } from "../queries/pcr/models/super-batch-item";
import { SuperBatchItemState } from "../queries/pcr/models/super-batch-item-state";
import { SuperBatchState } from "../queries/pcr/models/super-batch-state";
import { TubeResult } from "../queries/pcr/models/tube-result";
import { TubeState } from "../queries/pcr/models/tube-state";
import { TubeType } from "../queries/pcr/models/tube-type";

export const SB_ROWS = ["A", "B", "C", "D", "E", "F", "G", "H"];
export const MB_ROWS = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
];

export function createDummySuperbatch(utiSize: number): SuperbatchModel {
  let items: SuperbatchItemModel[] = [];
  let i = 0;
  utiSize = 32;
  for (let c = 1; c < 13; c++) {
    for (let r = 0; r < 8; r++) {
      items.push({
        id: i % utiSize,
        state: SuperBatchItemState.Unassigned,
        superbatchId: 0,
        tubeId: i % utiSize,
        column: c.toString(),
        row: SB_ROWS[r],
        tube: {
          barcode: SB_ROWS[r] + c.toString(),
          createdAt: new Date(),
          id: i % utiSize,
          isRerun: false,
          pcrResult: TubeResult.Negative,
          pcrSubresult: PcrSubresult.NotApplicable,
          state: TubeState.InSuperbatch,
          type: TubeType.Regular,
        },
      });
      i++;
    }
  }

  return {
    items: items,
    dateCreated: new Date(),
    dateModified: new Date(),
    id: 0,
    metadata: {},
    plateBarcode: "",
    reference: "",
    state: SuperBatchState.Undefined,
    miniBatches: [],
    comment: "",
  };
}

export function createDummyUtiMinibatch(
  sbItems: SuperbatchItemModel[],
  utiSize: number
): MinibatchModel {
  let items: MinibatchItemModel[] = [];

  let row = MB_ROWS[0];
  let colSection = 1;

  for (let i = 0; i < utiSize; i++) {
    let sbItem = sbItems[i];

    for (let colI = 0; colI < 12; colI++) {
      items.push({
        id: 0,
        minibatchId: 0,
        tubeId: sbItem.tubeId,
        row: row,
        column: (colSection + colI).toString(),
        tube: sbItem.tube,
      });
    }

    if (row === MB_ROWS[MB_ROWS.length - 1]) {
      row = MB_ROWS[0];
      colSection = 13;
    } else {
      row = MB_ROWS[(i + 1) % MB_ROWS.length];
    }
  }

  return {
    items: items,
    id: 0,
    isExported: false,
    metadata: {},
    plateBarcode: "",
    state: MiniBatchState.Created,
    comment: "",
  };
}

export function createDummyAbrMinibatch(size: number): MinibatchModel {
  let items: MinibatchItemModel[] = [];

  let row = MB_ROWS[0];
  let colSection = 1;

  for (let i = 0; i < size; i++) {
    for (let colI = 0; colI < 24; colI++) {
      items.push({
        id: 0,
        minibatchId: 0,
        tubeId: i,
        row: row,
        column: (colSection + colI).toString(),
        tube: {
          barcode: i.toString(),
          createdAt: new Date(),
          id: i,
          isRerun: false,
          pcrResult: TubeResult.Negative,
          pcrSubresult: PcrSubresult.NotApplicable,
          state: TubeState.InSuperbatch,
          type: TubeType.Regular,
        },
      });
    }

    row = MB_ROWS[(i + 1) % MB_ROWS.length];
  }

  return {
    items: items,
    id: 0,
    isExported: false,
    metadata: {},
    plateBarcode: "",
    state: MiniBatchState.Created,
    comment: "",
  };
}
