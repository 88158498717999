import { intervalToDuration, isEqual } from "date-fns";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { useMemo, useState, useEffect } from "react";
import { useQueryClient } from "react-query";
import { MicroTubeModel } from "../../models/micro/micro-tube";
import { ExtendedMetaData } from "../../queries/micro/models/meta-data";
import {
  useRemoveTubeMutation,
  useTubesNoAstQuery,
} from "../../queries/micro/tubes.query";
import {
  getMilisecondsTimeInIncubator,
  isMetaDataEmpty,
  isMetaDataValid,
} from "../../utils/helpers";
import { RemoveTubeButton } from "../ui/RemoveTubeButton";
import { useToast } from "../ui/ToastContext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { IncubationType } from "../../queries/micro/models/incubation-type";

export interface CultureTubesSelectorProps {
  tubes: MicroTubeModel[];
  selectedTubes: MicroTubeModel[];
  selectedIds: number[];
  showNoAstWarning?: boolean;
  useIncubationType?: boolean;
  onChangeSelection: (v: number[]) => void;
}

export function CultureTubesSelector({
  tubes,
  selectedTubes,
  selectedIds,
  showNoAstWarning = false,
  useIncubationType = false,
  onChangeSelection,
}: CultureTubesSelectorProps) {
  const removeTubeMutation = useRemoveTubeMutation();
  const queryClient = useQueryClient();
  const toast = useToast();

  const [matchingCount, setMatchingCount] = useState<number>(0);
  const [matchingIncubationCount, setMatchingIncubationCount] =
    useState<number>(0);
  const [tubesBarcodes, setTubesBarcodes] = useState<string[]>([]);
  const noAstQuery = useTubesNoAstQuery(tubesBarcodes, showNoAstWarning);

  useEffect(() => {
    if (tubes) {
      setTubesBarcodes(tubes.map((t) => t.barcode));
    }
    if (selectedTubes && selectedTubes.length > 0) {
      const first = selectedTubes[0];
      setMatchingCount(
        tubes.filter((t) => isTubeInSameGroup(t, first)).map((t) => t.id).length
      );
      setMatchingIncubationCount(
        tubes
          .filter((t) => isTubeInSameIncubationGroup(t, first))
          .map((t) => t.id).length
      );
    } else {
      setMatchingCount(0);
    }

    const incubationTypes: IncubationType[] = [];
    selectedTubes.forEach((x) => {
      if (!incubationTypes.find((t) => x.incubationType === t)) {
        incubationTypes.push(x.incubationType);
      }
    });

    if (incubationTypes.length > 1) {
      setMatchingIncubationCount(0);
    }
  }, [tubes, selectedTubes]);

  const noAstBarcodes = useMemo(() => {
    return noAstQuery.data ?? [];
  }, [noAstQuery.data]);

  function isNoAst(barcode: string): boolean {
    return noAstBarcodes.some((b) => b === barcode);
  }

  function selectAll() {
    const first = selectedTubes[0];

    onChangeSelection(
      tubes.filter((t) => isTubeInSameGroup(t, first)).map((t) => t.id)
    );
  }

  function selectAllIncubation() {
    const first = selectedTubes[0];

    onChangeSelection(
      tubes
        .filter((t) => isTubeInSameIncubationGroup(t, first))
        .map((t) => t.id)
    );
  }

  function deselectAll() {
    onChangeSelection([]);
  }

  function isTubeInSameGroup(t1: MicroTubeModel, t2: MicroTubeModel) {
    return (
      ((!t1.timeIn && !t2.timeIn) || isEqual(t1.timeIn!, t2.timeIn!)) &&
      ((!t1.timeOut && !t2.timeOut) || isEqual(t1.timeOut!, t2.timeOut!)) &&
      ((!t1.secondTimeIn && !t2.secondTimeIn) ||
        isEqual(t1.secondTimeIn!, t2.secondTimeIn!)) &&
      ((!t1.secondTimeOut && !t2.secondTimeOut) ||
        isEqual(t1.secondTimeOut!, t2.secondTimeOut!)) &&
      isMetaDataEqual(t1.metadata, t2.metadata)
    );
  }

  function isTubeInSameIncubationGroup(t1: MicroTubeModel, t2: MicroTubeModel) {
    return (
      ((!t1.timeIn && !t2.timeIn) || isEqual(t1.timeIn!, t2.timeIn!)) &&
      ((!t1.timeOut && !t2.timeOut) || isEqual(t1.timeOut!, t2.timeOut!)) &&
      ((!t1.secondTimeIn && !t2.secondTimeIn) ||
        isEqual(t1.secondTimeIn!, t2.secondTimeIn!)) &&
      ((!t1.secondTimeOut && !t2.secondTimeOut) ||
        isEqual(t1.secondTimeOut!, t2.secondTimeOut!)) &&
      isEqual(t1.incubationType, t2.incubationType) &&
      isMetaDataEqual(t1.metadata, t2.metadata)
    );
  }

  function isMetaDataEqual(
    m1: ExtendedMetaData | null,
    m2: ExtendedMetaData | null
  ) {
    if (m1 === null && m2 === null) {
      return true;
    }

    return JSON.stringify(m1) === JSON.stringify(m2);
  }

  function isTubeSelectable(tube: MicroTubeModel) {
    if (selectedTubes.length === 0) return true;

    const first = selectedTubes[0];

    return isTubeInSameGroup(tube, first);
  }

  function toggleTubeWithRelations(tubeId: number, checked: boolean) {
    const relatedIds = getRelatedIds(tubeId);
    if (checked) {
      if (relatedIds.length > 0) {
        onChangeSelection([...relatedIds]);
      } else {
        selectedIds.push(tubeId);
        onChangeSelection([...selectedIds]);
      }
    } else {
      const index = selectedIds.indexOf(tubeId);
      selectedIds.splice(index, 1);
      onChangeSelection([...selectedIds]);
    }
  }

  function toggleTube(tubeId: number, checked: boolean) {
    if (checked) {
      selectedIds.push(tubeId);
      onChangeSelection([...selectedIds]);
    } else {
      const index = selectedIds.indexOf(tubeId);
      selectedIds.splice(index, 1);
      onChangeSelection([...selectedIds]);
    }
  }

  function getRelatedIds(tubeId: number): number[] {
    const tube = tubes.find((t) => t.id === tubeId)!;

    if (!tube.timeIn && !tube.timeOut && isMetaDataEmpty(tube.metadata)) {
      return [];
    }

    return tubes.filter((t) => isTubeInSameGroup(t, tube)).map((t) => t.id);
  }

  function removeTube(tubeId: number, comment: string) {
    removeTubeMutation.mutate(
      {
        tubeId: tubeId,
        comment: comment,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries("superBatches");
          queryClient.invalidateQueries("tubes");
          toast.current!.show({
            detail: "Tube removed",
            severity: "success",
          });
        },
        onError: (error) => console.error(error),
      }
    );
  }

  const tubeCheckboxTemplate = (t: MicroTubeModel) => {
    return (
      <div>
        <Checkbox
          checked={selectedIds.indexOf(t.id) !== -1}
          onChange={(e) => toggleTube(t.id, e.checked)}
          disabled={!isTubeSelectable(t)}
          inputId={"checkbox-" + t.id}
        />
        <label
          htmlFor={"checkbox-" + t.id}
          className={"cursor-pointer p-2" + (t.selectable ? "" : "p-disabled")}
        >
          {t.barcode}
        </label>
        {isNoAst(t.barcode) && (
          <span
            className="pi pi-exclamation-triangle text-orange-400 ml-2"
            title="No AST performed"
          />
        )}
      </div>
    );
  };

  const inIncubatorStringTemplate = (t: MicroTubeModel) => {
    let duration = intervalToDuration({
      start: 0,
      end: getMilisecondsTimeInIncubator(t) ?? 0,
    });
    let formated = "";
    if (duration.days) {
      formated += `${duration.days} days `;
    }
    if (duration.hours) {
      formated += `${duration.hours} hrs `;
    }
    if (duration.minutes) {
      formated += `${duration.minutes} mins `;
    }
    if (duration.seconds) {
      formated += `${duration.seconds} sec `;
    }
    return <div>{formated}</div>;
  };

  const incubationTimeTemplate = (t: MicroTubeModel) => {
    return (
      <div>
        {t.incubationType === IncubationType.Preculture4Hour
          ? "4-hour"
          : t.incubationType === IncubationType.Preculture18Hour
          ? "18-hour"
          : t.incubationType === IncubationType.PosNoAst
          ? "Positive PCR, NO AST"
          : t.incubationType === IncubationType.Negative
          ? "Negative PCR"
          : "Pending"}
      </div>
    );
  };

  const removeTubeTemplate = (t: MicroTubeModel) => {
    return (
      <div className="text-right">
        <RemoveTubeButton tube={t} iconOnly onConfirmRemove={removeTube} />
      </div>
    );
  };

  return (
    <div className="flex flex-column ">
      <div>
        <Button
          label={
            matchingCount > 0
              ? `Select all matching (${matchingCount})`
              : "Select all matching"
          }
          className="p-button-outlined p-button-sm mb-2"
          onClick={() => selectAll()}
          disabled={selectedIds.length === 0}
        />
        {useIncubationType && (
        <Button
          label={
            matchingIncubationCount > 0
              ? `Select all matching incubation (${matchingIncubationCount})`
              : "Select all matching incubation"
          }
          className="p-button-outlined p-button-sm mb-2"
          onClick={() => selectAllIncubation()}
          disabled={matchingIncubationCount === 0}
        />
        )}
        <Button
          label={
            selectedTubes.length > 0
              ? `Deselect all (${selectedTubes.length})`
              : "Deselect all"
          }
          className="p-button-outlined p-button-sm mb-2 ml-2"
          onClick={() => deselectAll()}
          disabled={selectedIds.length === 0}
        />
      </div>

      <DataTable
        filterDisplay="menu"
        value={tubes}
        scrollable
        scrollHeight="calc(100vh - 250px)"
        globalFilterFields={["tubeId", "action", "user", "timestamp"]}
      >
        <Column
          style={{ width: "30%" }}
          field="barcode"
          header="Barcode"
          body={tubeCheckboxTemplate}
          sortable
        />
        <Column
          style={{ width: "30%" }}
          field="inIncubatorMiliseconds"
          header="Total Time in Incubator"
          body={inIncubatorStringTemplate}
          sortable
        />

        {useIncubationType && (
          <Column
            style={{ width: "10%" }}
            field="incubationType"
            header="Assigned Incubation Time"
            body={incubationTimeTemplate}
            sortable
          />
        )}
        <Column
          style={{ width: "10%" }}
          field="id"
          header="Scanned order"
          sortable
        />
        <Column
          style={{ width: "10%" }}
          field="id"
          header=""
          body={removeTubeTemplate}
        />
      </DataTable>
    </div>
  );
}
