import { useState } from "react";
import { useQueryClient } from "react-query";
import {
  useBatchForAbrMutation,
  useSuperBatchesWithTubesQuery,
} from "../../queries/pcr/superBatches.query";
import { SuperBatchState } from "../../queries/pcr/models/super-batch-state";
import { useToast } from "../../components/ui/ToastContext";
import { SuperbatchMultiSelector } from "../../components/pcr/SuperbatchMultiSelector";
import { Button } from "primereact/button";

export function PagePcrAbrBatching() {
  const [selectedSuperBatchIds, setSelectedSuperBatchIds] = useState<number[]>(
    []
  );

  const superBatchesQuery = useSuperBatchesWithTubesQuery(
    SuperBatchState.PendingAbr,
    SuperBatchState.ReadyForAbr
  );

  const batchMutation = useBatchForAbrMutation();

  const toast = useToast();
  const queryClient = useQueryClient();

  function handleSuperbatchesSelected(ids: number[]) {
    ids = ids.filter(selectedId => superBatchesQuery.data?.find(x => x.id === selectedId && x.state === SuperBatchState.ReadyForAbr))
    setSelectedSuperBatchIds([...ids]);
  }

  function batch() {
    batchMutation.mutate(
      {
        ids: selectedSuperBatchIds,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries("superBatches");
          queryClient.invalidateQueries("minibatches");
          queryClient.invalidateQueries("pcrtotals");
          setSelectedSuperBatchIds([]);
          toast.current!.show({
            detail: "ABR batch created",
            severity: "success",
          });
        },
        onError: (error) => console.error(error),
      }
    );
  }

  return (
    <>
      {!superBatchesQuery.isLoading && superBatchesQuery.data && (
        <div>
          <SuperbatchMultiSelector
            batches={superBatchesQuery.data}
            selectedBatchIds={selectedSuperBatchIds}
            header="SuperBatch ID"
            showStatusColumn
            showPlateIdColumn
            statusSelector={(v) => SuperBatchState[v.state]}
            onSelect={handleSuperbatchesSelected}
          />
          <div className="flex justify-content-end">
            <Button
              className="p-button"
              disabled={selectedSuperBatchIds.length === 0}
              label="Merge selected SuperBatches into ABR plates"
              onClick={batch}
            />
          </div>
        </div>
      )}
    </>
  );
}
