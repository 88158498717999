import { useState } from "react";
import { SuperbatchSelector } from "../../components/pcr/SuperbatchSelector";
import { SuperBatchState } from "../../queries/pcr/models/super-batch-state";
import { useSuperBatchesQuery } from "../../queries/pcr/superBatches.query";
import { SuperBatchEditor } from "../../components/pcr/SuperBatchEditor";
import { useConfigurationQuery } from "../../queries/admin/configuration.query";

export function PagePcrSuperBatchQueue() {
  const [selectedSuperBatchId, setSelectedSuperBatchId] = useState<number>();

  const superBatchesQuery = useSuperBatchesQuery(SuperBatchState.Created);
  const configurationQuery = useConfigurationQuery();

  return (
    <>
      {!superBatchesQuery.isLoading &&
        superBatchesQuery.data &&
        !selectedSuperBatchId && (
          <SuperbatchSelector
            batches={superBatchesQuery.data}
            header="SuperBatch ID"
            showPlateIdColumn={true}
            onSelect={(v) => setSelectedSuperBatchId(v.id)}
          />
        )}
      {selectedSuperBatchId && (
        <SuperBatchEditor
          id={selectedSuperBatchId}
          configuration={configurationQuery.data!}
          onComplete={() => setSelectedSuperBatchId(undefined)}
        />
      )}
    </>
  );
}
