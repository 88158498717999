import { useEffect, useState } from "react";
import { MicroTubeModel } from "../../models/micro/micro-tube";
import {
  useTubesQuery,
  useUpdatePrecultureTubesMutation,
} from "../../queries/micro/tubes.query";
import { TubeState } from "../../queries/micro/models/tube-state";
import { UpdateCultureTubesModel } from "../../queries/micro/models/update-culture-tubes";
import { useQueryClient } from "react-query";
import {
  IncubatorDataChanges,
  IncubatorDataPanel,
} from "../../components/micro/IncubatorDataPanel";
import { CultureTubesSelector } from "../../components/micro/CultureTubesSelector";
import {
  ExtendedMetaData,
  ExtendedMetaDataItem,
} from "../../queries/micro/models/meta-data";
import { getMilisecondsTimeInIncubator, getMilisecondsTimeInIncubatorAlternative } from "../../utils/helpers";

export function PageMicroPreculture() {
  const [tubes, setTubes] = useState<MicroTubeModel[]>([]);
  const [selectedTubes, setSelectedTubes] = useState<MicroTubeModel[]>([]);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);

  const { isLoading, data, dataUpdatedAt } = useTubesQuery(
    TubeState.PendingPreculture
  );
  const tubesMutation = useUpdatePrecultureTubesMutation();

  const queryClient = useQueryClient();

  useEffect(() => {
    const tubeIds = tubes.map((t) => t.id);

    const validSelectedIds = selectedIds.filter((id) =>
      Boolean(tubeIds.find((tid) => tid === id))
    );

    if (validSelectedIds.length !== selectedIds.length) {
      setSelectedIds(validSelectedIds);
    }
  }, [tubes, selectedIds]);

  useEffect(() => {
    setSelectedTubes(tubes.filter((t) => selectedIds.find((i) => i === t.id)));
  }, [selectedIds, tubes]);

  useEffect(() => {
    if (!isLoading && data) {
      setTubes([
        ...data.map((d) => {
          return {
            id: d.id,
            barcode: d.barcode,
            selectable: true,
            timeIn: d.currentStepTimeIn,
            timeOut: d.currentStepTimeOut,
            secondTimeIn: d.currentStepSecondTimeIn,
            secondTimeOut: d.currentStepSecondTimeOut,
            incubationType: d.incubationType,
            inIncubatorMiliseconds: getMilisecondsTimeInIncubatorAlternative(d),
            metadata: ensureMetaData(d.currentStepMetaData),
            comment: d.currentStepComment ?? "",
          } as MicroTubeModel;
        }),
      ]);
    }
  }, [isLoading, data, dataUpdatedAt]);

  function ensureMetaData(metadata: ExtendedMetaData | null): ExtendedMetaData {
    if (!metadata) {
      return {
        reagentLots: [
          {
            label: "Blood Agar Plates (BAP) Lot No.",
            value: "",
            required: true,
          },
          {
            label: "Trypticase Soy Broth (TSB) Lot No.",
            value: "",
            required: true,
          },
        ],
        equipments: [
          {
            label: "Incubator",
            value: "",
            required: true,
          },
          {
            label: "P1000",
            value: "",
            required: true,
          },
          {
            label: "Vortex",
            value: "",
            required: true,
          },
        ],
      } as ExtendedMetaData;
    } else {
      return metadata;
    }
  }

  function saveTubes(data: IncubatorDataChanges) {
    const model = {
      completed: false,
      tubeIds: selectedTubes.map((t) => t.id),
      timeIn: data.timeIn,
      timeOut: data.timeOut,
      timeSecondIn: data.secondTimeIn,
      timeSecondOut: data.secondTimeOut,
      metadata: data.metadata,
      comment: data.comment,
    } as UpdateCultureTubesModel;

    tubesMutation.mutate(model, {
      onSuccess: () => {
        queryClient.invalidateQueries("tubes");
        queryClient.invalidateQueries("micrototals");
        setSelectedIds([]);
      },
      onError: (error) => console.error(error),
    });
  }

  function completeTubes(data: IncubatorDataChanges) {
    const model = {
      completed: true,
      tubeIds: selectedTubes.map((t) => t.id),
      timeIn: data.timeIn,
      timeOut: data.timeOut,
      timeSecondIn: data.secondTimeIn,
      timeSecondOut: data.secondTimeOut,
      metadata: data.metadata,
      comment: data.comment,
    } as UpdateCultureTubesModel;

    tubesMutation.mutate(model, {
      onSuccess: () => {
        queryClient.invalidateQueries("tubes");
        queryClient.invalidateQueries("micrototals");
      },
      onError: (error) => console.error(error),
    });
  }

  return (
    <div className="flex">
      <div className="col-6">
        <h2>Preculture Queue</h2>

        <div className="flex flex-column ">
          <CultureTubesSelector
            tubes={tubes}
            selectedTubes={selectedTubes}
            selectedIds={selectedIds}
            useIncubationType={true}
            onChangeSelection={setSelectedIds}
          />
        </div>
      </div>
      <div className="col-6">
        {selectedTubes.length > 0 && (
          <IncubatorDataPanel
            selectedTubes={selectedTubes}
            saveTubes={saveTubes}
            completeTubes={completeTubes}
            useSecondTime={true}
          />
        )}
      </div>
    </div>
  );
}
