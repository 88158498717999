/* eslint-disable react-hooks/rules-of-hooks */
import { useMutation, useQuery } from "react-query";
import { LoginRequest } from "./models/login-request";
import { authService } from "../../services/auth.service";


export function useLoginMutation() {
  return useMutation((model: LoginRequest) =>
    authService.login(model)
  );
}
