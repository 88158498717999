import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputSwitch } from "primereact/inputswitch";
import { Checkbox } from "primereact/checkbox";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { PendingResultSelector } from "../../components/pcr/PendingResultSelector";
import { useToast } from "../../components/ui/ToastContext";
import { PcrResultType } from "../../queries/pcr/models/pcr-result-type";
import { TubeModel } from "../../queries/pcr/models/tube";
import { TubeState } from "../../queries/pcr/models/tube-state";
import {
  useAssignResultMutation,
  useRerunTubesMutation,
  useTubesQuery,
} from "../../queries/pcr/tubes.query";
import { ResultFilesImporter } from "../../components/pcr/ResultFilesImporter";

export function PagePcrPendingResults() {
  const [selectedTubes, setSelectedTubes] = useState<TubeModel[]>([]);

  const [rerunsMode, setRerunsMode] = useState(false);

  const { data } = useTubesQuery(
    TubeState.PendingUtiResults,
    TubeState.PendingAbrResults,
    TubeState.PendingPcrHaloResults
  );
  const assignResultMutation = useAssignResultMutation();
  const rerunTubesMutation = useRerunTubesMutation();
  const queryClient = useQueryClient();

  const toast = useToast();

  function handleSubmit(
    tubes: TubeModel[],
    result: PcrResultType,
    comment: string
  ) {
    if (tubes && result) {
      assignResultMutation.mutate(
        {
          tubeIds: tubes.map((t) => t.id),
          pcrResultType: result,
          comment: comment,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries();
            queryClient.invalidateQueries("pcrtotals");
            toast.current!.show({
              severity: "success",
              summary: "Success Message",
              detail: "Result assigned!",
            });
            setSelectedTubes([]);
          },
          onError: (error) => console.error(error),
        }
      );
    }
  }

  function handleRerun(tubes: TubeModel[]) {
    rerunTubesMutation.mutate(
      { tubeIds: tubes.map((x) => x.id) },
      {
        onSuccess: () => {
          queryClient.invalidateQueries();
          queryClient.invalidateQueries("pcrtotals");
          toast.current!.show({
            severity: "success",
            summary: "Success Message",
            detail: "Tubes pushed for rerun!",
          });
          setSelectedTubes([]);
        },
        onError: (error) => console.error(error),
      }
    );
  }

  function toggleRerunsMode(isRerunsMode: boolean) {
    setRerunsMode(isRerunsMode);
    setSelectedTubes([]);
  }

  function getTypeText(tube: TubeModel) {
    switch (tube.state) {
      case TubeState.PendingUtiResults:
        return "UTI";
      case TubeState.PendingAbrResults:
        return "ABR";
      case TubeState.PendingPcrHaloResults:
        return "HALO";
      default:
        return "Unknown";
    }
  }

  function tubeIsSelectable(tube: TubeModel) {
    if (!rerunsMode) {
      const firstSelected = selectedTubes?.[0];

      if (!firstSelected) return true;

      if (tube.state === firstSelected.state) return true;

      return false;
    } else {
      return !tube.isRerun;
    }
  }

  function tubeIsChecked(tube: TubeModel) {
    return Boolean(selectedTubes.find((t) => t.id === tube.id));
  }

  function toggleTube(tube: TubeModel) {
    if (tubeIsChecked(tube)) {
      const existing = selectedTubes.find((t) => t.id === tube.id)!;
      selectedTubes.splice(selectedTubes.indexOf(existing), 1);
      setSelectedTubes([...selectedTubes]);
    } else {
      setSelectedTubes([...selectedTubes, tube]);
    }
  }

  return (
    <div className="flex flex-row">
      <div className="col-8">
        <div className="flex justify-content-end mb-2">
          <ResultFilesImporter />
        </div>
        <div className="flex justify-content-between">
          <div className="flex flex-row align-items-center">
            <InputSwitch
              checked={rerunsMode}
              onChange={(e) => toggleRerunsMode(e.value)}
            />
            <span className="ml-2">Reruns mode</span>
          </div>
          {rerunsMode && (
            <Button
              className="p-button-outlined"
              label={
                "Rerun selected tubes (" +
                selectedTubes?.filter((x) => !x.isRerun)?.length +
                ")"
              }
              onClick={() => handleRerun(selectedTubes)}
              disabled={!selectedTubes || selectedTubes.length === 0}
            />
          )}
        </div>
        <DataTable
          value={data}
          paginator
          rows={10}
          dataKey="id"
          selection={selectedTubes}
          onSelectionChange={(e) => setSelectedTubes(e.value)}
          rowClassName={(rowData) => "cursor-pointer"}
          isDataSelectable={(e) => tubeIsSelectable(e.data)}
          onRowClick={(e) => tubeIsSelectable(e.data) && toggleTube(e.data)}
        >
          <Column
            headerStyle={{ width: "3em" }}
            selectionMode="multiple"
          ></Column>
          <Column
            field="isRerun"
            headerStyle={{ width: "3em" }}
            body={(rowData) => (rowData.isRerun ? "Rerun" : "")}
          ></Column>
          <Column header="Tube ID" field="barcode" />
          <Column
            header="Type"
            body={(rowData) => <span>{getTypeText(rowData)}</span>}
          />
          <Column header="MiniBatch ID" field="currentMinibatch.plateBarcode" />
          <Column header="SuperBatch ID" field="superbatch.plateBarcode" />
        </DataTable>
      </div>
      {!rerunsMode && selectedTubes && selectedTubes.length > 0 && (
        <div className="col-4">
          {selectedTubes[0].state === TubeState.PendingUtiResults && (
            <PendingResultSelector
              header="Initial Results"
              tubes={selectedTubes}
              showOptions={true}
              onSubmitted={handleSubmit}
            />
          )}
          {selectedTubes[0].state === TubeState.PendingAbrResults && (
            <PendingResultSelector
              header="ABR Results"
              tubes={selectedTubes}
              showOptions={false}
              onSubmitted={handleSubmit}
            />
          )}
          {selectedTubes[0].state === TubeState.PendingPcrHaloResults && (
            <PendingResultSelector
              header="Halo Results"
              tubes={selectedTubes}
              showOptions={false}
              onSubmitted={handleSubmit}
            />
          )}
        </div>
      )}
    </div>
  );
}
