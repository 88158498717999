import React, { useRef } from "react";
import "./App.css";
import { locale, addLocale } from "primereact/api";
import { Routes, Route, Navigate } from "react-router-dom";
import { Admin } from "./pages/admin/Admin";
import { Login } from "./pages/Login";
import { PageUsers } from "./pages/admin/PageUsers";
import { PageAudit } from "./pages/admin/PageAudit";
import { PageConfiguration } from "./pages/admin/PageConfiguration";
import { Micro } from "./pages/micro/Micro";
import { PageMicroNewTube } from "./pages/micro/PageMicroNewTube";
import { PageMicroPreculture } from "./pages/micro/PageMicroPreculture";
import { PageMicroPendingResults } from "./pages/micro/PageMicroPendingResults";
import { PageMicroSamplePreparation } from "./pages/micro/PageMicroSamplePreparation";
import { PageMicroNegativePcrResults } from "./pages/micro/PageMicroNegativePcrResults";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { PageMicroPlateCulture } from "./pages/micro/PageMicroPlateCulture";
import { ToastContextProvider } from "./components/ui/ToastContext";
import { Pcr } from "./pages/pcr/Pcr";
import { PagePcrNewTube } from "./pages/pcr/PagePcrNewTube";
import { PagePcrBatchingQueue } from "./pages/pcr/PagePcrBatchingQueue";
import { PagePcrSuperBatchQueue } from "./pages/pcr/PagePcrSuperBatchQueue";
import { PagePcrUtiSetup } from "./pages/pcr/PagePcrUtiSetup";
import { PagePcrUtiRunQueue } from "./pages/pcr/PagePcrUtiRunQueue";
import { PagePcrAbrSetup } from "./pages/pcr/PagePcrAbrSetup";
import { PagePcrAbrRunQueue } from "./pages/pcr/PagePcrAbrRunQueue";
import { PagePcrPendingResults } from "./pages/pcr/PagePcrPendingResults";
import { MyAccount } from "./pages/MyAccount";
import { PagePcrBatchResults } from "./pages/pcr/PagePcrBatchResults";
import { PagePcrAbrBatching } from "./pages/pcr/PagePcrAbrBatching";

function App() {
  const toast = useRef<Toast>(null);

  addLocale("nl", {
    firstDayOfWeek: 1,
    dateFormat: "dd.mm.yy",
  });

  locale("nl");

  return (
    <>
      <Toast ref={toast} />
      <ToastContextProvider toastRef={toast}>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="my-account" element={<MyAccount />} />
          <Route path="admin" element={<Admin />}>
            <Route path="" element={<Navigate to="users" />} />
            <Route path="users" element={<PageUsers />}></Route>
            <Route path="audit" element={<PageAudit />} />
            <Route path="configuration" element={<PageConfiguration />} />
          </Route>
          <Route path="micro" element={<Micro />}>
            <Route path="" element={<Navigate to="new" />} />
            <Route path="new" element={<PageMicroNewTube />} />
            <Route path="preculture" element={<PageMicroPreculture />} />
            <Route path="plate-culture" element={<PageMicroPlateCulture />} />
            <Route
              path="pending-results"
              element={<PageMicroPendingResults />}
            />
            <Route
              path="sample-preparation"
              element={<PageMicroSamplePreparation />}
            />
            <Route
              path="negative-pcr-results"
              element={<PageMicroNegativePcrResults />}
            />
          </Route>
          <Route path="pcr" element={<Pcr />}>
            <Route path="" element={<Navigate to="new" />} />
            <Route path="new" element={<PagePcrNewTube />} />
            <Route path="batching-queue" element={<PagePcrBatchingQueue />} />
            <Route path="superbatch" element={<PagePcrSuperBatchQueue />} />
            <Route path="uti-setup" element={<PagePcrUtiSetup />} />
            <Route path="uti-run" element={<PagePcrUtiRunQueue />} />
            <Route path="abr-batching" element={<PagePcrAbrBatching />} />
            <Route path="abr-setup" element={<PagePcrAbrSetup />} />
            <Route path="abr-run" element={<PagePcrAbrRunQueue />} />
            <Route path="batch-results" element={<PagePcrBatchResults />} />
            <Route path="pending-results" element={<PagePcrPendingResults />} />
          </Route>
        </Routes>
        <ConfirmDialog />
      </ToastContextProvider>
    </>
  );
}

export default App;
