import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { RemoveTubeButton } from "../../components/ui/RemoveTubeButton";
import { useToast } from "../../components/ui/ToastContext";
import { PcrTubeModel } from "../../models/pcr/pcr-tube";
import { useConfigurationQuery } from "../../queries/admin/configuration.query";
import { TubeState } from "../../queries/pcr/models/tube-state";
import {
  useBatchTubesMutation,
  useRemoveTubeMutation,
  useTubesQuery,
} from "../../queries/pcr/tubes.query";

export function PagePcrBatchingQueue() {
  const [tubes, setTubes] = useState<PcrTubeModel[]>([]);
  const [tubesToBatch, setTubesToBatch] = useState<PcrTubeModel[]>([]);
  const [controlBarcode, setControlBarcode] = useState<string>("");
  const [controlBarcodesQuantity, setControlBarcodesQuantity] = useState(1);
  const [barcodesDialogVisible, setBarcodesDialogVisible] = useState(false);

  const navigate = useNavigate();
  const tubesQuery = useTubesQuery(
    TubeState.PendingSuperbatchQueue,
    TubeState.PcrCreated
  );
  const configurationQuery = useConfigurationQuery();
  const batchTubesMutation = useBatchTubesMutation();
  const removeTubeMutation = useRemoveTubeMutation();

  const dataLoaded =
    !tubesQuery.isLoading &&
    tubesQuery.data &&
    !configurationQuery.isLoading &&
    configurationQuery.data;

  const queryClient = useQueryClient();

  const toast = useToast();

  useEffect(() => {
    if (
      !tubesQuery.isLoading &&
      tubesQuery.data &&
      tubesQuery.isFetchedAfterMount
    ) {
      setTubes([
        ...tubesQuery.data.map((d) => {
          return {
            id: d.id,
            barcode: d.barcode,
            state: d.state,
            isRerun: d.isRerun,
          } as PcrTubeModel;
        }),
      ]);
    }
  }, [
    tubesQuery.isLoading,
    tubesQuery.data,
    tubesQuery.dataUpdatedAt,
    tubesQuery.isFetchedAfterMount,
  ]);

  useEffect(() => {
    if (configurationQuery.data) {
      if (tubesToBatch.length === 0) {
        setTubesToBatch(
          tubes.slice(0, getSuperbatchTotalCapacity() - controlBarcodesQuantity)
        );
      } else {
        const allTubeIds = tubes.map((t) => t.id);
        const newTubes = tubesToBatch
          .filter((t) => Boolean(allTubeIds.find((id) => t.id === id)))
          .slice(0, getSuperbatchTotalCapacity() - controlBarcodesQuantity);
        setTubesToBatch(newTubes);
      }
    }
  }, [tubes, configurationQuery.data, controlBarcodesQuantity]);

  async function batch() {
    if (controlBarcodesQuantity > 0 && !controlBarcode) {
      setBarcodesDialogVisible(true);
      return;
    }

    await batchTubesMutation.mutateAsync(
      {
        ids: tubesToBatch.map((x) => x.id),
        barcodes: tubesToBatch.map((x) => x.barcode),
        controlBarcodes: new Array(controlBarcodesQuantity).fill(
          controlBarcode
        ),
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries("tubes");
          queryClient.invalidateQueries("pcrtotals");
        },
      }
    );

    toast.current!.show({
      detail: "Superbatch created",
      severity: "success",
    });
    navigate("../superbatch");
  }

  function tubeIsChecked(tube: PcrTubeModel) {
    return Boolean(tubesToBatch.find((t) => t.id === tube.id));
  }

  function tubeIsDisabled(tube: PcrTubeModel) {
    return (
      !tubeIsChecked(tube) &&
      tubesToBatch.length >=
        getSuperbatchTotalCapacity() - controlBarcodesQuantity
    );
  }

  function toggleTube(tube: PcrTubeModel) {
    if (tubeIsChecked(tube)) {
      const existing = tubesToBatch.find((t) => t.id === tube.id)!;
      tubesToBatch.splice(tubesToBatch.indexOf(existing), 1);
      setTubesToBatch([...tubesToBatch]);
    } else {
      setTubesToBatch([...tubesToBatch, tube]);
    }
  }

  function removeTube(tubeId: number, comment: string) {
    removeTubeMutation.mutate(
      {
        tubeId: tubeId,
        comment: comment,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries("superBatches");
          queryClient.invalidateQueries("tubes");
          toast.current!.show({
            detail: "Tube removed",
            severity: "success",
          });
        },
        onError: (error) => console.error(error),
      }
    );
  }

  function getSuperbatchTotalCapacity() {
    return configurationQuery?.data != null
      ? Math.min(
          configurationQuery.data.superbatchSize,
          96 - configurationQuery.data.disabledSuperbatchWells.length
        )
      : 0;
  }

  return (
    <>
      {dataLoaded && (
        <div className="flex flex-row">
          <div className="col-6">
            <DataTable
              value={tubes}
              paginator
              rows={78}
              selectionMode="checkbox"
              rowClassName={(tube: PcrTubeModel) =>
                tubesToBatch.find((t) => t.id === tube.id) ? "bg-blue-100" : ""
              }
              onRowClick={(e) => !tubeIsDisabled(e.data) && toggleTube(e.data)}
            >
              <Column
                headerStyle={{ width: "3em" }}
                body={(rowData: PcrTubeModel) => (
                  <Checkbox
                    checked={tubeIsChecked(rowData)}
                    disabled={tubeIsDisabled(rowData)}
                    onChange={() => toggleTube(rowData)}
                  />
                )}
              ></Column>
              <Column
                header="Tube ID"
                body={(rowData) => <span>{rowData.barcode}</span>}
              />
              <Column
                header=""
                body={(rowData) => (
                  <div>
                    {tubes.filter((x) => x.barcode === rowData.barcode).length >
                      1 && (
                      <span className="text-red-500 font-italic font-semibold">
                        {" "}
                        Duplicate{" "}
                      </span>
                    )}
                  </div>
                )}
              />
              <Column
                header="Rerun"
                body={(rowData) => (
                  <span>{rowData.isRerun ? "Yes" : "No"}</span>
                )}
              />
              <Column
                headerStyle={{ width: "10em" }}
                body={(rowData) => (
                  <RemoveTubeButton
                    tube={rowData}
                    onConfirmRemove={removeTube}
                  />
                )}
              />
            </DataTable>
            <div>
              <Button
                className="mt-2"
                onClick={batch}
                disabled={tubesToBatch.length === 0}
              >
                Batch highlighted
              </Button>
            </div>
          </div>
          <div className="col-6">
            <h3>Control Tubes Quantity</h3>
            <InputNumber
              value={controlBarcodesQuantity}
              onValueChange={(e) => setControlBarcodesQuantity(e.target.value!)}
              min={1}
              max={Math.min(getSuperbatchTotalCapacity() - 1 ?? 0, 3)}
              buttonLayout="horizontal"
              showButtons
            />
            <h3>Selected tubes</h3>
            <h2>
              {tubesToBatch.length}/
              {getSuperbatchTotalCapacity() - controlBarcodesQuantity}
            </h2>
          </div>
          <Dialog
            visible={barcodesDialogVisible}
            header="Scan control tubes IDs"
            onHide={() => {
              setBarcodesDialogVisible(false);
              setControlBarcode("");
            }}
          >
            <div className="flex flex-column align-items-center w-25rem">
              <InputText
                value={controlBarcode}
                onChange={(e) => setControlBarcode(e.target.value)}
                autoFocus
                className="mb-2 w-20rem"
              />

              <Button onClick={batch}>Done</Button>
            </div>
          </Dialog>
        </div>
      )}
    </>
  );
}
