import { Card } from "primereact/card";
import { ReactNode } from "react";

export function PageWrapper({ children }: { children: ReactNode }) {
  return (
    <div className="p-2">
      <Card>{children}</Card>
    </div>
  );
}
