import { ConfigurationModel } from "../../queries/admin/models/configuration";
import { MicroTotalsModel } from "../../queries/admin/models/micro-totals";
import { PcrTotalsModel } from "../../queries/admin/models/pcr-totals";
import { API_ENDPOINTS } from "../../utils/endpoints";
import { BaseService } from "../base.service";

class ConfigurationService extends BaseService {
  configuration = { superbatchSize: 3 } as ConfigurationModel;

  async getConfiguration(): Promise<ConfigurationModel> {
    let url = `${this.endpointPath}`;

    return this.http.get<ConfigurationModel>(url).then((res) => res.data);
  }
  async getPcrTotals(): Promise<PcrTotalsModel> {
    let url = `${this.endpointPath}/pcr-totals`;

    return this.http.get<PcrTotalsModel>(url).then((res) => res.data);
  }

  async getMicroTotals(): Promise<MicroTotalsModel> {
    let url = `${this.endpointPath}/micro-totals`;

    return this.http.get<MicroTotalsModel>(url).then((res) => res.data);
  }

  async saveConfiguration(model: ConfigurationModel): Promise<void> {
    return this.http.put(`${this.endpointPath}`, {
      superbatchSize: model.superbatchSize,
      minibatchUtiSize: model.minibatchUtiSize,
      minibatchAbrSize: model.minibatchAbrSize,
      haloSuffix: model.haloSuffix,
      showSupplementalElutionBufferInMetaData: model.showSupplementalElutionBufferInMetaData,
      showSupplementalProteinaseInMetaData: model.showSupplementalProteinaseInMetaData,
      disabledSuperbatchWells: model.disabledSuperbatchWells,
      disabledUtiMinibatchWells: model.disabledUtiMinibatchWells,
      disabledAbrMinibatchWells: model.disabledAbrMinibatchWells
    });
  }
}

export const configurationService = new ConfigurationService(
  API_ENDPOINTS.CONFIGURATION
);
