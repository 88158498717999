import { MetaDataReportItem } from "../../queries/admin/models/metadata-report-item";
import { TubeHistory } from "../../queries/admin/models/tube-history-report";
import { API_ENDPOINTS } from "../../utils/endpoints";
import { BaseService } from "../base.service";

class ReportsService extends BaseService {
  async getTubeHistory(code: string): Promise<TubeHistory> {
    let url = `${this.endpointPath}/tube-history/${code}`;

    return this.http.get<TubeHistory>(url).then((res) => res.data);
  }

  async getMetadataReport(
    query: string,
    barcode: string
  ): Promise<MetaDataReportItem[]> {
    let url = `${this.endpointPath}/metadata?q=${query}&b=${barcode}`;

    return this.http.get<MetaDataReportItem[]>(url).then((res) => res.data);
  }
}

export const reportsService = new ReportsService(API_ENDPOINTS.REPORTS);
