/* eslint-disable react-hooks/rules-of-hooks */
import { useQuery } from "react-query";
import { pcrService } from "../../services/pcr/pcr.service";
import { AbrBatchModel } from "./models/abr-batch";
import { AbrBatchState } from "./models/abr-batch-state";

async function fetchAbrBatches(states: AbrBatchState[]) {
  return await pcrService.getAbrBatches(states);
}

async function fetchAbrBatch(id: number | undefined) {
  if (!id) {
    return;
  } else {
    return await pcrService.getAbrBatchDetails(id);
  }
}

export function useAbrBatchesQuery(...states: AbrBatchState[]) {
  return useQuery<AbrBatchModel[]>(["abrBatches", "superBatches", states], () =>
    fetchAbrBatches(states)
  );
}

export function useAbrBatchQuery(id: number | undefined) {
  return useQuery<AbrBatchModel | undefined>(
    ["abrBatches", "superBatches", id],
    () => fetchAbrBatch(id),
    { refetchOnWindowFocus: false }
  );
}
