import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useState } from "react";
import { SuperbatchModel } from "../../queries/pcr/models/super-batch";
import { useAssignSuperbatchPlateMutation } from "../../queries/pcr/superBatches.query";
import { ScanBarcodeDialog } from "../ui/ScanBarcodeDialog";

interface SuperbatchSelectorProps<T> {
  batches: T[];
  header: string;
  showPlateIdColumn?: boolean;
  showStatusColumn?: boolean;
  statusSelector?: (v: T) => string;
  onSelect: (v: T) => void;
}

export function SuperbatchSelector<T extends SuperbatchModel>({
  batches,
  header,
  showPlateIdColumn = false,
  showStatusColumn = false,
  statusSelector,
  onSelect,
}: SuperbatchSelectorProps<T>) {
  const assignSuperbatchPlateMutation = useAssignSuperbatchPlateMutation();

  const [scanPlateIdVisible, setScanPlateIdVisible] = useState(false);
  const [selectedSuperBatch, setSelectedSuperBatch] = useState<T>();

  function selectSuperBatch(superBatch: T) {
    setSelectedSuperBatch(superBatch);
    setScanPlateIdVisible(true);
  }

  function confirmPlateId(barcode: string) {
    setScanPlateIdVisible(false);

    if (!selectedSuperBatch?.plateBarcode) {
      assignSuperbatchPlateMutation.mutate(
        {
          superbatchId: selectedSuperBatch!.id,
          plateBarcode: barcode,
        },
        {
          onSuccess: () => onSelect(selectedSuperBatch!),
          onError: (error) => console.error(error),
        }
      );
    } else {
      onSelect(selectedSuperBatch!);
    }
  }

  return (
    <div className="flex justify-content-center">
      <DataTable
        className="w-6"
        rowClassName={() => "cursor-pointer"}
        value={batches}
        onRowClick={(d) => selectSuperBatch(d.data)}
      >
        <Column header={header} field="reference" />
        {showPlateIdColumn && <Column header="Plate ID" field="plateBarcode" />}
        {showStatusColumn && statusSelector && (
          <Column header="Status" body={(rowData) => statusSelector(rowData)} />
        )}
      </DataTable>
      <ScanBarcodeDialog
        bodyText={
          selectedSuperBatch?.plateBarcode
            ? "SCAN " + selectedSuperBatch?.plateBarcode + " PLATE ID:"
            : "ASSIGN PLATE ID"
        }
        headerText="Scan Plate ID"
        visible={scanPlateIdVisible}
        onHide={() => setScanPlateIdVisible(false)}
        requiredBarcode={selectedSuperBatch?.plateBarcode}
        onScan={confirmPlateId}
      />
    </div>
  );
}
