import { useEffect } from "react";
import { useConfigurationQuery } from "../../queries/admin/configuration.query";
import { ExtendedMetaData } from "../../queries/pcr/models/meta-data";
import { ExtendedMetaDataInput } from "./ExtendedMetaDataInput";

export interface SuperBatchMetaDataInputProps {
  metadata: ExtendedMetaData;
  disabled?: boolean;
  onChange?: (v: ExtendedMetaData) => void;
  onBlur?: () => void;
}

export function SuperBatchMetaDataInput({
  metadata,
  disabled,
  onChange,
  onBlur,
}: SuperBatchMetaDataInputProps) {
  const configurationQuery = useConfigurationQuery();

  useEffect(() => {
    if (!configurationQuery.data) {
      return;
    }

    if (!metadata.equipments) {
      metadata.equipments = [
        {
          label: "KingFisher Flex",
          value: "",
          required: true,
          disabled: false,
        },
        {
          label: "P1000",
          value: "",
          required: true,
          disabled: false,
        },
        {
          label: "Repeater",
          value: "",
          required: true,
          disabled: false,
        },
        {
          label: "P300 Multi",
          value: "",
          required: true,
          disabled: false,
        },
        {
          label: "Additional Pipette",
          value: "",
          required: false,
          disabled: false,
        },
      ];
    }

    if (!metadata.reagentLots) {
      metadata.reagentLots = [
        {
          label: "Extraction Kit",
          value: "",
          required: true,
          disabled: false,
        },
        {
          label: "Ethanol",
          value: "",
          required: true,
          disabled: false,
        },
        {
          label: "Water",
          value: "",
          required: true,
          disabled: false,
        },
        {
          label: "TaqMan DNA Spike in Control",
          value: "",
          required: true,
          disabled: false,
        },
        {
          label: "ExCon",
          value: "",
          required: true,
          disabled: false,
        },
        {
          label: "Lysozyme",
          value: "",
          required: true,
          disabled: false,
        },
        {
          label: "Dimethyl sulfoxide (DMSO)",
          value: "",
          required: true,
          disabled: false,
        },
        {
          label: "Polyethylene glycol (PEG)",
          value: "",
          required: true,
          disabled: false,
        },
      ];

      if (configurationQuery.data.showSupplementalProteinaseInMetaData) {
        metadata.reagentLots.push({
          label: "Supplemental Proteinase K",
          value: "",
          required: false,
          disabled: false,
        });
      }

      if (configurationQuery.data.showSupplementalElutionBufferInMetaData) {
        metadata.reagentLots.push({
          label: "Supplemental Elution Buffer",
          value: "",
          required: false,
          disabled: false,
        });
      }
    }

    if (onChange) {
      onChange({ ...metadata });
    }
  }, [metadata, configurationQuery.data, onChange]);

  return (
    <>
      {metadata && (
        <ExtendedMetaDataInput
          metadata={metadata}
          disabled={disabled}
          onBlur={onBlur}
          onChange={onChange}
        />
      )}
    </>
  );
}
