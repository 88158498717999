import { format, startOfToday, startOfTomorrow } from "date-fns";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useState } from "react";
import { useUtiParsingLogsQuery } from "../../queries/admin/audits.query";
import { DATE_FORMAT } from "../../utils/constants";
import { ServerLog } from "../../models/admin/server-log";
import { InputText } from "primereact/inputtext";

export function TabUtiParsingResults() {
  const [accessionCode, setAccessionCode] = useState<string>('');
  const { isLoading, data } = useUtiParsingLogsQuery(accessionCode);

  function resultTemplate(log: ServerLog) {
    let object = JSON.parse(log.message);
    object.Comment = undefined;

    return (
      <div>
        <pre style={{ whiteSpace: "pre-wrap" }}>
          {JSON.stringify(object, null, 2)}
        </pre>
      </div>
    );
  }
  function logTemplate(log: ServerLog) {
    let object = JSON.parse(log.message);
    let comment = object.Comment;

    const lines = comment.split("\\r\\n");

    return (
      <pre>
        {lines.map((line: string, index: number) => (
          <p key={index}>{line}</p>
        ))}
      </pre>
    );
  }

  return (
    <div>
      <div>
        <div className="flex align-items-center mb-2">
          <label className="mr-2 w-5rem">Tube code:</label>
          <InputText
            value={accessionCode}
            onChange={(v) => setAccessionCode(v.target.value)}
          />
        </div>
      </div>
      {!isLoading && data && (
        <DataTable value={data}>
          <Column
            header="Date"
            dataType="date"
            body={(d) => <span>{format(d.date, DATE_FORMAT)}</span>}
          />
          <Column field="username" header="User" />
          <Column header="Result" body={resultTemplate} />
          <Column header="Log" body={logTemplate} />
        </DataTable>
      )}
    </div>
  );
}
