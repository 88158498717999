export enum TubeState {
  Undefined = 0,
  MicroCreated = 10,
  PendingPreculture = 20,
  PendingPlateCulture = 30,
  PendingMicroResults = 40,
  PendingHalo = 50,
  PendingFungal = 60,
  MicroCompleted = 99,

  PcrCreated = 110,
  PcrTubeRemoved = 111,
  PendingSuperbatchQueue = 120,
  InSuperbatch = 130,
  PendingUtiRun = 131,
  PendingAbrRun = 132,
  PendingUtiResults = 140,
  PendingAbrResults = 150,
  PendingPcrHaloResults = 160,

  NotApplicable = 998,
  WorkupCompleted = 999,
}
