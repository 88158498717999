import { format, startOfToday, startOfTomorrow } from "date-fns";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { useState } from "react";
import { useRerunTubesQuery } from "../../queries/admin/audits.query";
import { useTubeHistoryReportQuery } from "../../queries/admin/reports.query";
import { TubeState } from "../../queries/pcr/models/tube-state";
import { DATE_FORMAT } from "../../utils/constants";

export function TabTubeHistory() {
  const [barcode, setBarcode] = useState<string>("");
  const [barcodeForQuery, setBarcodeForQuery] = useState<string>();
  const { isLoading, data } = useTubeHistoryReportQuery(barcodeForQuery!);

  return (
    <div>
      <div>
        <label className="mr-2">Tube barcode:</label>
        <InputText
          value={barcode}
          onChange={(e) => setBarcode(e.target.value)}
        />
        <Button
          label="Search"
          className="p-button-outlined p-button-sm mb-2 ml-2"
          onClick={() => setBarcodeForQuery(barcode)}
          disabled={barcode === null || (barcode !== null && barcode?.length === 0)}
        />
      </div>
      <hr />

      {!isLoading && data && (
        <DataTable value={data.items}>
          <Column
            header="Date"
            dataType="date"
            body={(d) => <span>{format(new Date(d.timeStamp), DATE_FORMAT)}</span>}
          />
          <Column field="user" header="User" />
          <Column field="action" header="Action" />
          <Column field="info" header="Info" />
        </DataTable>
      )}
    </div>
  );
}
