import { classNames } from "primereact/utils";
import { PlateCoordinates } from "../../models/pcr/plate-coordinates";
import { TubeModel } from "../../queries/pcr/models/tube";

interface MiniPlateHoleProps {
  tube?: TubeModel;
  row: string;
  column: string;
  highlighted: boolean;
  disabled: boolean;
  highlightColorClassName?: string;
  onClick?: (coords: PlateCoordinates) => void;
}

export interface HoleClickData {
  row: string;
  column: string;
}

export function MiniPlateHole({
  tube,
  row,
  column,
  highlighted,
  disabled,
  highlightColorClassName = "bg-green-200",
  onClick,
}: MiniPlateHoleProps) {
  function handleWellClick() {
    if (onClick) {
      onClick({row, column});
    }
  }

  return (
    <>
      <div
        className={classNames(
          "border-1 border-circle text-xs h-1rem w-1rem flex justify-content-center align-items-center text-center",
          highlighted ? "bg-green-200" : "",
          highlighted ? highlightColorClassName : "",
          disabled ? "bg-gray-400" : "",
          (onClick && !disabled) ? "cursor-pointer" : ""
        )}
        onClick={handleWellClick}
      >
        <span className="word-break-all">{tube?.barcode}</span>
      </div>
    </>
  );
}
