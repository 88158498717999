import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { useMemo, useState } from "react";
import { useToast } from "./ToastContext";

interface ScanBarcodeDialogProps {
  visible: boolean;
  headerText: string;
  bodyText: string;
  requiredBarcode?: string;
  onHide: () => void;
  onScan?: (barcode: string) => void;
}
export function ScanBarcodeDialog({
  bodyText,
  headerText,
  visible,
  requiredBarcode,
  onHide,
  onScan,
}: ScanBarcodeDialogProps) {
  const [value, setValue] = useState<string>("");

  const toast = useToast();

  const trimmed = useMemo(() => {
    return value.trim();
  }, [value]);

  function handleTab() {
    if (!Boolean(trimmed)) {
      return;
    }

    setValue("");
    if (onScan) {
      if (requiredBarcode) {
        if (requiredBarcode === value) {
          return onScan(value);
        } else {
          toast.current!.show({
            detail: "Scanned code does not match Plate ID",
            severity: "error",
          });
        }
      } else {
        onScan(value);
      }
    }
  }

  return (
    <Dialog visible={visible} header="Scan Plate ID" onHide={onHide}>
      <div className="flex flex-column align-items-center w-25rem">
        <span className="mb-2">{bodyText}</span>
        <InputText
          value={value}
          onChange={(e) => setValue(e.target.value)}
          autoFocus
          className="mb-2 w-20rem"
          onKeyDown={(e) => {
            if (e.code === "Tab") {
              e.preventDefault();
              e.stopPropagation();
              handleTab();
            }
          }}
        />
        <Button onClick={handleTab} type="submit" disabled={!Boolean(trimmed)}>
          Done
        </Button>
      </div>
    </Dialog>
  );
}
