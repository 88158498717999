export enum ActionType {
  MicroTubesCreated = 10,
  MicroTubesRemoved = 11,
  MicroPreCultureStarted = 20,
  MicroPreCultureUpdated = 21,
  MicroPreCultureCompleted = 22,
  MicroPlateCultureStarted = 30,
  MicroPlateCultureUpdated = 31,
  MicroPlateCultureCompleted = 32,
  MicroResult = 40,
  MicroGenerateSamples = 50,

  PcrTubesCreated = 110,
  PcrTubeRemoved = 111,
  SuperbatchCreated = 120,
  SuperbatchPlateAssigned = 121,
  SuperbatchItemsUpdated = 122,
  SuperbatchQueueCompleted = 123,
  SuperbatchItemPositionAssigned = 124,
  SuperbatchControlItemAdded = 125,
  MinibatchControlItemBarcodeAssigned = 126,
  MinibatchUtiSetupCompleted = 130,
  MinibatchUtiRunQueueSaved = 131,
  MinibatchUtiRunQueueCompleted = 132,
  MinibatchUtiResultAssigned = 133,
  MinibatchAbrSetupCompleted = 140,
  MinibatchAbrRunQueueSaved = 141,
  MinibatchAbrRunQueueCompleted = 142,
  MinibatchAbrResultAssigned = 143,
  MinibatchAbrSetupBatched = 145,
  PcrResult = 150,
  SuperbatchMetadataAssigned = 160,
  MinibatchMetadataAssigned = 161,
  MinibatchExported = 170,
  RerunMinibatch = 180,
  RerunTubes = 181,
}
