import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Checkbox } from "primereact/checkbox";
import { useState } from "react";
import { SuperbatchModel } from "../../queries/pcr/models/super-batch";
import { useAssignSuperbatchPlateMutation } from "../../queries/pcr/superBatches.query";
import { ScanBarcodeDialog } from "../ui/ScanBarcodeDialog";

interface SuperbatchMultiSelectorProps<T> {
  batches: T[];
  selectedBatchIds: number[];
  enabledIds?: number[];
  header: string;
  showPlateIdColumn?: boolean;
  showStatusColumn?: boolean;
  statusSelector?: (v: T) => string;
  onSelect: (v: number[]) => void;
}

export function SuperbatchMultiSelector<T extends SuperbatchModel>({
  batches,
  selectedBatchIds,
  enabledIds,
  header,
  showPlateIdColumn = false,
  showStatusColumn = false,
  statusSelector,
  onSelect,
}: SuperbatchMultiSelectorProps<T>) {
  const [scanPlateIdVisible, setScanPlateIdVisible] = useState(false);
  const [selectedSuperBatch, setSelectedSuperBatch] = useState<T>();

  function confirmPlateId() {
    setScanPlateIdVisible(false);

    if (selectedSuperBatch) {
      onSelect([...selectedBatchIds, selectedSuperBatch.id]);
    }
  }

  function superBatchIsChecked(superBatch: T) {
    return Boolean(selectedBatchIds.find((i) => superBatch.id === i));
  }

  function superBatchIsSelectable(superBatch: T) {
    if (!enabledIds) return true;

    return enabledIds.find((i) => superBatch.id === i);
  }

  function toggleSuperBatch(superBatch: T) {
    const existing = selectedBatchIds.find((i) => superBatch.id === i);

    if (existing) {
      selectedBatchIds.splice(selectedBatchIds.indexOf(existing), 1);
      onSelect(selectedBatchIds);
    } else {
      setSelectedSuperBatch(superBatch);
      setScanPlateIdVisible(true);
    }
  }

  return (
    <div className="flex justify-content-center">
      <DataTable
        className="w-6"
        rowClassName={() => "cursor-pointer"}
        value={batches}
        onRowClick={(d) => toggleSuperBatch(d.data)}
      >
        <Column
          headerStyle={{ width: "3em" }}
          body={(rowData: T) => (
            <Checkbox
              checked={superBatchIsChecked(rowData)}
              disabled={!superBatchIsSelectable(rowData)}
              onChange={() => toggleSuperBatch(rowData)}
            />
          )}
        ></Column>
        <Column header={header} field="reference" />
        {showPlateIdColumn && <Column header="Plate ID" field="plateBarcode" />}
        {showStatusColumn && statusSelector && (
          <Column header="Status" body={(rowData) => statusSelector(rowData)} />
        )}
      </DataTable>
      <ScanBarcodeDialog
        bodyText={
          selectedSuperBatch?.plateBarcode
            ? "SCAN " + selectedSuperBatch?.plateBarcode + " PLATE ID:"
            : "ASSIGN PLATE ID"
        }
        headerText="Scan Plate ID"
        visible={scanPlateIdVisible}
        onHide={() => setScanPlateIdVisible(false)}
        requiredBarcode={selectedSuperBatch?.plateBarcode}
        onScan={confirmPlateId}
      />
    </div>
  );
}
