import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { RadioButton } from "primereact/radiobutton";
import { Checkbox } from "primereact/checkbox";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useToast } from "../../components/ui/ToastContext";
import { PendingMicroTubeModel } from "../../models/micro/pending-micro-tube";
import { MicroResult } from "../../queries/micro/models/micro-result";
import { TubeState } from "../../queries/micro/models/tube-state";
import {
  useTubesQuery,
  useUpdatePendingResultsTubesMutation,
} from "../../queries/micro/tubes.query";
import { TubeWorkupType } from "../../queries/pcr/models/tube-workup-type";

export function PageMicroPendingResults() {
  const [tubes, setTubes] = useState<PendingMicroTubeModel[]>([]);

  const [selectedTubes, setSelectedTubes] = useState<PendingMicroTubeModel[]>(
    []
  );
  const [selectedResult, setSelectedResult] = useState<MicroResult>();

  const { isLoading, data, dataUpdatedAt } = useTubesQuery(
    TubeState.PendingResults
  );
  const tubesMutation = useUpdatePendingResultsTubesMutation();
  const queryClient = useQueryClient();

  const toast = useToast();

  useEffect(() => {
    setSelectedResult(undefined);
  }, [selectedTubes]);

  useEffect(() => {
    if (!isLoading && data) {
      setTubes([
        ...data.map((d) => {
          return {
            id: d.id,
            barcode: d.barcode,
            state: d.state,
            workupType: d.workupType,
          } as PendingMicroTubeModel;
        }),
      ]);
    }
  }, [isLoading, data, dataUpdatedAt]);

  function submit() {
    if (selectedTubes.length > 0 && selectedResult) {
      tubesMutation.mutate(
        { tubeIds: selectedTubes.map((t) => t.id), result: selectedResult },
        {
          onSuccess: () => {
            setSelectedTubes([]);
            queryClient.invalidateQueries(["tubes"]);
            toast.current!.show({
              severity: "success",
              summary: "Success Message",
              detail: "Workup complete. No further testing needed.",
            });
          },
          onError: (error) => console.error(error),
        }
      );
    }
  }

  function shouldShowNegOption(tube: PendingMicroTubeModel): boolean {
    return (
      tube.workupType === TubeWorkupType.Regular ||
      tube.workupType === TubeWorkupType.SemiTrad
    );
  }

  function shouldShowNeedsHaloOption(tube: PendingMicroTubeModel): boolean {
    return true;
  }

  function shouldShowNeedsFungalOption(tube: PendingMicroTubeModel): boolean {
    return (
      tube.workupType === TubeWorkupType.Regular ||
      tube.workupType === TubeWorkupType.SemiTrad
    );
  }

  function shouldShowNeedsSemiTraditionalOption(
    tube: PendingMicroTubeModel
  ): boolean {
    return tube.workupType === TubeWorkupType.Regular;
  }

  function shouldShowFungalCompletedOption(
    tube: PendingMicroTubeModel
  ): boolean {
    return tube.workupType === TubeWorkupType.Fungal;
  }

  function getTypeText(tube: PendingMicroTubeModel) {
    switch (tube.workupType) {
      case TubeWorkupType.Regular:
        return "Regular";
      case TubeWorkupType.Halo:
        return "FE";
      case TubeWorkupType.Fungal:
        return "Fungal";
      case TubeWorkupType.SemiTrad:
        return "Semi-Trad";
      default:
        return "Unknown";
    }
  }

  function tubeIsSelectable(tube: PendingMicroTubeModel) {
    const firstSelected = selectedTubes?.[0];

    if (!firstSelected) return true;

    if (tube.workupType === firstSelected.workupType) return true;

    return false;
  }

  function tubeIsChecked(tube: PendingMicroTubeModel) {
    return Boolean(selectedTubes.find((t) => t.id === tube.id));
  }

  function toggleTube(tube: PendingMicroTubeModel) {
    if (tubeIsChecked(tube)) {
      const existing = selectedTubes.find((t) => t.id === tube.id)!;
      selectedTubes.splice(selectedTubes.indexOf(existing), 1);
      setSelectedTubes([...selectedTubes]);
    } else {
      setSelectedTubes([...selectedTubes, tube]);
    }
  }

  return (
    <div className="flex flex-row">
      <div className="col-6">
        <DataTable
          value={tubes}
          paginator
          rows={10}
          selection={selectedTubes}
          rowClassName={() => "cursor-pointer"}
          onRowClick={(e) => tubeIsSelectable(e.data) && toggleTube(e.data)}
        >
          <Column
            headerStyle={{ width: "3em" }}
            body={(rowData: PendingMicroTubeModel) => (
              <Checkbox
                checked={tubeIsChecked(rowData)}
                disabled={!tubeIsSelectable(rowData)}
                onChange={(e) => {
                  toggleTube(rowData);
                }}
              />
            )}
          ></Column>
          <Column
            header="Tube ID"
            alignHeader={"center"}
            body={(rowData) => <span>{rowData.barcode}</span>}
          />
          <Column
            header="Type"
            body={(rowData) => <span>{getTypeText(rowData)}</span>}
          />
        </DataTable>
      </div>
      {selectedTubes.length > 0 && (
        <div className="col-6">
          <Card className="surface-100">
            <h2>Waiting for initial results</h2>
            <div>
              {shouldShowNegOption(selectedTubes[0]) && (
                <div className="field-radiobutton">
                  <RadioButton
                    value={MicroResult.Neg}
                    name="state"
                    onChange={(e) => setSelectedResult(e.value)}
                    checked={selectedResult === MicroResult.Neg}
                    inputId="stateNeg"
                  />
                  <label htmlFor="stateNeg" className="cursor-pointer">
                    No further workup
                  </label>
                </div>
              )}
              {shouldShowNeedsHaloOption(selectedTubes[0]) && (
                <div className="field-radiobutton">
                  <RadioButton
                    value={MicroResult.NeedsHalo}
                    name="state"
                    onChange={(e) => setSelectedResult(e.value)}
                    checked={selectedResult === MicroResult.NeedsHalo}
                    inputId="stateNeedsHalo"
                  />
                  <label htmlFor="stateNeedsHalo" className="cursor-pointer">
                    Needs FE workup
                  </label>
                </div>
              )}
              {shouldShowNeedsFungalOption(selectedTubes[0]) && (
                <div className="field-radiobutton">
                  <RadioButton
                    value={MicroResult.NeedsFungal}
                    name="state"
                    onChange={(e) => setSelectedResult(e.value)}
                    checked={selectedResult === MicroResult.NeedsFungal}
                    inputId="stateNeedsFungal"
                  />
                  <label htmlFor="stateNeedsFungal" className="cursor-pointer">
                    Needs fungal workup
                  </label>
                </div>
              )}
              {shouldShowNeedsSemiTraditionalOption(selectedTubes[0]) && (
                <div className="field-radiobutton">
                  <RadioButton
                    value={MicroResult.NeedsSemiTraditional}
                    name="state"
                    onChange={(e) => setSelectedResult(e.value)}
                    checked={
                      selectedResult === MicroResult.NeedsSemiTraditional
                    }
                    inputId="stateNeedsSemiTraditional"
                  />
                  <label
                    htmlFor="stateNeedsSemiTraditional"
                    className="cursor-pointer"
                  >
                    Semi-traditional workup from BAP
                  </label>
                </div>
              )}
              {shouldShowFungalCompletedOption(selectedTubes[0]) && (
                <div className="field-radiobutton">
                  <RadioButton
                    value={MicroResult.FungalWorkupCompleted}
                    name="state"
                    onChange={(e) => setSelectedResult(e.value)}
                    checked={
                      selectedResult === MicroResult.FungalWorkupCompleted
                    }
                    inputId="stateFungalWorkupCompleted"
                  />
                  <label
                    htmlFor="stateFungalWorkupCompleted"
                    className="cursor-pointer"
                  >
                    Fungal workup completed
                  </label>
                </div>
              )}
              <Button
                className="p-button-outlined"
                label="Submit"
                onClick={submit}
                disabled={!selectedResult}
              />
            </div>
            <h3 className="mb-1">Selected IDs ({selectedTubes?.length}):</h3>
            <div className="">
              {selectedTubes?.map((t) => (
                <div>{t.barcode}</div>
              ))}
            </div>
          </Card>
        </div>
      )}
    </div>
  );
}
