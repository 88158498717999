import { format } from "date-fns";
import { ActionType } from "../../queries/action-type";
import { AbrBatchModel } from "../../queries/pcr/models/abr-batch";
import { AbrBatchState } from "../../queries/pcr/models/abr-batch-state";
import { AddSuperbatchControlItemRequest } from "../../queries/pcr/models/add-superbatch-control-item-request";
import { AssignMinibatchControlItemBarcode } from "../../queries/pcr/models/assign-minibatch-control-item-barcode";
import { AssignMinibatchMetadata } from "../../queries/pcr/models/assign-minibatch-metadata-request";
import { AssignMinibatchResultRequest } from "../../queries/pcr/models/assign-minibatch-result-request";
import { AssignSuperbatchItemPositionRequest } from "../../queries/pcr/models/assign-superbatch-item-position-request";
import { AssignSuperbatchMetadata } from "../../queries/pcr/models/assign-superbatch-metadata-request";
import { AssignSuperbatchPlate } from "../../queries/pcr/models/assign-superbatch-plate-request";
import { AssignTubeResultRequest } from "../../queries/pcr/models/assign-tube-result-request";
import { BatchForAbrModel } from "../../queries/pcr/models/batch-for-abr";
import { BatchTubesRequest } from "../../queries/pcr/models/batch-tubes-request";
import { CreateTubesModel } from "../../queries/pcr/models/create-tubes";
import { MinibatchModel } from "../../queries/pcr/models/mini-batch";
import { MiniBatchState } from "../../queries/pcr/models/mini-batch-state";
import { RemoveTubeRequest } from "../../queries/pcr/models/remove-tube.request";
import { RerunTubeRequest } from "../../queries/pcr/models/rerun-tube.request";
import { SaveMiniBatchModel } from "../../queries/pcr/models/save-mini-batch";
import { SaveMiniBatchSetupModel } from "../../queries/pcr/models/save-mini-batch-uti";
import { SaveSuperBatchModel } from "../../queries/pcr/models/save-super-batch";
import { SuperbatchModel } from "../../queries/pcr/models/super-batch";
import { SuperBatchState } from "../../queries/pcr/models/super-batch-state";
import { TubeModel } from "../../queries/pcr/models/tube";
import { TubeState } from "../../queries/pcr/models/tube-state";
import { API_ENDPOINTS } from "../../utils/endpoints";
import { getTimezoneName } from "../../utils/helpers";
import { BaseService } from "../base.service";
import { UtiParsingResult } from "../../queries/pcr/models/uti-parsing-result";
import { PcrSubresult } from "../../queries/pcr/models/pcr-subresult";
import { PcrResultType } from "../../queries/pcr/models/pcr-result-type";

class PcrService extends BaseService {
  async getTubesByStates(states: TubeState[]): Promise<TubeModel[]> {
    let url = `${this.endpointPath}/tubes?state=${states.join("&state=")}`;

    return this.http.get<TubeModel[]>(url).then((res) => res.data);
  }

  async createPcrTubes(model: CreateTubesModel): Promise<TubeModel[]> {
    return this.http.post(`${this.endpointPath}/actions/process-multi-tubes`, {
      tubeCodes: model.barcodes,
      actionType: ActionType.PcrTubesCreated,
      data: {},
    });
  }

  async batchTubes(model: BatchTubesRequest): Promise<TubeModel[]> {
    return this.http.post(`${this.endpointPath}/actions/process-single`, {
      actionType: ActionType.SuperbatchCreated,
      data: {
        tubeIds: model.ids,
        tubeBarcodes: model.barcodes,
        controlTubeBarcodes: model.controlBarcodes,
      },
    });
  }

  async rerunTubes(model: RerunTubeRequest): Promise<TubeModel[]> {
    return this.http.post(`${this.endpointPath}/actions/process-single`, {
      actionType: ActionType.RerunTubes,
      data: {
        tubeIds: model.tubeIds,
      },
    });
  }

  async removeTube(model: RemoveTubeRequest): Promise<void> {
    return this.http.post(`${this.endpointPath}/actions/process-single`, {
      actionType: ActionType.PcrTubeRemoved,
      data: {
        tubeId: model.tubeId,
        comment: model.comment,
      },
    });
  }

  async assignResult(model: AssignTubeResultRequest): Promise<TubeModel[]> {
    return this.http.post(`${this.endpointPath}/actions/process-multi-tubes`, {
      actionType: ActionType.PcrResult,
      tubeIds: model.tubeIds,
      data: {
        tubeIds: model.tubeIds,
        pcrResultType: model.pcrResultType,
        pcrSubresultType: model.pcrSubresultType,
        precultureTubeId: model.precultureTubeId,
        comment: model.comment,
      },
    });
  }

  async assignSuperbatchPlate(
    model: AssignSuperbatchPlate
  ): Promise<TubeModel[]> {
    return this.http.post(`${this.endpointPath}/actions/process-single`, {
      actionType: ActionType.SuperbatchPlateAssigned,
      data: {
        superbatchId: model.superbatchId,
        plateBarcode: model.plateBarcode,
      },
    });
  }

  async assignSuperbatchMetadata(
    model: AssignSuperbatchMetadata
  ): Promise<TubeModel[]> {
    return this.http.post(`${this.endpointPath}/actions/process-single`, {
      actionType: ActionType.SuperbatchMetadataAssigned,
      data: {
        superbatchId: model.superbatchId,
        metadata: model.metadata,
        comment: model.comment,
      },
    });
  }

  async assignMinibatchMetadata(
    model: AssignMinibatchMetadata
  ): Promise<TubeModel[]> {
    return this.http.post(`${this.endpointPath}/actions/process-single`, {
      actionType: ActionType.MinibatchMetadataAssigned,
      data: {
        minibatchId: model.minibatchId,
        metadata: model.metadata,
        comment: model.comment,
      },
    });
  }

  async assignSuperbatchItemPosition(
    model: AssignSuperbatchItemPositionRequest
  ): Promise<any> {
    return this.http.post(`${this.endpointPath}/actions/process-single`, {
      actionType: ActionType.SuperbatchItemPositionAssigned,
      data: {
        superbatchId: model.superbatchId,
        id: model.superbatchItemId,
        row: model.row,
        column: model.column,
      },
    });
  }

  async addSuperbatchControlItem(
    model: AddSuperbatchControlItemRequest
  ): Promise<any> {
    return this.http.post(`${this.endpointPath}/actions/process-single`, {
      actionType: ActionType.SuperbatchControlItemAdded,
      data: {
        superbatchId: model.superbatchId,
        barcode: model.barcode,
        tubeType: model.type,
        row: model.row,
        column: model.column,
      },
    });
  }

  async assignMinibatchControlItemBarcode(
    model: AssignMinibatchControlItemBarcode
  ): Promise<any> {
    return this.http.post(`${this.endpointPath}/actions/process-single`, {
      actionType: ActionType.MinibatchControlItemBarcodeAssigned,
      data: {
        tubeId: model.tubeId,
        barcode: model.barcode,
      },
    });
  }

  async reportMinibatchExported(minibatchId: number): Promise<any> {
    return this.http.post(`${this.endpointPath}/actions/process-single`, {
      actionType: ActionType.MinibatchExported,
      data: {
        minibatchId: minibatchId,
      },
    });
  }

  async getSuperBatchesByStates(
    states: SuperBatchState[]
  ): Promise<SuperbatchModel[]> {
    let url = `${this.endpointPath}/superbatches?state=${states.join(
      "&state="
    )}`;

    return this.http.get<SuperbatchModel[]>(url).then((res) => res.data);
  }

  async getSuperBatchesWithTubesByStates(
    states: SuperBatchState[]
  ): Promise<SuperbatchModel[]> {
    let url = `${this.endpointPath}/superbatches/details?state=${states.join(
      "&state="
    )}`;

    return this.http.get<SuperbatchModel[]>(url).then((res) => res.data);
  }

  async getSuperBatchesWithMinibatchesByStates(
    states: SuperBatchState[]
  ): Promise<SuperbatchModel[]> {
    let url = `${
      this.endpointPath
    }/superbatches/minibatches?state=${states.join("&state=")}`;

    return this.http.get<SuperbatchModel[]>(url).then((res) => res.data);
  }

  async getSuperBatchDetails(id: number): Promise<SuperbatchModel | undefined> {
    let url = `${this.endpointPath}/superbatches/by-id/${id}`;

    return this.http.get<SuperbatchModel>(url).then((res) => res.data);
  }

  async getSuperBatchDetailsByCode(
    code: string
  ): Promise<SuperbatchModel | undefined> {
    let url = `${this.endpointPath}/superbatches/by-code/${code}`;

    return this.http.get<SuperbatchModel>(url).then((res) => res.data);
  }

  async getSuperBatchDetailsByPlate(
    code: string
  ): Promise<SuperbatchModel | undefined> {
    let url = `${this.endpointPath}/superbatches/by-plate/${code}`;

    return this.http.get<SuperbatchModel>(url).then((res) => res.data);
  }

  async saveSuperBatchQueue(model: SaveSuperBatchModel) {
    return this.http.post(`${this.endpointPath}/actions/process-single`, {
      actionType: ActionType.SuperbatchMetadataAssigned,
      data: {
        superbatchId: model.id,
        metadata: model.metadata,
        comment: model.comment,
      },
    });
  }

  async completeSuperBatchQueue(model: SaveSuperBatchModel) {
    return this.http.post(`${this.endpointPath}/actions/process-single`, {
      actionType: ActionType.SuperbatchQueueCompleted,
      data: {
        superbatchId: model.id,
        metadata: model.metadata,
        comment: model.comment,
      },
    });
  }

  async completeMinibatchUtiSetup(model: SaveMiniBatchSetupModel) {
    return this.http.post(`${this.endpointPath}/actions/process-single`, {
      actionType: ActionType.MinibatchUtiSetupCompleted,
      data: {
        minibatchId: model.id,
        plateBarcode: model.plateBarcode,
        metadata: model.metadata,
        comment: model.comment,
      },
    });
  }

  async updateUtiRunMinibatch(model: SaveMiniBatchModel) {
    return this.http.post(`${this.endpointPath}/actions/process-single`, {
      actionType: ActionType.MinibatchUtiRunQueueSaved,
      data: {
        minibatchId: model.id,
        currentStepTimeIn: model.timeIn,
        machineId: model.machineId,
      },
    });
  }

  async completeUtiRunMinibatch(model: SaveMiniBatchModel) {
    return this.http.post(`${this.endpointPath}/actions/process-single`, {
      actionType: ActionType.MinibatchUtiRunQueueCompleted,
      data: {
        minibatchId: model.id,
        currentStepTimeIn: model.timeIn,
        machineId: model.machineId,
      },
    });
  }

  async completeMinibatchAbrSetup(model: SaveMiniBatchSetupModel) {
    return this.http.post(`${this.endpointPath}/actions/process-single`, {
      actionType: ActionType.MinibatchAbrSetupCompleted,
      data: {
        minibatchId: model.id,
        plateBarcode: model.plateBarcode,
        metadata: model.metadata,
        comment: model.comment,
      },
    });
  }

  async batchForAbr(model: BatchForAbrModel) {
    return this.http.post(`${this.endpointPath}/actions/process-single`, {
      actionType: ActionType.MinibatchAbrSetupBatched,
      data: {
        superbatchIds: model.ids,
      },
    });
  }

  async updateAbrRunMinibatch(model: SaveMiniBatchModel) {
    return this.http.post(`${this.endpointPath}/actions/process-single`, {
      actionType: ActionType.MinibatchAbrRunQueueSaved,
      data: {
        minibatchId: model.id,
        currentStepTimeIn: model.timeIn,
        machineId: model.machineId,
      },
    });
  }

  async completeAbrRunMinibatch(model: SaveMiniBatchModel) {
    return this.http.post(`${this.endpointPath}/actions/process-single`, {
      actionType: ActionType.MinibatchAbrRunQueueCompleted,
      data: {
        minibatchId: model.id,
        currentStepTimeIn: model.timeIn,
        machineId: model.machineId,
      },
    });
  }

  async assignAbrBatchResult(model: AssignMinibatchResultRequest) {
    return this.http.post(`${this.endpointPath}/actions/process-single`, {
      actionType: ActionType.MinibatchAbrResultAssigned,
      data: {
        minibatchId: model.minibatchId,
        pcrBatchResultType: model.pcrBatchResultType,
      },
    });
  }

  async assignUtiBatchResult(model: AssignMinibatchResultRequest) {
    return this.http.post(`${this.endpointPath}/actions/process-single`, {
      actionType: ActionType.MinibatchUtiResultAssigned,
      data: {
        minibatchId: model.minibatchId,
        pcrBatchResultType: model.pcrBatchResultType,
      },
    });
  }

  async getMiniBatchesByStates(
    states: MiniBatchState[]
  ): Promise<MinibatchModel[]> {
    let url = `${this.endpointPath}/minibatches?state=${states.join(
      "&state="
    )}`;

    return this.http.get<MinibatchModel[]>(url).then((res) => res.data);
  }

  async getUtiExport(minibatchId: number, date: Date) {
    const timezone = getTimezoneName();

    return this.http
      .get(
        `${
          this.endpointPath
        }/minibatches/export-uti/${minibatchId}?date=${date.toISOString()}&tz=${timezone}`,
        {
          responseType: "blob",
        }
      )
      .then((res) => new Blob([res.data]));
  }

  async getAbrExport(minibatchId: number, date: Date) {
    const timezone = getTimezoneName();
    return this.http
      .get(
        `${
          this.endpointPath
        }/minibatches/export-abr/${minibatchId}?date=${date.toISOString()}&tz=${timezone}`,
        {
          responseType: "blob",
        }
      )
      .then((res) => new Blob([res.data]));
  }

  async getAbrBatches(states: AbrBatchState[]): Promise<AbrBatchModel[]> {
    let url = `${this.endpointPath}/abrbatches?state=${states.join("&state=")}`;

    return this.http.get<AbrBatchModel[]>(url).then((res) => res.data);
  }

  async getAbrBatchDetails(id: number): Promise<AbrBatchModel | undefined> {
    let url = `${this.endpointPath}/abrbatches/by-id/${id}`;

    return this.http.get<AbrBatchModel>(url).then((res) => res.data);
  }

  async uploadUtiResultFile(file: Blob): Promise<UtiParsingResult> {
    let url = `${this.endpointPath}/actions/parse-uti-result`;
    const formData = new FormData();
    formData.append("file", file);

    return this.http
      .post<UtiParsingResult>(url, formData)
      .then((res) => res.data);
  }
}

export const pcrService = new PcrService(API_ENDPOINTS.PCR);
