import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { RadioButton } from "primereact/radiobutton";
import { useState } from "react";
import { MinibatchModel } from "../../queries/pcr/models/mini-batch";
import { PcrBatchResultType } from "../../queries/pcr/models/pcr-batch-result-type";
import { PcrResultType } from "../../queries/pcr/models/pcr-result-type";
import { TubeModel } from "../../queries/pcr/models/tube";
import { TubeState } from "../../queries/pcr/models/tube-state";

interface BatchResultSelectorProps {
  batch: MinibatchModel;
  header: string;
  onSubmitted: (v: MinibatchModel, r: PcrBatchResultType) => void;
}

export function BatchResultSelector({
  batch,
  header,
  onSubmitted,
}: BatchResultSelectorProps) {
  const [selectedResult, setSelectedResult] = useState<PcrBatchResultType>();

  function submit() {
    onSubmitted(batch, selectedResult!);
  }

  return (
    <Card className="surface-100">
      <h2>{header}</h2>
      <h3>ID: {batch?.plateBarcode}</h3>
      <div>
        <div className="field-radiobutton">
          <RadioButton
            value={PcrBatchResultType.Pass}
            name="state"
            onChange={(e) => setSelectedResult(e.value)}
            checked={selectedResult === PcrBatchResultType.Pass}
            inputId="Pass"
          />
          <label htmlFor="Pass" className="cursor-pointer">
            Pass
          </label>
        </div>
        <div className="field-radiobutton">
          <RadioButton
            value={PcrBatchResultType.PcrRerun}
            name="state"
            onChange={(e) => setSelectedResult(e.value)}
            checked={selectedResult === PcrBatchResultType.PcrRerun}
            inputId="PcrRerun"
          />
          <label htmlFor="PcrRerun" className="cursor-pointer">
            PCR Rerun
          </label>
        </div>
        <div className="field-radiobutton">
          <RadioButton
            value={PcrBatchResultType.ExtractionRerun}
            name="state"
            onChange={(e) => setSelectedResult(e.value)}
            checked={selectedResult === PcrBatchResultType.ExtractionRerun}
            inputId="ExtractionRerun"
          />
          <label htmlFor="ExtractionRerun" className="cursor-pointer">
            Extraction Rerun
          </label>
        </div>

        <Button
          className="p-button-outlined"
          label="Submit"
          onClick={submit}
          disabled={!selectedResult}
        />
      </div>
    </Card>
  );
}
