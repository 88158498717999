import { PlateCoordinates } from "../../models/pcr/plate-coordinates";
import { MinibatchModel } from "../../queries/pcr/models/mini-batch";
import { TubeModel } from "../../queries/pcr/models/tube";
import { MiniPlateHole } from "./MiniPlateHole";

interface MiniBatchPlateProps {
  miniBatch: MinibatchModel;
  selectedIds?: number | number[];
  highlightColorClassName?: string;
  onHoleClick?: (coords: PlateCoordinates) => void;
}

export function MiniBatchPlate({
  miniBatch,
  selectedIds,
  highlightColorClassName,
  onHoleClick,
}: MiniBatchPlateProps) {
  const rows = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
  ];
  const columns = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
  ];

  function hasAnyOfIds(
    row: string,
    column: string,
    ids?: number | number[]
  ): boolean {
    if (!ids) {
      return false;
    }

    if (!Array.isArray(ids)) {
      ids = [ids];
    }

    return (
      !!ids &&
      ids.indexOf(
        miniBatch.items.find((t) => t.row === row && t.column === column)
          ?.tubeId ?? -1
      ) > -1
    );
  }

  function hasAnyId(row: string, column: string): boolean {
    const ids = miniBatch.items.flatMap((t) => t.tubeId);
    return hasAnyOfIds(row, column, ids);
  }

  return (
    <div className="border-1 border-round">
      <div className="flex flex-column">
        <div className="flex flex-row justify-content-evenly">
          <span className="w-1rem"></span>
          {columns.map((c) => (
            <span key={c} className="text-center w-1rem">
              {c}
            </span>
          ))}
        </div>
        {rows.map((r) => (
          <div key={r} className="flex flex-row justify-content-evenly mb-1">
            <span className="justify-content-center align-items-center flex w-1rem line">
              {r}
            </span>
            {columns.map((c) => (
              <div key={c}>
                <MiniPlateHole
                  key={r + "x" + c}
                  row={r}
                  column={c}  
                  highlightColorClassName={highlightColorClassName}
                  onClick={onHoleClick}                
                  highlighted={hasAnyOfIds(r, c, selectedIds)}
                  disabled={!hasAnyId(r, c)}
                />
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}
