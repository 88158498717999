import { InputSwitch } from "primereact/inputswitch";
import {
  ExtendedMetaData,
  ExtendedMetaDataItem,
} from "../../queries/pcr/models/meta-data";
import { ExtendedMetaDataInputItem } from "./ExtendedMetaDataInputItem";

export interface ExtendedMetaDataInputProps {
  metadata: ExtendedMetaData;
  disabled?: boolean;
  showAutomatedSwitch?: boolean;
  onChange?: (v: ExtendedMetaData) => void;
  onBlur?: () => void;
}

export function ExtendedMetaDataInput({
  metadata,
  disabled = false,
  showAutomatedSwitch = false,
  onChange,
  onBlur,
}: ExtendedMetaDataInputProps) {
  function update() {
    if (onChange) {
      const data = {
        isAutomated: metadata.isAutomated,
        equipments: metadata.equipments ? [...metadata.equipments] : undefined,
        reagentLots: metadata.reagentLots
          ? [...metadata.reagentLots]
          : undefined,
        instruments: metadata.instruments
          ? [...metadata.instruments]
          : undefined,
      } as ExtendedMetaData;

      onChange(data);
    }
  }

  function setMetaDataItemValue(item: ExtendedMetaDataItem, value: string) {
    item.value = value;
    update();
  }

  function toggleIsAutomated(isAutomated: boolean) {
    metadata.isAutomated = isAutomated;

    let cp = metadata.equipments?.find((x) => x.label === "ClipTip Pipette (125)");
    let hs = metadata.equipments?.find((x) => x.label === "Hamilton Starlet");

    if (cp) {
      cp.required = !isAutomated;
      cp.disabled = isAutomated;
      if (cp.disabled) {
        cp.value = "";
      }
    }
    if (hs) {
      hs.required = isAutomated;
      hs.disabled = !isAutomated;
      if (hs.disabled) {
        hs.value = "";
      }
    }

    update();
  }

  return (
    <div className="flex justify-content-start flex-column">
      {showAutomatedSwitch && (
        <div className="flex flex-row align-items-left justify-content-start mb-1">
        <span className="mr-2">Manual </span>
          <InputSwitch
            checked={metadata.isAutomated}
            disabled={disabled}
            onChange={(e) => toggleIsAutomated(e.value)}
          />
          <span className="ml-2">Automated</span>
        </div>
      )}
      <div className="flex flex-row w-full justify-content-evenly mt-2">
        {metadata.equipments && (
          <div>
            <div className="mb-2 font-bold">Equipment IDs</div>
            <div className="flex flex-column">
              {metadata.equipments.map((equipment) => (
                <ExtendedMetaDataInputItem
                  item={equipment}
                  key={equipment.label}
                  disabled={disabled || equipment.disabled}
                  onChange={(v) => setMetaDataItemValue(equipment, v)}
                  onBlur={onBlur}
                />
              ))}
            </div>
          </div>
        )}
        {metadata.reagentLots && (
          <div>
            <div className="mb-2 font-bold">Reagent lot IDs</div>
            <div className="flex flex-column">
              {metadata.reagentLots.map((reagentLot) => (
                <ExtendedMetaDataInputItem
                  item={reagentLot}
                  key={reagentLot.label}
                  disabled={disabled}
                  onChange={(v) => setMetaDataItemValue(reagentLot, v)}
                  onBlur={onBlur}
                />
              ))}
            </div>
          </div>
        )}
        {metadata.instruments && (
          <div>
            <div className="mb-2 font-bold">Instrument ID</div>
            <div className="flex flex-column">
              {metadata.instruments.map((instrument) => (
                <ExtendedMetaDataInputItem
                  item={instrument}
                  key={instrument.label}
                  disabled={disabled}
                  onChange={(v) => setMetaDataItemValue(instrument, v)}
                  onBlur={onBlur}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
