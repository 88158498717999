import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { MiniBatchResultsTabContent } from "../../components/pcr/MiniBatchResultsTabContent";
import { useToast } from "../../components/ui/ToastContext";
import {
  useAssignBatchAbrResultMinibatchMutation,
  useMiniBatchesQuery,
} from "../../queries/pcr/miniBatches.query";
import { MinibatchModel } from "../../queries/pcr/models/mini-batch";
import { MiniBatchState } from "../../queries/pcr/models/mini-batch-state";
import { PcrBatchResultType } from "../../queries/pcr/models/pcr-batch-result-type";

export function TabPcrBatchResultsAbr() {
  const [batches, setBatches] = useState<MinibatchModel[]>([]);

  const { data } = useMiniBatchesQuery(MiniBatchState.CompletedAbrRun);
  const assignResultMutation = useAssignBatchAbrResultMinibatchMutation();
  const queryClient = useQueryClient();

  const toast = useToast();

  useEffect(() => {
    if (data) {
      setBatches(data);
    }
  }, [data]);

  function handleSubmit(minibatch: MinibatchModel, result: PcrBatchResultType) {
    if (minibatch && result) {
      assignResultMutation.mutate(
        { minibatchId: minibatch.id, pcrBatchResultType: result },
        {
          onSuccess: () => {
            queryClient.invalidateQueries();
            toast.current!.show({
              severity: "success",
              summary: "Success Message",
              detail: "Result assigned!",
            });
          },
          onError: (error: any) => console.error(error),
        }
      );
    }
  }

  return (
    <MiniBatchResultsTabContent batches={batches} onSetResult={handleSubmit} />
  );
}
