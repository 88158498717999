import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { MinibatchModel } from "../../queries/pcr/models/mini-batch";
import { MinibatchSelector } from "../../components/pcr/MinibatchSelector";
import {
  useCompleteAbrRunMinibatchMutation,
  useMiniBatchesQuery,
} from "../../queries/pcr/miniBatches.query";
import { MiniBatchState } from "../../queries/pcr/models/mini-batch-state";
import {
  PcrRunData,
  PcrRunDataPanel,
} from "../../components/pcr/PcrRunDataPanel";
import { pcrService } from "../../services/pcr/pcr.service";
import saveAs from "file-saver";
import { useToast } from "../../components/ui/ToastContext";

export function PagePcrAbrRunQueue() {
  const [batches, setBatches] = useState<MinibatchModel[]>([]);
  const [selectedBatch, setSelectedBatch] = useState<MinibatchModel>();
  const [selectedBatchId, setSelectedBatchId] = useState<number>();
  useState<() => void>();

  const { isLoading, data, dataUpdatedAt } = useMiniBatchesQuery(
    MiniBatchState.CompletedAbrSetup,
    MiniBatchState.PendingAbrRun
  );
  const toast = useToast();
  const queryClient = useQueryClient();

  const batchCompleteMutation = useCompleteAbrRunMinibatchMutation();

  useEffect(() => {
    if (!isLoading && data) {
      setBatches([
        ...data.map((d) => {
          return {
            id: d.id,
            plateBarcode: d.plateBarcode,
            state: d.state,
            machineId: d.machineId,
            startDate: d.startDate,
            items: d.items,
            isExported: d.isExported,
            runState: establishRunState(d),
          } as MinibatchModel;
        }),
      ]);
    }
  }, [isLoading, data, dataUpdatedAt]);

  useEffect(() => {
    if (selectedBatchId) {
      setSelectedBatch(batches.find((x) => x.id === selectedBatchId));
    } else {
      setSelectedBatch(undefined);
    }
  }, [batches, selectedBatchId]);

  function establishRunState(minibatch: MinibatchModel) {
    if (minibatch.isExported) {
      return "EXPORTED";
    } else {
      return "PENDING";
    }
  }

  function completeBatch(data: PcrRunData) {
    batchCompleteMutation.mutate(
      {
        id: data.id,
        machineId: data.machineId,
        timeIn: data.timeIn,
        completed: true,
      },
      {
        onSuccess: () => {
          toast.current!.show({
            detail: "Minibatch completed",
            severity: "success",
          });
          setSelectedBatchId(undefined);
          queryClient.invalidateQueries();
        },
        onError: (error) => {
          toast.current!.show({
            detail: "Minibatch completion failed",
            severity: "error",
          });
          console.error(error);
        },
      }
    );
  }

  async function exportBatch(data: PcrRunData) {
    if (selectedBatch) {
      const blob = await pcrService.getAbrExport(selectedBatch.id, new Date());
      saveAs(blob, `${selectedBatch.plateBarcode}_abr_export.csv`);
      queryClient.invalidateQueries();
    }
  }

  return (
    <>
      <div className="flex">
        <div className="col-4">
          <MinibatchSelector
            batches={batches}
            onSelect={(m) => setSelectedBatchId(m.id)}
            header="PLATE ID"
          />
        </div>
        <div className="col-8">
          {selectedBatch && (
            <PcrRunDataPanel
              batch={selectedBatch}
              onComplete={completeBatch}
              onExport={exportBatch}
              state={selectedBatch?.runState}
              disabled={selectedBatch?.state === MiniBatchState.CompletedAbrRun}
            ></PcrRunDataPanel>
          )}
        </div>
      </div>
    </>
  );
}
